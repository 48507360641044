import React from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { CloseOutline, Save } from 'assets/icons';

const InplaceUpdateForm = ({
  field,
  form: { errors, touched, dirty },
  onSubmit,
  onAbort,
}) => {
  const { name } = field;
  return (
    <TextField
      id={'outlined-adornment-password' + field.name}
      type="text"
      {...field}
      fullWidth
      error={touched[name] && !!errors[name]}
      helperText={touched[name] && errors[name]}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {dirty ? (
              <IconButton
                aria-label="save changes"
                onClick={onSubmit}
                edge="end"
              >
                <Save size={22} />
              </IconButton>
            ) : (
              <IconButton aria-label="abort" onClick={onAbort} edge="end">
                <CloseOutline size={22} />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InplaceUpdateForm;
