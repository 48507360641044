import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout, SettingsContainer } from 'components/Structure';
import { domainManagement, identities } from 'consts/routes';
import { DomainsManager, EmailsManager } from 'components/Menu/Identities';

const Identities = () => {
  return (
    <Layout title="Identity Management">
      <SettingsContainer>
        <Switch>
          <Route exact path={identities} component={EmailsManager} />
          <Route exact path={domainManagement} component={DomainsManager} />
        </Switch>
      </SettingsContainer>
    </Layout>
  );
};

export default Identities;
