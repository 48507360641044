import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { StyledButton } from './Buttons';

const StyledLoadingButton = styled(({ secondaryVariant, ...props }) => (
  <StyledButton {...props} />
))(({ secondaryVariant, theme }) => {
  let variantStyles = {};
  const styles = {
    overflow: 'hidden',
    textTransform: 'none',
    height: theme.custom.buttonHeight,
    padding: theme.spacing(0, 2),
  };

  if (secondaryVariant === 'inverted') {
    variantStyles = {
      color: '#fff',
      border: '1px solid #fff',

      '&:hover': {
        border: '1px solid #fff',
      },
    };
  }

  if (secondaryVariant === 'warning') {
    variantStyles = {
      color: '#fff',
      backgroundColor: theme.palette.warning.main,

      '&:hover': {
        backgroundColor: theme.palette.warning.main,
      },
    };
  }

  return {
    ...styles,
    ...variantStyles,
  };
});

const ProgressContainer = styled(({ visible, ...props }) => <div {...props} />)(
  ({ visible, theme }) => {
    let styles = {
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: '0',
      right: '0',
      opacity: 0,
      transform: 'translateY(30px)',
      transition: 'transform 0.125s ease-in',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& > *': {
        color: theme.palette.secondary.main,
      },
    };

    if (visible) {
      styles = {
        ...styles,
        opacity: 1,
        transform: 'translateY(0)',
      };
    }

    return styles;
  }
);

const Label = styled(({ visible, ...props }) => <span {...props} />)(
  ({ theme, visible }) => {
    let styles = {
      transition: 'all 0.125s ease-in',
      opacity: 1,
      transform: 'translateY(0)',
      fontFamily: theme.typography.fontFamilyTitle,
    };

    if (!visible) {
      styles = {
        ...styles,
        opacity: 0,
        transform: 'translateY(-30px)',
      };
    }

    return styles;
  }
);

const LoadingButton = ({
  children,
  loading,
  disabled,
  onClick,
  type,
  secondaryVariant,
  ...rest
}) => {
  const triggerSubmit = (event) => {
    if (
      type === 'submit' &&
      event.which === 13 &&
      typeof onClick === 'function'
    ) {
      onClick(event);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', triggerSubmit);

    return () => window.removeEventListener('keydown', triggerSubmit);
  }, []); // eslint-disable-line

  return (
    <StyledLoadingButton
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
      secondaryVariant={secondaryVariant}
      {...rest}
    >
      <ProgressContainer visible={loading}>
        <CircularProgress size={16} thickness={5} />
      </ProgressContainer>
      <Label visible={!loading}>{children}</Label>
    </StyledLoadingButton>
  );
};

LoadingButton.defaultProps = {
  variant: 'contained',
  color: 'primary',
};

LoadingButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  secondaryVariant: PropTypes.string,
};

export default LoadingButton;
