import React, { useEffect, useState } from 'react';
import { Loader } from 'components/Base';
import sha256 from 'crypto-js/sha256';

const FirebaseContext = React.createContext(null);
const FirebaseConsumer = FirebaseContext.Consumer;

const defaultConfig = { underMaintenance: null };

const determineFlagType = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;

  return value.toString();
};

const FirebaseProvider = ({ children, firebase }) => {
  const remoteConfig = firebase.getFirebase().remoteConfig();
  const [flags, setFlags] = useState(defaultConfig);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    remoteConfig.defaultConfig = flags;
    remoteConfig.settings.minimumFetchIntervalMillis = 1000;

    const fetchFlags = async () => {
      await remoteConfig.fetchAndActivate();
      const remoteFlags = await remoteConfig.getAll();
      const newFlags = {};

      Object.entries(remoteFlags).forEach(([key, config]) => {
        newFlags[key] = determineFlagType(config.asString());
      });

      setFlags(newFlags);
    };

    fetchFlags();
  }, [remoteConfig]); //eslint-disable-line

  useEffect(() => {
    firebase
      .getFirebase()
      .auth()
      .onAuthStateChanged((user) => {
        setAuthenticated(!!user);
        setLoading(false);

        try {
          if (window.Tawk_API && user) {
            window.Tawk_API.setAttributes({
              name: user.displayName,
              email: user.email,
              hash: sha256(user.email, process.env.REACT_APP_TAWK_API_KEY),
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
  }, []); //eslint-disable-line

  if (loading) return <Loader />;

  return (
    <FirebaseContext.Provider value={{ authenticated, flags }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseProvider, FirebaseConsumer };

export default FirebaseContext;
