import React, { useState } from 'react';
import { Layout } from 'components/Structure';
import { SettingsHeader } from 'components/Base';
import { Grid } from '@material-ui/core';
import { TagManager } from 'components/Menu/Tags';
import CreateTagDialog from '../components/Menu/Tags/CreateTagDialog';
import { connect } from 'react-redux';
import { TagProvider } from '../components/Contexts';

const Tags = ({ tagCount }) => {
  const [createTagDialogOpen, setCreateTagDialogOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');

  const title = 'Tag Manager';

  const handleClose = () => {
    setCreateTagDialogOpen(false);
    setSelectedTag(null);
  };

  const handleEditTag = (tag) => {
    setSelectedTag(tag);
    setCreateTagDialogOpen(true);
  };

  return (
    <Layout title={title}>
      <TagProvider>
        <Grid container direction={'column'}>
          <SettingsHeader
            buttonText={'Create Tag'}
            title={
              tagCount > 0
                ? `${tagCount} item${tagCount !== 1 ? 's' : ''} listed`
                : 'You have not created any tags yet'
            }
            onClick={() => setCreateTagDialogOpen(true)}
          />
          <TagManager onEditDialogClick={handleEditTag} />
          <CreateTagDialog
            open={createTagDialogOpen}
            tag={selectedTag}
            onClose={handleClose}
          />
        </Grid>
      </TagProvider>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    tagCount: state.tags.total,
  };
};

export default connect(mapStateToProps)(Tags);
