import React, { Fragment, useRef, useState } from 'react';
import { LoadingButton } from 'components/Base';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
} from '@material-ui/core';
import { Publish, Save } from 'assets/icons';
import { useSelector } from 'react-redux';
import { useSubscription } from 'components/Contexts';
import { ArrowDropDown } from '@material-ui/icons';

const StyledButtonGroup = styled(ButtonGroup)(() => ({
  '&:hover': {
    boxShadow: 'none',
  },
  boxShadow: 'none',
}));

const commonButtonStyles = ({ theme, published }) => ({
  transition: 'background-color 0.25s',
  backgroundColor: published
    ? theme.palette.secondary.main
    : theme.palette.primary.main,
  color: published ? theme.palette.text.main : theme.palette.text.reverted,
  '&:hover': {
    backgroundColor: published
      ? theme.palette.secondary.dark
      : theme.palette.primary.dark,
    boxShadow: 'none',
  },
  '&:disabled': {
    color: 'rgba(3,39,51,0.4)',
  },
});

const StyledLoadingButton = styled(LoadingButton)(commonButtonStyles);

const StyledButton = styled(Button)(commonButtonStyles);

const PublishButton = ({
  unsavedChanges,
  onSave,
  onPublish,
  loading,
  template,
}) => {
  const { default: defaultAwsAccount } = useSelector((state) => state.aws);
  const {
    subscription: { awsAccountsCount },
  } = useSubscription();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  if (!template) return <span />;
  const { unpublishedChanges } = template;
  const showPublish =
    defaultAwsAccount && unpublishedChanges && !unsavedChanges;
  const handleMultiAction = () => {
    if (showPublish) {
      onPublish({ bulk: true });
    } else {
      onSave({ bulk: true });
    }
    setOpen(!open);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const singleActionButton = () => {
    return (
      <StyledLoadingButton
        loading={loading}
        startIcon={
          !loading && (showPublish ? <Publish size={26} /> : <Save size={26} />)
        }
        variant="contained"
        onClick={showPublish ? onPublish : onSave}
        published={!showPublish ? 1 : 0}
      >
        {showPublish ? 'Publish' : 'Save'}
      </StyledLoadingButton>
    );
  };

  if (awsAccountsCount <= 1) {
    return singleActionButton();
  }

  return (
    <Fragment>
      <StyledButtonGroup
        variant="contained"
        ref={anchorRef}
        disabled={
          loading ||
          (!unsavedChanges && (showPublish ? !unpublishedChanges : true))
        }
      >
        {singleActionButton()}
        <StyledButton
          variant="contained"
          published={!showPublish ? 1 : 0}
          size="small"
          onClick={() => setOpen(!open)}
        >
          <ArrowDropDown />
        </StyledButton>
      </StyledButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={{
          marginTop: '0',
          padding: 0,
          zIndex: 1,
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList disablePadding>
              <MenuItem key="template-starters" onClick={handleMultiAction}>
                <ListItemText>
                  {showPublish
                    ? 'Publish to all accounts'
                    : 'Save in all accounts'}
                </ListItemText>
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Fragment>
  );
};
export default PublishButton;
