import React from 'react';
import { Button, Card, Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const Container = styled(Card)(({ theme }) => ({
  margin: theme.spacing(4),
  padding: theme.spacing(3),
}));

const ErrorView = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Container>
      <Grid
        container
        direction="column"
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          Sorry, an unexpected error occured. Please try to reload the page.
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={refreshPage}>
            Reload
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ErrorView;
