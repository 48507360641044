import React, { useRef } from 'react';
import SearchBar from 'material-ui-search-bar';
import { makeStyles } from '@material-ui/core';
import { StringParam, useQueryParam } from 'use-query-params';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  noBoxShadow: {
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: 'none',
  },
}));

const DebouncedSearchbar = ({
  onSubmit,
  submitAfterType,
  defaultQueryParam = 'filter',
  customStyles,
  ...rest
}) => {
  const classes = useStyles();

  const [value, setValue] = useQueryParam(defaultQueryParam, StringParam);

  const sendRequest = (update) => {
    setValue(update);
    onSubmit(update);
  };

  const debouncedSave = useRef(
    debounce((nextValue) => sendRequest(nextValue), 1000)
  ).current;

  return (
    <SearchBar
      {...rest}
      style={customStyles}
      className={classes.noBoxShadow}
      value={value}
      onChange={debouncedSave}
      onRequestSearch={() => onSubmit(value)}
    />
  );
};

export default DebouncedSearchbar;
