import React, { useState, useEffect } from 'react';
import { awsPermissionsColumns } from 'consts';
import { OverviewTable } from 'components/Base';
import {
  getSemplatesFeaturesForAccount,
  extendByFeatureStatus,
} from './AwsPermissionsUtils';

const AwsPermissionsTable = ({ loading, permissions, awsAccount }) => {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    if (permissions !== undefined) {
      setFeatures(
        extendByFeatureStatus(
          getSemplatesFeaturesForAccount(awsAccount),
          permissions
        )
      );
    }
  }, [permissions, awsAccount]);

  return (
    <OverviewTable
      data={features}
      columns={awsPermissionsColumns}
      total={1} // set to 1 to avoid showing the pagination
      rowsPerPage={10}
      page={0}
    />
  );
};

export default AwsPermissionsTable;
