import React from 'react';
import PropTypes from 'prop-types';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid, styled, Typography } from '@material-ui/core';
import { FieldWrapper, LoadingButton } from 'components/Base';
import * as Yup from 'yup';
import CheckboxField from '../../Base/CheckboxField';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    '& input': {
      textAlign: 'right',
    },
  },
});

const CreateSchema = Yup.object().shape({
  domain: Yup.string().required('This field is required'),
  addDkim: Yup.boolean(),
  addMailFrom: Yup.boolean(),
  mailFromSubdomain: Yup.string(),
});

const VerifyDomainForm = ({ onSubmit, loading }) => {
  return (
    <Formik
      initialValues={{
        domain: '',
        addDkim: true,
        addMailFrom: true,
        mailFromSubdomain: 'mails',
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={CreateSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldWrapper
                label="Domain Identity"
                subtitle="SES will add this domain identity and create DNS entries. You will need to add those entries to your DNS settings."
              >
                <Field
                  component={TextField}
                  fullWidth
                  name="domain"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12}>
              <Grid item>
                <FieldWrapper
                  label="DKIM Authentication (Recommended)"
                  subtitle="DKIM is a standard for mail verification. It helps passing SPAM checks and increases security."
                  link="https://docs.aws.amazon.com/ses/latest/DeveloperGuide/send-email-authentication-dkim.html"
                  linkText="Read more on DKIM"
                >
                  <Field
                    component={CheckboxField}
                    name="addDkim"
                    className="form-control rounded-0"
                    childrenComponent={<span>Add DKIM</span>}
                  />
                </FieldWrapper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item>
                <FieldWrapper
                  label="Mail From Domain (Recommended)"
                  subtitle="The mail from address indicated from where the mail originated. It allows to pass DMARC validation through SPF."
                  link="https://docs.aws.amazon.com/ses/latest/DeveloperGuide/mail-from.html"
                  linkText="Read more on custom mail from domains"
                >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={5}>
                      <Field
                        component={CheckboxField}
                        name="addMailFrom"
                        className="form-control rounded-0"
                        childrenComponent={<span>Add Mail From Domain</span>}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={StyledTextField}
                        fullWidth
                        name="mailFromSubdomain"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography align="left">
                        .
                        {values.domain === ''
                          ? 'your-domain.com'
                          : values.domain}
                      </Typography>
                    </Grid>
                  </Grid>
                </FieldWrapper>
              </Grid>
            </Grid>
            <Grid item container justifyContent={'flex-end'}>
              <Grid item>
                <LoadingButton loading={isSubmitting || loading} type="submit">
                  {'Add domain'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

VerifyDomainForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default VerifyDomainForm;
