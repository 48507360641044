import React from 'react';
import { connect } from 'react-redux';
import { TermsUpdatedDialog } from '.';

const ActionRequired = ({ termsAccepted }) => {
  if (termsAccepted !== undefined && !termsAccepted) {
    return <TermsUpdatedDialog />;
  }
  return <></>;
};

const mapStateToProps = (state) => {
  return {
    termsAccepted: state.user?.termsAccepted,
  };
};

export default connect(mapStateToProps)(ActionRequired);
