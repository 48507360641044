import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const StyledHeadline = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  color: theme.palette.text.reverted,
  fontWeight: 900,
  fontSize: '38px',
}));

const SubHeadline = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0.5, 0, 3, 0),
  fontSize: '22px',
  fontWeight: 400,
  color: theme.palette.text.reverted,
}));

const AuthHeadline = ({ title, subTitle }) => {
  return (
    <>
      <StyledHeadline variant="h2">{title}</StyledHeadline>
      <SubHeadline variant="h3">{subTitle}</SubHeadline>
    </>
  );
};

AuthHeadline.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

export default AuthHeadline;
