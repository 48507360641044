import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const ErrorAlert = ({ error }) => {
  if (error.includes('403')) {
    return (
      <Alert severity="error">
        <AlertTitle>Access Denied</AlertTitle>
        <Typography variant="body2">
          This page can only be viewed by administrators
        </Typography>
      </Alert>
    );
  }
};

ErrorAlert.propTypes = {
  error: PropTypes.node,
};

export default ErrorAlert;
