import React from 'react';

import { DashboardMain, DashboardProfile } from 'components/Menu/Dashboard';
import { connect } from 'react-redux';
import { Layout } from '../components/Structure';
import { LogoLoader } from '../components/Base';
import { styled } from '@material-ui/core/styles';

const Box = styled('div')({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
});

const MainArea = styled('div')(({ theme }) => ({
  height: '100%',
  width: `calc(100vw - ${theme.custom.drawerWidth}px - ${theme.custom.profileAreaWidth}px)`,
}));

const ProfileArea = styled('div')(({ theme }) => ({
  position: 'fixed',
  right: '0',
  height: '100%',
  width: theme.custom.profileAreaWidth,
}));

const Dashboard = ({ companyDetails }) => {
  if (!companyDetails) {
    return (
      <Layout title={'Loading...'}>
        <LogoLoader />
      </Layout>
    );
  }

  return (
    <Layout
      title={companyDetails?.name ? `${companyDetails.name}` : ''}
      renderappbar={'false'}
      nopadding={1}
    >
      <Box display={'flex'} style={{ flexDirection: 'row' }}>
        <MainArea>
          <DashboardMain />
        </MainArea>
        <ProfileArea>
          <DashboardProfile />
        </ProfileArea>
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    companyDetails: state.company.details,
  };
};

export default connect(mapStateToProps, null)(Dashboard);
