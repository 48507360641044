import React from 'react';
import { LogoLoader } from 'components/Base';
import { Box } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1),
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    marginRight: theme.spacing(1),
  },
}));

const AwsAccountStatus = ({ statusSummary }) => {
  const classes = useStyles();

  if (!statusSummary)
    return (
      <Box m={8} display="inline-block">
        <LogoLoader />
      </Box>
    );

  const {
    accountId,
    region,
    sendingEnabled,
    max24HourSend,
    maxSendRate,
    sentLast24Hours,
  } = statusSummary;

  return (
    <>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6}>
          <Typography variant="body1">AWS Account ID</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">{accountId}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6}>
          <Typography variant="body1">AWS Region</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">{region}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6}>
          <Typography variant="body1">Sending Enabled</Typography>
        </Grid>
        <Grid item xs={6} className={classes.statusContainer}>
          <FiberManualRecordIcon
            style={{ color: sendingEnabled ? green[500] : red[500] }}
            className={classes.statusIcon}
          />
          <Typography variant="body1">
            {sendingEnabled ? 'Enabled' : 'Disabled'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6}>
          <Typography variant="body1">Quota per 24 hours</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">{max24HourSend}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6}>
          <Typography variant="body1">Maximum send within 24 hours</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">{maxSendRate}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6}>
          <Typography variant="body1">Sent within last 24 hours</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">{sentLast24Hours}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default AwsAccountStatus;
