import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, withStyles } from '@material-ui/core/styles';
import { AppBar, Box, Tab, Tabs } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';

const StyledTabs = withStyles((theme) => ({
  root: {
    color: theme.palette.text.main,
    fontWeight: 500,
    bordeRadius: '10px',
    width: '200px',
  },
  indicator: {
    opacity: 0,
  },
}))(Tabs);

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '10px',
  zIndex: 10,
  boxShadow: 'none',
  position: 'relative',
  top: '-70px',
  width: '200px',
  bordeRadius: '10px',
  left: `calc(100% - 200px)`,
}));

const StyledContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  height: '110.5%',
  display: 'flex',
  flexFlow: 'column',
  borderRadius: '10px',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  width: '100px',
  minWidth: '100px',
  borderRadius: '10px',
  transition: 'all 0.125s ease-in',

  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.light,
  },
}));

const StyledSwipeableViews = styled(SwipeableViews)(() => ({
  flex: 1,
  position: 'relative',
  top: '-48px',
  '& > div': {
    height: '100%',
  },
}));

const StyledTabPanelContainer = styled('div')({
  top: '100px',
  height: '100%',
});

const StyledBoxContainer = styled(Box)({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <StyledTabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledBoxContainer p={2}>{children}</StyledBoxContainer>
      )}
    </StyledTabPanelContainer>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const TabComponent = ({ tabData }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <StyledContainer>
      <StyledAppBar position="relative">
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {tabData.map(({ label, Icon }, index) => (
            <StyledTab
              key={`${label}${index}`}
              label={label}
              icon={<Icon size={18} />}
              {...a11yProps(index)}
            />
          ))}
        </StyledTabs>
      </StyledAppBar>
      <StyledSwipeableViews
        axis={'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tabData.map(({ label, children: Children }, index) => (
          <TabPanel
            key={`${label}${index}`}
            {...a11yProps(index)}
            value={value}
            index={index}
          >
            <Children />
          </TabPanel>
        ))}
      </StyledSwipeableViews>
    </StyledContainer>
  );
};
export default TabComponent;
