import { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useLocalStorage } from './index';
import { useDispatch } from 'react-redux';
import { setThemeMode } from 'state/actions/layout';

export const useDarkMode = () => {
  const dispatch = useDispatch();
  const prefsDarkMode = usePrefersDarkMode();
  const [enableDarkMode, storeEnableDarkMode] = useLocalStorage(
    'dark-mode-enabled',
    null
  );
  const [darkModeEnabled, setDarkModeEnabled] = useState(
    enableDarkMode != null ? enableDarkMode : prefsDarkMode
  );

  const setDarkMode = (value) => {
    storeEnableDarkMode(value);
    setDarkModeEnabled(value);
  };

  useEffect(() => {
    dispatch(setThemeMode(darkModeEnabled ? 'dark' : 'light'));
  }, [darkModeEnabled, dispatch]);

  return [darkModeEnabled, setDarkMode];
};

const usePrefersDarkMode = () => {
  return useMediaQuery('(prefers-color-scheme: dark)');
};
