import React, { Fragment, useMemo, useState } from 'react';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Article,
  ChevronDown,
  Copy,
  Home,
  Fingerprint,
  Settings,
  Tags,
} from 'assets/icons';
import {
  companySettings,
  dashboard,
  domainRoute,
  identities,
  memberRoute,
  starterRoute,
  subscriptionRoute,
  tags,
  templates,
} from 'consts/routes';

const ListContainer = styled('div')({
  height: '100%',
});

const StyledList = styled(List)(({ theme, child }) => ({
  margin: theme.spacing(1, 1, 0, 1),
}));

const StyledSubItemContainer = styled('div')(({ theme, isvisible }) => ({
  height: isvisible ? 'auto' : '0px !important',
  margin: theme.spacing(0, 1),
  backgroundColor: 'transparent',
  flexDirection: 'column',
}));

const StyledSubItemListLine = styled('div')(({ theme }) => ({
  width: '2px',
  height: '100%',
  borderRadius: '30px',
  marginLeft: theme.spacing(1.2),
  backgroundColor: theme.palette.text.defaultReverted,
}));

const StyledSubItemsList = styled(List)(({ theme }) => ({
  marginLeft: theme.spacing(0.8),
}));

const StyledNavLink = styled(NavLink)(({ theme, visible }) => ({
  position: 'relative',
  margin: theme.spacing(2.5, 0),
  padding: theme.spacing(0),
  borderRadius: '10px',
  display: visible ? 'flex' : 'none',
  textDecoration: 'none',
  backgroundColor: 'transparent',
  color: theme.palette.text.defaultReverted,
  transition: 'all 0.15s ease',

  '&:hover': {
    backgroundColor: theme.palette.background.light,
    color: theme.palette.text.default,
  },

  '&.active': {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.default,
    margin: theme.spacing(2.5, 0, 1, 0),
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: '80%',
  padding: theme.spacing(1.2, 1),
  position: 'relative',
  '& svg': {
    width: theme.spacing(3),
  },
}));

const StyledItemLabel = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  fontWeight: 700,
  fontSize: '14px',
}));

const StyledChevron = styled(ChevronDown)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1.2),
}));

const SubmenuLink = styled(NavLink)(({ theme }) => ({
  position: 'relative',
  margin: theme.spacing(0, 2, 2, 2),
  borderRadius: '10px',
  display: 'flex',
  textDecoration: 'none',
  backgroundColor: 'transparent',
  color: theme.palette.text.defaultReverted,
  transition: 'all 0.15s ease',
  fontWeight: 600,
  fontFamily: theme.typography.fontFamilyTitle,
  fontSize: '14px',

  '&:hover': {
    backgroundColor: theme.palette.background.light,
    color: theme.palette.secondary.main,
  },

  '&.active': {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    fontWeight: 800,
  },

  '&:last-of-type': {
    marginBottom: theme.spacing(0),
  },
}));

const StyledKBItem = styled(ListItem)(({ theme, isactive }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  margin: theme.spacing(2.5, 0, 1, 0),
  padding: theme.spacing(1.1, 1),
  borderRadius: '10px',
  textDecoration: 'none',
  backgroundColor: isactive ? theme.palette.secondary.light : 'transparent',
  color: isactive
    ? theme.palette.text.default
    : theme.palette.text.defaultReverted,
  transition: 'all 0.15s ease',
  fontWeight: 600,

  '&:hover': {
    backgroundColor: theme.palette.background.light,
    color: theme.palette.text.default,
    cursor: 'pointer',
  },

  '& svg': {
    width: theme.spacing(3),
  },
}));

const SubmenuKBLink = styled('a')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  fontWeight: 600,
  fontFamily: theme.typography.fontFamilyTitle,
  fontSize: '14px',
  textDecoration: 'none',
  color: theme.palette.text.defaultReverted,
  transition: 'all 0.15s ease',
  margin: theme.spacing(0, 0, 2, 2),

  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.main,
    fontWeight: 800,
  },
}));

const DrawerMenu = ({ user }) => {
  const [showKB, setShowKB] = useState(false);
  const { role: userRole } = user;
  const match = useRouteMatch();
  const MENU = useMemo(
    () => [
      {
        label: 'Home',
        Icon: Home,
        to: dashboard,
        allowedUserRoles: ['admin', 'member'],
        exactMatch: true,
      },
      {
        label: 'Templates',
        Icon: Copy,
        to: templates,
        allowedUserRoles: ['admin', 'member'],
        hasChildren: true,
        subnav: [
          {
            label: 'Template Starters',
            to: starterRoute,
          },
        ],
      },
      {
        label: 'Tag Manager',
        Icon: Tags,
        to: tags,
        allowedUserRoles: ['admin', 'member'],
      },
      {
        label: 'AWS Identities',
        Icon: Fingerprint,
        to: identities,
        allowedUserRoles: ['admin', 'member'],
        hasChildren: true,
        subnav: [
          {
            label: 'Emails',
            to: '',
            exactMatch: true,
          },
          {
            label: 'Domains',
            to: domainRoute,
            exactMatch: true,
          },
        ],
      },
      {
        label: 'Settings',
        Icon: Settings,
        to: companySettings,
        allowedUserRoles: ['admin'],
        hasChildren: true,
        subnav: [
          {
            label: 'AWS Credentials',
            to: '',
            exactMatch: true,
          },
          {
            label: 'Members',
            to: memberRoute,
            exactMatch: true,
          },
          {
            label: 'Subscription',
            to: subscriptionRoute,
            exactMatch: true,
          },
        ],
      },
      {
        label: 'Helpdesk',
        Icon: Article,
        allowedUserRoles: ['admin', 'member'],
        hasChildren: true,
        subnav: [
          {
            label: 'Setup Guide',
            to: 'https://semplates.io/documentation/setup-guide',
          },
          {
            label: 'Template Design',
            to: 'https://semplates.io/documentation/template-design',
          },
          {
            label: 'FAQ',
            to: 'https://semplates.io/documentation/faq',
          },
        ],
      },
    ],
    []
  );

  return (
    <ListContainer>
      <StyledList>
        {MENU.map(
          (
            { label, to, allowedUserRoles, subnav, Icon, exactMatch },
            index
          ) => {
            return (
              <Fragment key={`${label}-${index}`}>
                {to ? (
                  <StyledNavLink
                    to={to}
                    visible={allowedUserRoles.includes(userRole) ? 1 : 0}
                    exact={exactMatch}
                  >
                    <StyledListItem>
                      <Icon />
                      <Box ml={1}>
                        <StyledItemLabel>{label}</StyledItemLabel>
                      </Box>
                    </StyledListItem>
                    {subnav && match.path.includes(to) && <StyledChevron />}
                  </StyledNavLink>
                ) : (
                  <StyledKBItem
                    onClick={() => setShowKB(!showKB)}
                    isactive={showKB ? 1 : 0}
                  >
                    <Icon />
                    <Box ml={1}>
                      <StyledItemLabel>{label}</StyledItemLabel>
                    </Box>
                    {showKB && <StyledChevron />}
                  </StyledKBItem>
                )}
                {allowedUserRoles.includes(userRole) && subnav && (
                  <StyledSubItemContainer
                    isvisible={match.path.includes(to) ? 1 : 0}
                  >
                    {match.path.includes(to) && (
                      <StyledSubItemListLine>
                        <StyledSubItemsList>
                          {subnav.map((sublink) => {
                            return (
                              <SubmenuLink
                                key={sublink.to}
                                to={`${to}${sublink.to}`}
                                exact={sublink.exactMatch}
                              >
                                {sublink.label}
                              </SubmenuLink>
                            );
                          })}
                        </StyledSubItemsList>
                      </StyledSubItemListLine>
                    )}
                  </StyledSubItemContainer>
                )}

                {!to && subnav && (
                  <StyledSubItemContainer isvisible={showKB ? 1 : 0}>
                    {showKB && (
                      <StyledSubItemListLine>
                        <StyledSubItemsList>
                          {subnav.map((sublink) => {
                            return (
                              <SubmenuKBLink
                                key={sublink.to}
                                href={sublink.to}
                                target={'_blank'}
                              >
                                {sublink.label}
                              </SubmenuKBLink>
                            );
                          })}
                        </StyledSubItemsList>
                      </StyledSubItemListLine>
                    )}
                  </StyledSubItemContainer>
                )}
              </Fragment>
            );
          }
        )}
      </StyledList>
    </ListContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.user,
  };
};

export default connect(mapStateToProps)(DrawerMenu);
