import React, { createContext, useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import Bugsnag from '@bugsnag/js';

const GoogleAdsID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

const ConversionContext = createContext(null);

const useConversions = () => useContext(ConversionContext);

const ConversionProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (GoogleAdsID && !isInitialized) {
      try {
        TagManager.initialize({
          gtmId: GoogleAdsID,
          dataLayerName: 'gtmDataLayer',
        });
        setIsInitialized(true);
      } catch (error) {
        console.error('Error initializing GTM:', error);
        Bugsnag.notify(error, {
          context: 'Initializing Google Tag Manager',
          severity: 'error',
        });
      }
    }
  }, [isInitialized]);

  const sendConversion = (gclid, conversionLabel) => {
    try {
      if (GoogleAdsID && isInitialized && window.gtmDataLayer) {
        window.gtmDataLayer.push({
          event: 'conversion',
          send_to: `${GoogleAdsID}/${conversionLabel}`,
          gclid: gclid,
        });
      } else {
        const errorMessage =
          'GTM not initialized or gtmDataLayer not available';
        console.warn(errorMessage);
        Bugsnag.notify(new Error(errorMessage), {
          context: 'Sending Conversion Data',
          severity: 'warning',
        });
      }
    } catch (error) {
      console.error('Error sending conversion:', error);
      Bugsnag.notify(error, {
        context: 'Sending Conversion Data',
        severity: 'error',
      });
    }
  };

  return (
    <ConversionContext.Provider value={{ sendConversion }}>
      {children}
    </ConversionContext.Provider>
  );
};

export { ConversionProvider, useConversions };
