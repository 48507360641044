import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const StyledTabs = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.reverted,
    fontWeight: 500,
  },
  indicator: {
    backgroundColor: theme.palette.text.reverted,
  },
}))(Tabs);

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Fragment
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Fragment>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const LinkTab = (props) => {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
};

const SettingsTopNavigation = ({ tabs }) => {
  const history = useHistory();

  const [value, setValue] = useState(history.location.pathname);

  const handleChange = (event, path) => {
    history.push(path);
    setValue(path);
  };

  return (
    <Fragment>
      <AppBar position="static">
        <StyledTabs variant="fullWidth" value={value} onChange={handleChange}>
          {tabs.map(({ label, to }) => {
            return <LinkTab value={to} label={label} href={to} key={to} />;
          })}
        </StyledTabs>
      </AppBar>
    </Fragment>
  );
};

export default SettingsTopNavigation;
