import { styled } from '@material-ui/core/styles';
import { LoadingButton } from '../Base';

export const ControlBarContainer = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '32px 48px',
}));

export const StyledButton = styled(LoadingButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.text.main,
  minWidth: 80,

  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));
