import React, { Fragment, useEffect, useRef, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useLazyLoading } from 'state/hooks';
import { publishTemplate, updateTemplate } from 'state/actions';
import {
  TemplatesAppBar,
  MoreActionsDrawer,
  PublishButton,
  RouteGuard,
} from '../Shared';
import EmailEditor from 'react-email-editor';
import { connect } from 'react-redux';
import { LogoLoader } from 'components/Base';
import { useSubscription, useTracking } from 'components/Contexts';
import { BooleanParam, useQueryParam } from 'use-query-params';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
});

const UnlayerEditor = ({ template, themeMode }) => {
  const defaultEditorOptions = {
    appearance: {
      theme: themeMode,
    },
    features: {
      preheaderText: false,
    },
  };
  const { company, subscription } = useSubscription();
  const history = useHistory();
  const { logEvent } = useTracking();

  const designLoaded = useRef(false);
  const emailEditorRef = useRef(null);
  const pathToNavigate = useRef();

  const [designHasLoaded, setDesignHasLoaded] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [templateData, setTemplateData] = useState(template);
  const [editorOptions, setEditorOptions] = useState(null);

  const [drawerOpen, setDrawerOpen] = useQueryParam(
    'moreActions',
    BooleanParam
  );

  // initial loading of the design
  useEffect(() => {
    if (!designLoaded.current) handleLoadTemplateDesign();
  });

  // loading & state setting
  const handleLoadTemplateDesign = () => {
    if (!emailEditorRef.current || !emailEditorRef.current.editor) {
      return;
    }
    designLoaded.current = true;
    emailEditorRef.current.editor.loadDesign(
      template.design ? JSON.parse(template.design) : {}
    );
    emailEditorRef.current.editor.addEventListener('design:loaded', () => {
      setDesignHasLoaded(true);
    });
  };

  // AFTER the design has successfully loaded, we add a listener to the editor.
  useEffect(() => {
    if (designHasLoaded) {
      emailEditorRef.current.editor.addEventListener('design:updated', () => {
        setUnsavedChanges(true);
      });
    }
  }, [designHasLoaded]);

  // load user specific editor options
  useEffect(() => {
    if (
      subscription.name === 'Business' ||
      subscription.name === 'Enterprise'
    ) {
      setEditorOptions({
        ...defaultEditorOptions,
        user: {
          id: company?.details?.uid,
        },
      });
    } else {
      setEditorOptions(defaultEditorOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, subscription]);

  const [handleTemplateSave, { loading: savingUpdate }] = useLazyLoading({
    action: updateTemplate,
    onSuccess: (template) => {
      logEvent({ category: 'template', action: 'updated' });
      // setUnsavedChanges = false, then setTemplateData
      // Thus previous useEffect is redundant -> removed
      setUnsavedChanges(false);
      setTemplateData(template);
      if (pathToNavigate.current) {
        history.push(pathToNavigate.current);
        pathToNavigate.current = undefined;
      }
    },
  });

  const handleSave = async (update, bulk) => {
    await emailEditorRef.current.editor.exportHtml(async ({ html, design }) => {
      update = {
        ...update,
        html,
        design,
      };
      // also calls success handler
      await handleTemplateSave(update, bulk);
    });
  };

  const handleSaveMetadata = async (update) => {
    if (update?.design) {
      // Need to load & export once, to update HTML
      emailEditorRef.current.editor.loadDesign(update.design);
      await emailEditorRef.current.editor.exportHtml(
        async ({ html, design }) => {
          update = {
            ...update,
            html,
            design,
          };
          // also calls success handler
          await handleTemplateSave(update);
        }
      );
    } else {
      // also calls success handler
      await handleTemplateSave(update);
    }
  };

  const handleSaveWithoutInput = async ({ bulk }) => {
    await handleSave(templateData, bulk);
  };

  const handleNavigation = async (save = true, path = null) => {
    pathToNavigate.current = path;
    if (save) {
      await handleSaveWithoutInput({ bulk: false });
    }
    history.push(path);
  };

  const [handlePublish, { loading: publishing }] = useLazyLoading({
    action: publishTemplate,
    onSuccess: () => {
      logEvent({ category: 'template', action: 'published' });
    },
  });

  return (
    <Fragment>
      <RouteGuard
        when={unsavedChanges}
        onNavigate={handleNavigation}
        loading={savingUpdate}
      />
      <TemplatesAppBar>
        <PublishButton
          loading={savingUpdate || publishing}
          onSave={handleSaveWithoutInput}
          onPublish={({ bulk }) => handlePublish(template.uid, bulk)}
          unsavedChanges={unsavedChanges}
          template={template}
        />
      </TemplatesAppBar>
      <Container>
        {editorOptions ? (
          <Fragment>
            <EmailEditor
              ref={emailEditorRef}
              onLoad={handleLoadTemplateDesign}
              projectId={6799}
              options={editorOptions}
            />
            <MoreActionsDrawer
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              template={template}
              saving={savingUpdate}
              onSaveMetadata={handleSaveMetadata}
            />
          </Fragment>
        ) : (
          <LogoLoader />
        )}
      </Container>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.layout.themeMode,
    template: state.templates.selectedTemplate,
  };
};

export default connect(mapStateToProps)(UnlayerEditor);
