import React from 'react';
import { ErrorMessage } from 'formik';
import { Checkbox, styled } from '@material-ui/core';

const StyledError = styled('div')(({ theme }) => ({
  color: theme.palette.error.main,
  marginLeft: theme.spacing(2),
}));

const CheckboxContainer = styled('span')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
}));

const StyledBox = styled(Checkbox)(({ theme, hasdarkbackground }) => ({
  paddingLeft: '0px !important',

  '& .MuiSvgIcon-root': {
    color:
      hasdarkbackground === 'true'
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
  },
}));

const CheckboxField = ({ field, childrenComponent, ...other }) => {
  const { name, value } = field;
  return (
    <CheckboxContainer>
      <StyledBox
        style={{ color: 'red !important' }}
        checked={value}
        {...field}
        {...other}
      />
      {childrenComponent}
      <ErrorMessage name={name} component={StyledError} />
    </CheckboxContainer>
  );
};
export default CheckboxField;
