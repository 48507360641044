import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

const getPoliciesNeededForFeature = (permissions) => {
  if (!Array.isArray(permissions)) {
    return permissions.Action;
  }
  return (
    permissions
      .map((policy) => {
        return policy.Action;
      })
      .flat()
      // Filter Action sts:GetCallerIdentity, because it is always needed
      .filter((action) => action !== 'sts:GetCallerIdentity')
  );
};

export const extendByFeatureStatus = (features, permissions) => {
  return features.map((feature) => {
    feature.permissionsStatus = {
      permissions: feature.permissions,
      disabled: [],
    };

    const policiesForFeatures = getPoliciesNeededForFeature(
      JSON.parse(feature.permissions)
    );
    const unlocked = policiesForFeatures.every((policy) => {
      return permissions.some((permission) => {
        if (permission.id === policy && !permission.enabled) {
          feature.permissionsStatus.disabled.push(permission.id);
        }

        return permission.id === policy && permission.enabled;
      });
    });
    return {
      ...feature,
      featureStatus: unlocked ? 'Enabled' : 'Locked by missing permissions',
    };
  });
};

export const getSemplatesFeaturesForAccount = () => {
  return [
    {
      featureName: 'Permission Management',
      featureDescription: 'The feature you are currently using',
      permissions: JSON.stringify([
        {
          Sid: 'GetCallerIdentity',
          Effect: 'Allow',
          Action: ['sts:GetCallerIdentity'],
          Resource: '*',
        },
        {
          Sid: 'SimulatePrincipalPolicy',
          Effect: 'Allow',
          Action: ['iam:SimulatePrincipalPolicy'],
          Resource: '*',
        },
      ]),
    },
    {
      featureName: 'Template Management',
      featureDescription: 'Create, update, delete and list email templates',
      permissions: JSON.stringify({
        Sid: 'TemplateManagement',
        Effect: 'Allow',
        Action: [
          'ses:CreateTemplate',
          'ses:DeleteTemplate',
          'ses:GetTemplate',
          'ses:UpdateTemplate',
          'ses:ListTemplates',
        ],
        Resource: '*',
      }),
    },
    {
      featureName: 'Domain Management',
      featureDescription: 'Manage email domains and DKIM settings',
      permissions: JSON.stringify({
        Sid: 'DomainManagement',
        Effect: 'Allow',
        Action: [
          'ses:GetIdentityDkimAttributes',
          'ses:GetIdentityMailFromDomainAttributes',
          'ses:PutEmailIdentityDkimSigningAttributes',
          'ses:SetIdentityMailFromDomain',
          'ses:VerifyDomainIdentity',
          'ses:VerifyDomainDkim',
        ],
        Resource: '*',
      }),
    },
    {
      featureName: 'Sender Address Management',
      featureDescription: 'Manage email sender addresses',
      permissions: JSON.stringify({
        Sid: 'SenderAddressManagement',
        Effect: 'Allow',
        Action: [
          'ses:DeleteIdentity',
          'ses:GetEmailIdentity',
          'ses:GetIdentityVerificationAttributes',
          'ses:VerifyEmailAddress',
          'ses:VerifyEmailIdentity',
          'ses:ListIdentities',
          'ses:ListVerifiedEmailAddresses',
          'ses:DeleteVerifiedEmailAddress',
        ],
        Resource: '*',
      }),
    },
    {
      featureName: 'Sending Statistics',
      featureDescription: 'Get sending statistics and quotas',
      permissions: JSON.stringify({
        Sid: 'SendingStatistics',
        Effect: 'Allow',
        Action: [
          'ses:GetAccountSendingEnabled',
          'ses:GetSendStatistics',
          'ses:GetSendQuota',
        ],
        Resource: '*',
      }),
    },
    {
      featureName: 'Mail Sending',
      featureDescription: 'Test send email templates',
      permissions: JSON.stringify({
        Sid: 'MailSending',
        Effect: 'Allow',
        Action: [
          'ses:SendEmail',
          'ses:SendTemplatedEmail',
          'ses:TestRenderTemplate',
        ],
        Resource: '*',
      }),
    },
  ];
};

const CollapsibleContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  marginTop: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.main,
  width: '100%',
  position: 'relative',
}));

const StyledBoxTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '0.9rem',
  paddingBottom: theme.spacing(0.5),
  paddingTop: theme.spacing(1),
}));

const StyledInfoOutlined = styled(InfoOutlined)(({ theme }) => ({
  fontSize: 20,
  marginBottom: -3,
  paddingRight: 3,
}));

export const getCollapseContentRenderer = (content) => {
  const prettyPrinted = JSON.stringify(
    JSON.parse(content.permissions),
    null,
    2
  );
  const isMissingPermissions = content.disabled.length > 0;
  return (
    <Box m={2}>
      <StyledBoxTitle>IAM Policy Statement:</StyledBoxTitle>
      {isMissingPermissions && (
        <>
          <Typography display="block" variant="subtitle2">
            <StyledInfoOutlined color="info" />
            Add <b>{content.disabled.join(', ')}</b> to your policy document in
            IAM, to use this feature in Semplates without issues.
          </Typography>
        </>
      )}
      <CollapsibleContentBox>
        <pre>{prettyPrinted}</pre>
      </CollapsibleContentBox>
    </Box>
  );
};
