import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useLazyLoading } from 'state/hooks';
import { getAccountSendingStatistics } from 'state/actions';
import { SettingsHeader } from 'components/Base';
import AwsAccountSendingStatisticsCard from './AwsAccountSendingStatisticsCard';

const chartOptions = {
  series: [],
  options: {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [2, 2, 2],
      curve: 'smooth',
      dashArray: [0, 8, 5],
      colors: ['#064861', '#FFD301', '#09759C', '#E6BF00'],
    },
    legend: {
      show: true,
      colors: ['#064861', '#FFD301', '#09759C', '#E6BF00'],
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      type: 'datetime',
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  },
};

function sortByTimestamp(array) {
  return array.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
}

const AwsAccountSendingStatisticsSection = ({ open, account, onError }) => {
  const [sendingStatistics, setSendingStatistics] = useState(null);

  const [loadSendingStatistics, { loading, error }] = useLazyLoading({
    action: getAccountSendingStatistics,
    onSuccess: (data) => {
      // order dataPoints by timestamp ascending
      const dataPoints = sortByTimestamp(data.sendDataPoints);
      const deliveryAttempts = dataPoints.map((point) => ({
        x: new Date(point.timestamp).toUTCString(),
        y: point.deliveryAttempts,
      }));
      const bounces = dataPoints.map((point) => ({
        x: new Date(point.timestamp).toUTCString(),
        y: point.bounces,
      }));
      const complaints = dataPoints.map((point) => ({
        x: new Date(point.timestamp).toUTCString(),
        y: point.complaints,
      }));
      const rejects = dataPoints.map((point) => ({
        x: new Date(point.timestamp).toUTCString(),
        y: point.rejects,
      }));
      setSendingStatistics({
        ...data,
        chartOptions: {
          ...chartOptions,
          series: [
            {
              name: 'Delivery Attempts',
              data: deliveryAttempts,
              color: '#064861',
            },
            {
              name: 'Bounces',
              data: bounces,
              color: '#FFD301',
            },
            {
              name: 'Complaints',
              data: complaints,
              color: '#09759C',
            },
            {
              name: 'Rejects',
              data: rejects,
              color: '#E6BF00',
            },
          ],
        },
      });
    },
    onError: () => setSendingStatistics(null),
  });

  useEffect(() => {
    if (open && account.id !== sendingStatistics?.uid) {
      loadSendingStatistics(account.id);
    }
  }, [open, account]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    onError();
  }

  return (
    <Fragment>
      <Box pt={3}>
        <SettingsHeader title={'Sending Statitics of ' + account?.alias} />
        <AwsAccountSendingStatisticsCard
          loading={loading}
          sendingStatistics={sendingStatistics}
        />
      </Box>
    </Fragment>
  );
};

export default AwsAccountSendingStatisticsSection;
