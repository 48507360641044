import React from 'react';
import PropTypes from 'prop-types';
import { useWelcomeSlides } from 'components/WelcomeSlides/hooks';
import { ControlBarContainer, StyledButton } from './styles';
import { SlideIndicator } from 'components/Base';
import { SkipButton } from './Slides/styles';
import { Box } from '@material-ui/core';

const FormControlBar = ({
  onSubmit,
  isSubmitting,
  submitButton,
  allowSkip,
}) => {
  const {
    currentSlide,
    nextSlide,
    slides,
    setCurrentSlide,
  } = useWelcomeSlides();

  const handleSubmit = async () => {
    const res = await onSubmit();
    if (res) {
      nextSlide();
    }
  };

  return (
    <ControlBarContainer>
      <SlideIndicator
        activeIndex={currentSlide}
        slideCount={slides.length}
        onClick={setCurrentSlide}
      />
      <Box>
        {allowSkip && <SkipButton onClick={nextSlide}>Skip</SkipButton>}
        <StyledButton loading={isSubmitting} onClick={handleSubmit}>
          {submitButton ? 'Save' : 'Next'}
        </StyledButton>
      </Box>
    </ControlBarContainer>
  );
};

FormControlBar.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  submitButton: PropTypes.bool,
};

export default FormControlBar;
