import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { FadeMount } from './';

export const Container = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  zIndex: 1,
});

const OverlayInner = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: '#000',
  opacity: '0.2 !important',
  zIndex: 1,
});

const Loader = ({ label, size, overlay, overlayShimmer, spinner }) => (
  <Fragment>
    {overlay && (
      <FadeMount>
        <OverlayInner>
          {overlayShimmer && <div className="shimmer" />}
        </OverlayInner>
      </FadeMount>
    )}
    {spinner && (
      <Container>
        <CircularProgress size={size} />
        {label && (
          <Typography noWrap variant="subtitle2">
            {label}
          </Typography>
        )}
      </Container>
    )}
  </Fragment>
);

Loader.defaultProps = {
  spinner: true,
  overlay: false,
  overlayShimmer: false,
};

Loader.propTypes = {
  label: PropTypes.string,
  size: PropTypes.number,
  spinner: PropTypes.bool,
  overlay: PropTypes.bool,
  overlayShimmer: PropTypes.bool,
};

export default Loader;
