import { showErrorSnackbar, showSuccessSnackbar } from './layout';
import {
  CREATE_TAG_ERROR,
  CREATE_TAG_SUCCESS,
  DELETE_TAG_ERROR,
  DELETE_TAG_SUCCESS,
  UPDATE_TAG_ERROR,
  UPDATE_TAG_SUCCESS,
} from '../consts/snackbarMessages';
import { handleBackendErrorMessage } from '../utils/error_handling';

export const setTags = (tags) => ({
  type: 'TAG_LIST_SET',
  payload: tags,
});

export const update = (tag) => ({
  type: 'TAG_UPDATE',
  payload: tag,
});

export const deleteTag = (id) => ({
  type: 'TAG_REMOVE',
  payload: id,
});

export const getTags = () => async (dispatch, getState, { axios }) => {
  try {
    const { data } = await axios.get(`/tags`);
    dispatch(setTags(data));
    return data;
  } catch (error) {
    throw error;
  }
};

export const createTag = (body) => async (dispatch, getState, { axios }) => {
  try {
    const { data } = await axios.post(`/tag`, body);
    dispatch(setTags(data));
    dispatch(showSuccessSnackbar(CREATE_TAG_SUCCESS));
    return true;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, CREATE_TAG_ERROR);
    throw error;
  }
};

export const removeTag = ({ id }) => async (dispatch, getState, { axios }) => {
  try {
    const {
      data: { id: tagId },
    } = await axios.delete(`/tag/${id}`);
    dispatch(deleteTag(tagId));
    dispatch(showSuccessSnackbar(DELETE_TAG_SUCCESS));

    return true;
  } catch (error) {
    dispatch(showErrorSnackbar(DELETE_TAG_ERROR));
    throw error;
  }
};

export const updateTag = ({ id, label, color }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`/tag`, { id, label, color });
    dispatch(update(data));
    dispatch(showSuccessSnackbar(UPDATE_TAG_SUCCESS));

    return true;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, UPDATE_TAG_ERROR);
    throw error;
  }
};
