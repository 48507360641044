import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import WelcomeSlidesCarousel from './WelcomeSlidesCarousel';
import { styled } from '@material-ui/core/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  width: 920,
  margin: 'auto',

  '& .MuiDialog-paper': {
    borderRadius: '16px',
  },
}));

const WelcomeSlidesDialog = ({ isOpen, closeDialog, backdropClickEnabled }) => {
  return (
    <StyledDialog
      fullWidth={true}
      maxWidth="md"
      open={isOpen}
      onbackdropclick={backdropClickEnabled ? closeDialog : null}
    >
      <WelcomeSlidesCarousel />
    </StyledDialog>
  );
};

WelcomeSlidesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  backdropClickEnabled: PropTypes.bool.isRequired,
};

export default WelcomeSlidesDialog;
