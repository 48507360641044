const privacyPolicy = `<style>
  [data-custom-class='body'], [data-custom-class='body'] * {
    background: transparent !important;
  }

  [data-custom-class='title'], [data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
  }

  [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
    font-family: Arial !important;
    font-size: 14px !important;
  }

  [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
  }

  [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
  }

  [data-custom-class='body_text'], [data-custom-class='body_text'] * {
    font-size: 14px !important;
    font-family: Arial !important;
  }

  [data-custom-class='link'], [data-custom-class='link'] * {
    color: #09759C !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
  }
</style>

<div data-custom-class="body">
  <p style="font-size:15px;"><strong><span style="font-size: 26px;"><span
    data-custom-class="title">PRIVACY NOTICE</span></span></strong></p>
  <p style="font-size:15px;"><span><strong><span data-custom-class="subtitle">Last updated <bdt
    class="question">May 25, 2022</bdt> </span></strong> </span></p>
  <p style="font-size: 15px; line-height: 0.5;"><br></p>
  <p style="font-size: 15px; line-height: 0.5;"><br></p>
  <p style="font-size: 15px; line-height: 0.5;"><br></p>
  <p style="font-size: 15px;"><span style=""><span data-custom-class="body_text">Thank you for choosing to be part of our community at <bdt
    class="question">SaaS Bricks Consulting GmbH</bdt><bdt class="block-component"></bdt> (“<bdt
    class="block-component"></bdt>company<bdt class="statement-end-if-in-editor"></bdt>”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at <bdt
    class="question">clemens@semplates.io</bdt>.</span></span></p>
  <p style="font-size: 15px;"><span style=""><span data-custom-class="body_text">When you visit our <bdt
    class="block-component"></bdt>website <bdt class="question"><a href="https://semplates.io" target="_blank"
                                                                   data-custom-class="link">https://semplates.io</a></bdt>,<bdt
    class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt> and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our <bdt
    class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
    class="block-component"></bdt> and our services.</span></span></p>
  <p style="font-size: 15px;"><span style=""><span data-custom-class="body_text">This privacy policy applies to all information collected through our <bdt
    class="block-component"></bdt><bdt class="forloop-component"></bdt><bdt class="question">website</bdt><bdt
    class="forloop-component"></bdt> (such as <bdt class="question"><a href="https://semplates.io" target="_blank"
                                                                       data-custom-class="link">https://semplates.io</a></bdt>),<bdt
    class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt> and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "<strong>Sites</strong>"). </span></span>
  </p>
  <p style="font-size: 15px;"><span style=""><strong><span data-custom-class="body_text">Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us. </span></strong> </span>
  </p>
  <p style="font-size:15px;"><span style=""><br></span></p>
  <p style="font-size:15px;"><span style=""><strong><span style="font-size: 19px;"><span
    data-custom-class="heading_1">TABLE OF CONTENTS</span></span> </strong> </span></p>
  <p style="font-size:15px;"><a data-custom-class="link" href="#infocollect"><span style="">1. WHAT INFORMATION DO WE COLLECT?</span></a>
    <span style=""><bdt class="block-component"></bdt></span></p>
  <p style="font-size:15px;"><a data-custom-class="link" href="#infouse"><span style="">2. HOW DO WE USE YOUR INFORMATION?</span></a><span
    style=""><span style=""><bdt class="statement-end-if-in-editor"></bdt></span></span>
  </p>
  <p style="font-size:15px;"><span style=""><a data-custom-class="link" href="#infoshare">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></span><span
    style=""> <span style=""><bdt class="block-component"><span
    data-custom-class="body_text"></span></bdt></span></span></p>
  <p style="font-size:15px;"><span style=""><a data-custom-class="link" href="#whoshare">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</a> <span
    style=""><bdt class="statement-end-if-in-editor"></bdt></span></span><span
    style="font-size: 15px; "> <bdt class="block-component"></bdt> </span></p>
  <p style="font-size:15px;"><a data-custom-class="link" href="#cookies"><span
    style="font-size: 15px; ">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></a><span
    style="font-size: 15px; "> <bdt class="statement-end-if-in-editor"></bdt></span><span
    style=""><span style=""> <bdt class="block-component"></bdt> <bdt
    class="block-component"></bdt> <bdt class="block-component"></bdt> </span></span></span><span
    style=""><bdt class="block-component"></bdt> </span></p>
  <p style="font-size:15px;"><a data-custom-class="link" href="#inforetain"><span style="">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a>
  </p>
  <p style="font-size:15px;"><a data-custom-class="link" href="#infosafe"><span style="">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a><span
    style=""> <bdt class="block-component"></bdt> </span></p>
  <p style="font-size:15px;"><a data-custom-class="link" href="#infominors"><span style="">8. DO WE COLLECT INFORMATION FROM MINORS?</span></a>
    <bdt class="statement-end-if-in-editor"></bdt>
     
  </p>
  <p style="font-size:15px;"><a data-custom-class="link" href="#privacyrights"><span style="">9. WHAT ARE YOUR PRIVACY RIGHTS?</span></a>
  </p>
  <p style="font-size:15px;"><a data-custom-class="link" href="#DNT"><span style="">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a><span
    style=""> </span></p>
  <p style="font-size:15px;"><a data-custom-class="link" href="#caresidents"><span style="">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a><span
    style=""> </span></p>
  <p style="font-size:15px;"><a data-custom-class="link" href="#policyupdates"><span style="">12. DO WE MAKE UPDATES TO THIS POLICY?</span></a>
  </p>
  <p style="font-size:15px;"><a data-custom-class="link" href="#contact"><span style="">13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</span></a>
  </p>
  <p style="font-size:15px;"><span style=""><br></span></p>
  <p id="infocollect" style="font-size:15px;"><span style=""><strong><span style="font-size: 19px;"><span
    data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span></span> </strong> </span></p>
  <p style="font-size:15px;"><span style=""><bdt class="block-component"></bdt> <br></span></p>
  <p style="font-size:15px;"><span style=""><strong><span data-custom-class="heading_2">Personal information you disclose to us</span></strong> </span>
  </p>
  <p style="font-size: 15px;"><span style=""><strong><em><span data-custom-class="body_text">In Short:</span></em> </strong><span
    data-custom-class="body_text"><em>We collect personal information that you provide to us such as name, address, contact information, passwords and security data,<bdt
    class="block-component"></bdt> and payment information<span style=""><em><bdt
    class="statement-end-if-in-editor"></bdt></em></span>.</em> </span> </span></p>
  <p style="font-size: 15px;"><span style=""><span data-custom-class="body_text">We collect personal information that you voluntarily provide to us when <bdt
    class="block-component"></bdt>registering at the <bdt class="block-component"></bdt>Sites<bdt
    class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt><span
    style=""><bdt class="statement-end-if-in-editor"></bdt> </span>expressing an interest in obtaining information about us or our products and services, when participating in activities on the <bdt
    class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
    class="block-component"></bdt><bdt class="block-component"></bdt></span></span><span
    style=""><span
    data-custom-class="body_text"> or otherwise contacting us.</span></span></span></span></p>
  <p style="font-size: 15px;"><span style=""><span data-custom-class="body_text">The personal information that we collect depends on the context of your interactions with us and the <bdt
    class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
    class="block-component"></bdt>, the choices you make and the products and features you use. The personal information we collect can include the following:</span></span>
  </p>
  <p style="font-size: 15px;"><span style=""><strong><span data-custom-class="body_text">Name and Contact Data.</span></strong><span
    data-custom-class="body_text">We collect your first and last name, and email address. Upon registration, this information is shared with HubSpot for customer relationship management and marketing purposes. You can review HubSpot's <a href="https://legal.hubspot.com/privacy-policy">privacy policy here: HubSpot Privacy Policy.</a></span></span>
  </p>
  <p style="font-size: 15px;"><span style=""><strong><span data-custom-class="body_text">Credentials.</span></strong><span
    data-custom-class="body_text"> We collect passwords, password hints, and similar security information used for authentication and account access. These credentials are stored securely on Google servers, as we utilize Firebase Authentication for this purpose. For more information, please review Google's privacy policy here: <a href="https://firebase.google.com/support/privacy">Google Privacy Policy</a></span></span>
  </p>
  <p style="font-size: 15px;"><span style=""><strong><span data-custom-class="body_text">Payment Data.</span></strong><span
    data-custom-class="body_text">If you make purchases, we collect data necessary to process your payment, such as your payment instrument number (like a credit card number) and the security code associated with your payment instrument. All payment data is stored by our payment processor, Stripe. We recommend reviewing Stripe's privacy <a href="https://stripe.com/de/privacy">Stripe Privacy Policy</a>.</span><bdt
    class="block-component"><span data-custom-class="body_text"></span></bdt> </span></span></p>
  <p style="font-size: 15px;"><span style=""><span data-custom-class="body_text">Additionally, we use Google Analytics to collect and analyze activity data, combined with your first and last name, to understand how our tool is used. This helps us improve our services and provide a better user experience. For more information on how Google Analytics processes data, please visit <a href="https://policies.google.com/technologies/partner-sites">Google Analytics Privacy.</a></span><span
    style=""><span data-custom-class="body_text"><bdt
    class="statement-end-if-in-editor"></bdt></span> <bdt class="block-component"><span
    data-custom-class="body_text"></span></bdt> </span></span></p>
  <p style="font-size:15px;"><span style=""><br></span></p>
  <p style="font-size:15px;"><span style=""><strong><span data-custom-class="heading_2">Information automatically collected</span></strong> </span>
  </p>
  <p style="font-size: 15px;"><span style=""><strong><em><span data-custom-class="body_text">In Short:</span></em> </strong><em><span
    data-custom-class="body_text">Some information – such as IP address and/or browser and device characteristics – is collected automatically when you visit our <bdt
    class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
    class="block-component"></bdt>.</span></em></span></p>
  <p style="font-size: 15px;"><span style=""><span data-custom-class="body_text">We automatically collect certain information when you visit, use or navigate the <bdt
    class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
    class="block-component"></bdt>. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our <bdt
    class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
    class="block-component"></bdt> and other technical information. This information is primarily needed to maintain the security and operation of our <bdt
    class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
    class="block-component"></bdt>, and for our internal analytics and reporting purposes.<bdt
    class="block-component"></bdt> </span></span></p>
  <p style="font-size: 15px;"><span style=""><span data-custom-class="body_text">Like many businesses, we also collect information through cookies and similar technologies. <bdt
    class="block-component"></bdt><span style=""><bdt class="statement-end-if-in-editor"></bdt><bdt
    class="statement-end-if-in-editor"></bdt></span></span> <bdt class="block-component"></bdt> </span></span>
    <br></span></li></ul>
  <p style="font-size:15px;"><br></p>
  <p style="font-size:15px;"><span style=""><strong><span data-custom-class="heading_2">Information collected from other sources</span></strong> </span>
  </p>
  <p style="font-size: 15px;"><span style=""><strong><em><span style="font-size: 15px;"><span
    data-custom-class="body_text">In Short:</span></span> </em></strong><span style="font-size: 15px;"><em><span
    data-custom-class="body_text">We may collect limited data from public databases, marketing partners, <bdt
    class="block-component"></bdt>and other outside sources. </span></em> </span> </span></p>
  <p style="font-size: 15px;"><span style=" font-size: 15px;"><span
    data-custom-class="body_text">We may obtain information about you from other sources, such as public databases, joint marketing partners,<bdt
    class="block-component"></bdt> as well as from other third parties. Examples of the information we receive from other sources include: social media profile information<bdt
    class="block-component"></bdt></span></span><span data-custom-class="body_text">; marketing leads and search results and links, including paid listings (such as sponsored links).</span><span
    data-custom-class="body_text"> <bdt class="block-component"></bdt> </span></span></p>
  <p style="font-size:15px;"><span style=""><br></span></p>
  <p id="infouse" style="font-size:15px;"><span style=""><strong><span
    style="font-size: 19px;"><span
    data-custom-class="heading_1">2. HOW DO WE USE YOUR INFORMATION?</span></span> </strong> </span></p>
  <p style="font-size: 15px;"><span style=""><strong><em><span style="font-size: 15px;"><span
    data-custom-class="body_text">In Short:</span></span> </em></strong><span style="font-size: 15px;"><em><span
    data-custom-class="body_text">We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</span></em> </span> </span>
  </p>
  <p style="font-size: 15px;"><span style="font-size: 15px;"><span style=""><span
    data-custom-class="body_text">We use personal information collected via our <bdt class="block-component"></bdt>Sites<bdt
    class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt class="block-component"></bdt> for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests ("Business Purposes"), in order to enter into or perform a contract with you ("Contractual"), with your consent ("Consent"), and/or for compliance with our legal obligations ("Legal Reasons"). We indicate the specific processing grounds we rely on next to each purpose listed below. </span></span> </span>
  </p>
  <p style="font-size:15px;"><span style="font-size: 15px;"><span style=""><span
    data-custom-class="body_text">We use the information we collect or receive:<bdt
    class="block-component"></bdt> </span></span> </span></p>
  <ul>
    <li><span style="font-size: 15px;"><span style=""><strong><span
      data-custom-class="body_text">To facilitate account creation and logon process.</span></strong><span
      data-custom-class="body_text">If you choose to link your account with us to a third party account *(such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process. <bdt
      class="block-component"></bdt> <span style="font-size: 15px;"><span style=""><span
      data-custom-class="body_text"> <bdt class="statement-end-if-in-editor"></bdt> </span></span></span><bdt
      class="block-component"></bdt> <bdt class="block-component"></bdt> </span></span> </span> </span> </span> </span>
       </span><br><br></li>
    <li><span style="font-size: 15px;"><span style=""><strong><span
      data-custom-class="body_text">To send administrative information to you.</span></strong><span
      data-custom-class="body_text"> We may use your personal information to send you product, service, service-related and new feature information, industry updates and/or information about changes to our terms, conditions, and policies.</span><span
      style="font-size: 15px;"><span style=""><span style="font-size: 15px;"><span
      style=""><span style="font-size: 15px;"><span style=""><span
      data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt> <bdt class="block-component"></bdt> </span></span> </span> </span> </span> </span> </span> </span> </span><br><br>
    </li>
    <li><span style="font-size: 15px;"><span style=""><strong><span
      data-custom-class="body_text">Fulfill and manage your orders.</span></strong><span data-custom-class="body_text"> We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the <bdt
      class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt
      class="block-component"></bdt><bdt class="block-component"></bdt>.</span><span style="font-size: 15px;"><span
      style=""><span style="font-size: 15px;"><span style=""><span
      style="font-size: 15px;"><span style=""><span style="font-size: 15px;"><span
      style=""><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt> <bdt
      class="block-component"></bdt> <bdt class="block-component"></bdt> <bdt class="block-component"></bdt><bdt
      class="block-component"></bdt> <bdt class="block-component"></bdt> <bdt class="block-component"></bdt> <bdt
      class="block-component"></bdt> <bdt
      class="block-component"></bdt> </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span>
      <br><br></li>
    <li><span style=" font-size: 15px;"><strong><span data-custom-class="body_text">For other Business Purposes.</span></strong><span
      data-custom-class="body_text"> We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our <bdt
      class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt
      class="block-component"></bdt><bdt class="block-component"></bdt>, products, services, marketing and your experience.</span><span
      style="font-size: 15px;"><span style=""><span style="font-size: 15px;"><span
      style=""><span style="font-size: 15px;"><span style=""><span
      style="font-size: 15px;"><span style=""><span style="font-size: 15px;"><span
      style=""><span style="font-size: 15px;"><span style=""><span
      style="font-size: 15px;"><span style=""><span style="font-size: 15px;"><span
      style=""><span style="font-size: 15px;"><span style=""><span
      style="font-size: 15px;"><span style=""><span style="font-size: 15px;"><span
      style=""><span style="font-size: 15px;"><span style=""><span
      style="font-size: 15px;"><span style=""><span data-custom-class="body_text"><bdt
      class="statement-end-if-in-editor"></bdt> </span><span style=" font-size: 15px;"><span
      data-custom-class="body_text"><bdt
      class="statement-end-if-in-editor"></bdt> </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span>
    </li>
  </ul>
  <p style="font-size:15px;"><span style=""><br></span></p>
  <p id="infoshare" style="font-size:15px;"><span style=""><strong><span
    style="font-size: 19px;"><span data-custom-class="heading_1">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></span> </strong> </span>
  </p>
  <p style="font-size: 15px;"><span style=""><strong><em><span style="font-size: 15px;"><span
    data-custom-class="body_text">In Short:</span></span> </em></strong><span style="font-size: 15px;"><em><span
    data-custom-class="body_text">We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations. </span></em> </span> </span>
  </p>
  <div><br></div>
  <div><span style=" font-size: 15px;"><span data-custom-class="body_text">We may process or share data based on the following legal basis:</span></span>
  </div>
  <ul>
    <li><span data-custom-class="body_text"><span
      style=" font-size: 15px;"><strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information in a specific purpose. </span><br><br></span>
    </li>
    <li><span data-custom-class="body_text"><span style=" font-size: 15px;"><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests. </span><br><br></span>
    </li>
    <li><span data-custom-class="body_text"><span style=" font-size: 15px;"><strong>Performance of a Contract: </strong>Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract. </span><br><br></span>
    </li>
    <li><span data-custom-class="body_text"><span style=" font-size: 15px;"><strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements). </span><br><br></span>
    </li>
    <li><span style=" font-size: 15px;"><span data-custom-class="body_text"><strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</span></span>
    </li>
  </ul>
  <p style="font-size:15px;"><span style="font-size: 15px;"><span style=""><span
    data-custom-class="body_text">More specifically, we may need to process your data or share your personal information in the following situations:</span></span></span><span
    style="font-size: 15px;"> </span><span style="font-size: 15px;"><span style=""><span
    data-custom-class="body_text"><bdt class="block-component"></bdt> </span></span> </span></p>
  <ul>
    <li><span style="font-size: 15px;"><span style=""><strong><span
      data-custom-class="body_text">Vendors, Consultants and Other Third-Party Service Providers.</span></strong><span
      data-custom-class="body_text"> We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the <bdt
      class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt
      class="block-component"></bdt><bdt class="block-component"></bdt>, which will enable them to collect data about how you interact with the <bdt
      class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt
      class="block-component"></bdt><bdt class="block-component"></bdt> over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.</span><span
      style="font-size: 15px;"><span style=""><span data-custom-class="body_text"><bdt
      class="statement-end-if-in-editor"></bdt> </span></span> </span></span></span><br><br></li>
    <li><span style="font-size: 15px;"><span style=""><strong><span
      data-custom-class="body_text">Business Transfers.</span></strong><span data-custom-class="body_text"> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.<bdt
      class="block-component"></bdt> <bdt class="block-component"></bdt> </span></span> </span> </span>
      <bdt class="block-component"></bdt>
       </span></span></span></span></span><span style="font-size: 15px;"><span style=""><span
        data-custom-class="body_text"><bdt class="block-component"></bdt> <bdt
        class="block-component"></bdt></span> </span> </span></span></li>
  </ul>
  <div><span style=""><bdt class="block-component"><span
    data-custom-class="body_text"></span></bdt></span>
    <p id="whoshare" style="font-size:15px;"><span style=""><strong><span
      style="font-size: 19px;"><span
      data-custom-class="heading_1">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</span></span> </strong> </span></p>
  </div>
  <div><br></div>
  <div><span style=""><strong><em><span style="font-size: 15px;"><span
    data-custom-class="body_text">In Short:</span></span> </em> </strong><span style="font-size: 15px;"><em><span
    data-custom-class="body_text">We only share information with the following third parties.</span></em> </span> </span>
  </div>
  <div><span style=""><span style="font-size: 15px;"> </span> </span></div>
  <div><span style="font-size: 15px;"><span style=""><span data-custom-class="body_text">We only share and disclose your information with the following third parties. We have categorized each party so that you may be easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us.</span></span>  </span>
  </div>
  <div>
    <bdt class="block-component"><span data-custom-class="body_text"></bdt>
    </span></span></span></span></span></li></ul>
    <div>
      <bdt class="block-component"><span data-custom-class="body_text"></bdt>
      </span></span></span></span></span></li></ul>
      <div>
        <bdt class="block-component"><span data-custom-class="body_text"></bdt>
        </span></span></span></span></span></li></ul>
        <div>
          <bdt class="block-component"><span data-custom-class="body_text"></bdt>
          </span></span></span></span></span></li></ul>
          <div>
            <bdt class="block-component"><span data-custom-class="body_text"></bdt>
            </span></span></span></span></span></li></ul>
            <div>
              <bdt class="block-component"><span data-custom-class="body_text"></bdt>
              </span></span></span></span></span></li></ul>
              <div>
                <bdt class="block-component"><span data-custom-class="body_text"></bdt>
                </span></span></span></span></span></li></ul>
                <div>
                  <bdt class="block-component"><span data-custom-class="body_text"></bdt>
                  </span></span></span></span></span></li></ul>
                  <div>
                    <bdt class="block-component"><span data-custom-class="body_text"></bdt>
                    </span></span></span></span></span></li></ul>
                    <div>
                      <bdt class="block-component"><span data-custom-class="body_text"></bdt>
                      </span></span></span></span></span></li></ul>
                      <div>
                        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                      </div>
                      <ul>
                        <li><span style="font-size: 15px;"><span style=""><span
                          data-custom-class="body_text"><strong>User Account Registration and Authentication</strong></span></span></span><br><span
                          style="font-size: 15px;"><span style=""><bdt class="forloop-component"><span
                          data-custom-class="body_text"></span></bdt><span data-custom-class="body_text"><span
                          style="font-size: 15px;"><span style=""><bdt
                          class="block-component"></bdt></span></span><span style="font-size: 15px;"><span
                          style=""><bdt
                          class="question">Firebase Authentication</bdt></span></span><span
                          style="font-size: 15px;"><span style=""><bdt
                          class="block-component"></bdt></span></span></span></span><bdt
                          class="forloop-component"></bdt><bdt
                          class="statement-end-if-in-editor"></bdt></span></span></span></span></span></li>
                      </ul>
                      <div>
                        <bdt class="block-component"><span data-custom-class="body_text"></bdt>
                        </span></span></span></span></span></li></ul>
                        <div>
                          <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                        </div>
                        <ul>
                          <li><span style="font-size: 15px;"><span style=""><span
                            data-custom-class="body_text"><strong>Web and Mobile Analytics</strong></span></span></span><br><span
                            style="font-size: 15px;"><span style=""><bdt
                            class="forloop-component"><span data-custom-class="body_text"></span></bdt><span
                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                            style=""><bdt class="block-component"></bdt></span></span><span
                            style="font-size: 15px;"><span style=""><bdt class="question">Google Analytics 4, Google Analytics for Firebase</bdt></span><span
                            class="block-component"></bdt></span></span></span></span><bdt
                            class="forloop-component"></bdt><bdt
                            class="statement-end-if-in-editor"></bdt></span></span></span></span></span></li>
                        </ul>
                        <div>
                          <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                        </div>
                        <ul>
                          <li><span style="font-size: 15px;"><span style=""><span
                            data-custom-class="body_text"><strong>Website Hosting</strong></span></span></span><br><span
                            style="font-size: 15px;"><span style=""><bdt
                            class="forloop-component"><span data-custom-class="body_text"></span></bdt><span
                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                            style=""><bdt class="block-component"></bdt></span></span><span
                            style="font-size: 15px;"><span style=""><bdt class="question">Google Firebase</bdt></span></span><span
                            style="font-size: 15px;"><span style=""><bdt
                            class="block-component"></bdt></span></span></span></span><bdt
                            class="forloop-component"></bdt><bdt
                            class="statement-end-if-in-editor"></bdt></span></span></span></span></span></li>
                        </ul>
                        <div>
                          <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                        </div>
                        <ul>
                          <li><span style="font-size: 15px;"><span style=""><span
                            data-custom-class="body_text"><strong>Website Performance Monitoring</strong></span></span></span><br><span
                            style="font-size: 15px;"><span style=""><bdt
                            class="forloop-component"><span data-custom-class="body_text"></span></bdt><span
                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                            style=""><bdt class="block-component"></bdt></span></span><span
                            style="font-size: 15px;"><span style=""><bdt class="question">Firebase Crash Reporting</bdt></span></span><span
                            style="font-size: 15px;"><span style=""><bdt
                            class="block-component"></bdt></span></span></span></span><bdt
                            class="forloop-component"></span></bdt><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span style=""><bdt
                            class="block-component"></bdt></span></span> and <bdt class="question">Firebase Performance Monitoring</bdt><span
                            style="font-size: 15px;"><span style=""><span
                            style="font-size: 15px;"><span style=""><bdt
                            class="statement-end-if-in-editor"></bdt></span></span></span></span><bdt
                            class="forloop-component"></bdt><bdt
                            class="statement-end-if-in-editor"></bdt></span></span></span></span></span></li>
                        </ul>
                        <div>
                          <bdt class="block-component"><span data-custom-class="body_text"></bdt>
                          </span></span></span></span></span></li></ul>
                          <div><span data-custom-class="body_text"></span><span
                            data-custom-class="body_text"></span><span data-custom-class="body_text"></span><span
                            data-custom-class="body_text"></span><span data-custom-class="body_text"></span><span
                            data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span><span
                            style=" font-size: 15px;"><span style="font-size: 15px;"><span
                            style=""><span style="font-size: 15px;"><span
                            style=""><bdt class="block-component"><span
                            data-custom-class="heading_1"></span></bdt></span></span></span></span></span></div>
                          <div><br></div>
                          <div><span id="cookies" style=""><strong><span
                            style="font-size: 19px;"><span data-custom-class="heading_1">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></span></strong></span>
                          </div>
                          <p style="font-size: 15px;"><span style=""><strong><em><span
                            style="font-size: 15px;"><span
                            data-custom-class="body_text">In Short:</span></span> </em></strong><span
                            style="font-size: 15px;"><em><span data-custom-class="body_text">We may use cookies and other tracking technologies to collect and store your information.</span></em> </span> </span>
                          </p>
                          <p style="font-size: 15px;"><span style=" font-size: 15px;"><span
                            data-custom-class="body_text">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy<bdt
                            class="block-component"></bdt>.</span><span
                            style=" font-size: 15px;"><span style="font-size: 15px;"><span
                            style=""><span style="font-size: 15px;"><span
                            style=""><span data-custom-class="body_text"><bdt
                            class="statement-end-if-in-editor"></bdt> <bdt class="block-component"></bdt><bdt
                            class="block-component"></bdt> <bdt
                            class="block-component"></bdt>  </span></span></span></span></span></span></span></span></span></span>
                            <span style=" font-size: 15px;"><span
                              style=" font-size: 15px;"><span
                              style=" font-size: 15px;"><span
                              style=" font-size: 15px;"><span
                              style=" font-size: 15px;"><span style="font-size: 15px;"><span
                              style=""><span style="font-size: 15px;"><span
                              style=""><span data-custom-class="body_text"><bdt
                              class="block-component"></span></bdt>
                               </span></span></span></span></span></span></span></span></p>
                          <p style="font-size:15px;"><span style=""><br></span></p>
                          <p id="inforetain" style="font-size:15px;"><span style=""><strong><span
                            style="font-size: 19px;"><span data-custom-class="heading_1">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span></span> </strong></span>
                          </p>
                          <p style="font-size: 15px;"><span style=""><strong><em><span
                            style="font-size: 15px;"><span
                            data-custom-class="body_text">In Short:</span></span> </em></strong><span
                            style="font-size: 15px;"><em><span data-custom-class="body_text">We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</span></em> </span> </span>
                          </p>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                            style=""><span data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than <bdt
                            class="block-component"></bdt><bdt class="question">90 days past the termination of the user's account</bdt><bdt
                            class="else-block"></bdt>.</span></span></span></p>
                          <p style="font-size: 15px;"><span style=" font-size: 15px;"><span
                            data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></span>
                          </p>
                          <p style="font-size:15px;"><span style=""><br></span></p>
                          <p id="infosafe" style="font-size:15px;"><span style=""><strong><span
                            style="font-size: 19px;"><span data-custom-class="heading_1">7. HOW DO WE KEEP YOUR INFORMATION SAFE? </span></span></strong></span>
                          </p>
                          <p style="font-size: 15px;"><span style=""><strong><em><span
                            style="font-size: 15px;"><span
                            data-custom-class="body_text">In Short:</span></span> </em></strong><span
                            style="font-size: 15px;"><em><span data-custom-class="body_text">We aim to protect your personal information through a system of organisational and technical security measures.</span></em> </span></span>
                          </p>
                          <p style="font-size: 15px;"><span style=" font-size: 15px;"><span
                            data-custom-class="body_text">We have implemented appropriate technical and organisational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our <bdt
                            class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt
                            class="block-component"></bdt><bdt class="block-component"></bdt> is at your own risk. You should only access the services within a secure environment.<bdt
                            class="block-component"></bdt> </span></span></p>
                          <p style="font-size:15px;"><span style=""><br></span></p>
                          <p id="infominors" style="font-size:15px;"><span style=""><strong><span
                            style="font-size: 19px;"><span data-custom-class="heading_1">8. DO WE COLLECT INFORMATION FROM MINORS?</span></span></strong></span>
                          </p>
                          <p style="font-size: 15px;"><span style=""><strong><em><span
                            style="font-size: 15px;"><span
                            data-custom-class="body_text">In Short:</span></span> </em></strong><span
                            style="font-size: 15px;"><em><span data-custom-class="body_text">We do not knowingly collect data from or market to children under 18 years of age.</span></em></span></span>
                          </p>
                          <p style="font-size: 15px;"><span style=" font-size: 15px;"><span
                            data-custom-class="body_text">We do not knowingly solicit data from or market to children under 18 years of age. By using the <bdt
                            class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt
                            class="block-component"></bdt><bdt class="block-component"></bdt>, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the <bdt
                            class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt
                            class="block-component"></bdt><bdt class="block-component"></bdt>. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at <bdt
                            class="block-component"></bdt><bdt class="question">clemens@semplates.io</bdt><bdt
                            class="else-block"></bdt>.</span><span
                            style=" font-size: 15px;"><span data-custom-class="body_text"><bdt
                            class="statement-end-if-in-editor"></bdt> </span></span></span></p>
                          <p style="font-size:15px;"><span style=""><br></span></p>
                          <p id="privacyrights" style="font-size:15px;"><span style=""><strong><span
                            style="font-size: 19px;"><span data-custom-class="heading_1">9. WHAT ARE YOUR PRIVACY RIGHTS?</span></span> </strong></span>
                          </p>
                          <p style="font-size: 15px;"><span style=""><strong><em><span
                            style="font-size: 15px;"><span
                            data-custom-class="body_text">In Short:</span></span> </em></strong><span
                            style="font-size: 15px;"><span data-custom-class="body_text"><em>In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</em></span></span></span>
                          </p>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                            style=""><span data-custom-class="body_text">In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the </span></span></span><span
                            style=""><span data-custom-class="body_text"><a
                            data-custom-class="link" href="#contact"><span
                            style="font-size: 15px;">contact details</span></a></span></span><span
                            style="font-size: 15px;"><span style=""><span
                            data-custom-class="body_text"> provided below. We will consider and act upon any request in accordance with applicable data protection laws.</span></span> </span>
                          </p>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                            style=""><span data-custom-class="body_text">If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.</span></span></span>
                          </p>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                            style=""><span data-custom-class="body_text">If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:</span></span> </span><span
                            data-custom-class="body_text"><span style=""><a
                            data-custom-class="link"
                            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                            target="_blank"><span style="font-size: 15px;">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a> </span></span><span
                            style="font-size: 15px;"><span style=""><bdt class="block-component"><span
                            data-custom-class="body_text"></span></bdt><br></span> </span></p>
                          <p style="font-size:15px;"><span style="font-size: 15px;"><span
                            style=""><strong><span
                            data-custom-class="heading_2">Account Information</span></strong> </span></span></p>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                            style=""><span data-custom-class="body_text">If you would at any time like to review or change the information in your account or terminate your account, you can:<bdt
                            class="forloop-component"></bdt> </span></span> </span></p>
                          <p style="font-size: 15px; margin-left: 20px;"><span style="font-size:13px;">■  </span><span
                            style="font-size: 15px;"><span style=""><span
                            data-custom-class="body_text"><bdt class="question">Log into your account settings and update your user account.</bdt> <bdt
                            class="forloop-component"></bdt> </span></span> </span></p>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                            style=""><span data-custom-class="body_text">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</span><span
                            style="font-size: 15px;"><span style=""><span
                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                            style=""><bdt class="statement-end-if-in-editor"></bdt><bdt
                            class="block-component"></bdt></span></span> </span> </span> </span> </span> </span></p>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                            style=""><strong><u><span data-custom-class="body_text">Cookies and similar technologies:</span></u> </strong><span
                            data-custom-class="body_text"> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our <bdt
                            class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt
                            class="block-component"></bdt><bdt class="block-component"></bdt>. To opt-out of interest-based advertising by advertisers on our <bdt
                            class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt
                            class="block-component"></bdt><bdt
                            class="block-component"></bdt> visit </span></span></span><span
                            style=""><span data-custom-class="body_text"><ahref="http://www.aboutads.info/choices/" target="_blank"><span style="font-size: 15px;">http://www.aboutads.info/choices/</span></a></span></span><span
                            style="font-size: 15px;"><span style=""><span
                            data-custom-class="body_text">. <bdt class="block-component"></bdt><span
                            style="font-size: 15px;"><span style=""><span
                            style="font-size: 15px;"><span style=""><span
                            style="font-size: 15px;"><span style=""><bdt
                            class="statement-end-if-in-editor"></bdt></span></span> </span></span></span></span><bdt
                            class="block-component"></bdt> </span></span></span></p>
                          <p style="font-size: 15px;"><span style=""><span
                            style="font-size: 15px;"><strong><u><span data-custom-class="body_text">Opting out of email marketing:</span></u> </strong><span
                            data-custom-class="body_text">You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we will still need to send you service-related emails that are necessary for the administration and use of your account. To otherwise opt-out, you may:<bdt
                            class="forloop-component"></bdt> </span></span> </span></p>
                          <p style="font-size: 15px; margin-left: 20px;"><span style="font-size:13px;">■  </span><span
                            style=""><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><bdt class="question">Note your preferences when you register an account with the site.</bdt> <bdt
                            class="forloop-component"></bdt> </span></span> </span></p>
                          <p style="font-size: 15px; margin-left: 20px;"><span style="font-size:13px;">■  </span><span
                            style=""><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><bdt class="question">Access your account settings and update preferences.</bdt> <bdt
                            class="forloop-component"></bdt> <span style="font-size: 15px;"><bdt
                            class="statement-end-if-in-editor"></bdt></span></span></span></span></p>
                          <p id="DNT" style="font-size:15px;"><span style=""><strong><span
                            style="font-size: 19px;"><span data-custom-class="heading_1">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></span> </strong> </span>
                          </p>
                          <p style="font-size: 15px;"><span style=""><span
                            style="font-size: 15px;"><span data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.</span></span> </span>
                          </p>
                          <div><br></div>
                          <p id="caresidents" style="font-size:15px;"><span style=""><strong><span
                            style="font-size: 19px;"><span data-custom-class="heading_1">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></span></strong></span>
                          </p>
                          <p style="font-size: 15px;"><span style=""><strong><em><span
                            style="font-size: 15px;"><span
                            data-custom-class="body_text">In Short:</span></span> </em></strong><span
                            style="font-size: 15px;"><em><span data-custom-class="body_text">Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information. </span></em> </span></span>
                          </p>
                          <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                            style=""><span data-custom-class="body_text">California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span></span> </span>
                          </p>
                          <p style="font-size: 15px;"><span style=" font-size: 15px;"><span
                            data-custom-class="body_text">If you are under 18 years of age, reside in California, and have a registered account with the <bdt
                            class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt
                            class="block-component"></bdt><bdt class="block-component"></bdt>, you have the right to request removal of unwanted data that you publicly post on the <bdt
                            class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt
                            class="block-component"></bdt><bdt class="block-component"></bdt>. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the <bdt
                            class="block-component"></bdt>Sites<bdt class="statement-end-if-in-editor"></bdt><bdt
                            class="block-component"></bdt><bdt class="block-component"></bdt>, but please be aware that the data may not be completely or comprehensively removed from our systems.</span></span>
                          </p>
                          <p style="font-size:15px;"><span style=""><br></span></p>
                          <p id="policyupdates" style="font-size:15px;"><span style=""><strong><span
                            style="font-size: 19px;"><span data-custom-class="heading_1">12. DO WE MAKE UPDATES TO THIS POLICY?</span></span></strong></span>
                          </p>
                          <p style="font-size: 15px;"><span style=""><strong><em><span
                            style="font-size: 15px;"><span
                            data-custom-class="body_text">In Short:</span></span> </em></strong><span
                            style="font-size: 15px;"><em><span data-custom-class="body_text">Yes, we will update this policy as necessary to stay compliant with relevant laws.</span></em></span></span>
                          </p>
                          <p style="font-size: 15px;"><span style=" font-size: 15px;"><span
                            data-custom-class="body_text">We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information. </span></span>
                          </p>
                          <p style="font-size:15px;"><span style=""><br></span></p>
                          <p id="contact" style="font-size:15px;"><span style=""><strong><span
                            style="font-size: 19px;"><span data-custom-class="heading_1">13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</span></span></strong></span>
                          </p>
                          <p style="font-size: 15px;"><span style=" font-size: 15px;"><span
                            data-custom-class="body_text">If you have questions or comments about this policy, you may <bdt
                            class="block-component"></bdt>email us at <bdt class="question">clemens@semplates.io</bdt><bdt
                            class="statement-end-if-in-editor"></bdt> or by post to:</span></span></p>
                          <div><span style=""><bdt class="question"><span
                            style="font-size: 15px;"><span data-custom-class="body_text">SaaS Bricks Consulting GmbH</span></span></bdt><span
                            data-custom-class="body_text"><span style="font-size: 15px;"><bdt
                            class="block-component"></bdt></span></span></span></div>
                          <div><span data-custom-class="body_text"><span style=""><span
                            style="font-size: 15px;"><bdt class="question">Torfstraße 19</bdt><bdt
                            class="block-component"></bdt></span></span></span></span></div>
                          <div><span data-custom-class="body_text"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"><span style=""><bdt class="question">Berlin</bdt>,<bdt
                            class="block-component"></bdt> <bdt class="question">13353</bdt><bdt
                            class="block-component"></bdt></span></span><bdt
                            class="block-component"></bdt></span></span><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><bdt class="question">Germany</bdt><bdt
                            class="statement-end-if-in-editor"></bdt><span data-custom-class="body_text"><span
                            style=""><bdt class="statement-end-if-in-editor"></bdt><bdt
                            class="statement-end-if-in-editor"></bdt></span></span></span></span><span
                            style=""><span style="font-size: 15px;"><span
                            data-custom-class="body_text"><bdt class="block-component"></bdt></span></span></span>
                            <bdt class="block-component"><span data-custom-class="body_text"></bdt>
                            </span></span></div>
                          <p style="font-size:15px;"><span style=""><br></span></p>
                          <p id="contact" style="font-size:15px;"><strong><span data-custom-class="heading_1">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong>
                          </p>
                          <div><span data-custom-class="body_text">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please <bdt
                            class="block-component"></bdt>visit: <bdt class="question">service.semplates.io/user</bdt><bdt
                            class="else-block"></bdt>. We will respond to your request within 30 days.</span></div>
                          <style>
                            ul {
                              list-style-type: square;
                            }

                            ul > li > ul {
                              list-style-type: circle;
                            }

                            ul > li > ul > li > ul {
                              list-style-type: square;
                            }

                            ol li {
                              font-family: Arial;
                            }
                          </style>
                        </div>
`;

export default privacyPolicy;
