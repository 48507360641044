import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useAsyncAction } from 'state/hooks';
import { getUserDetails } from 'state/actions';
import { ActionRequired } from 'components/UserActionRequired';
import { LogoLoader } from '../Base';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Box, Button, List, ListItem, styled } from '@material-ui/core';

const UserContext = createContext(null);

const AlertContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
  width: '100vw',
}));

const UserProvider = ({ children }) => {
  const { loading, error } = useAsyncAction({ action: getUserDetails });

  if (error && error.includes('Network Error')) {
    return (
      <AlertContainer>
        <Alert severity="warning">
          <AlertTitle>An unexpected Error occurs</AlertTitle>
          Unfortunately, it seems like we are having some technical issues. We
          hope to be back up soon. Sorry for the inconvenience.
        </Alert>
      </AlertContainer>
    );
  }

  if (error && error.includes('403')) {
    return (
      <Box m={4}>
        <Alert severity="warning">
          <AlertTitle>
            You don't have access to this company anymore :(
          </AlertTitle>
          We could not find a company your user belongs to. This can have two
          different reasons:
          <List>
            <ListItem>
              1. An admin has removed you from the company, so you do not have
              access to it any longer.
            </ListItem>
            <ListItem>
              2. An error occurred on our side and we cannot match your login
              credentials to a registered user account.
            </ListItem>
          </List>
          <Button href="/login">Try to login again</Button>
        </Alert>
      </Box>
    );
  }

  if (error) return <div>{error}</div>;

  if (loading) return <LogoLoader />;

  return (
    <UserContext.Provider>
      {children}
      <ActionRequired />
    </UserContext.Provider>
  );
};

UserContext.propTypes = {
  children: PropTypes.any,
};

export { UserProvider, UserContext };

export default UserContext;
