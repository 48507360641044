import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';

export const PageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  height: 'calc(100% - 72px)',
  overflow: 'hidden',
}));

export const TextContainer = styled('div')(({ theme, width }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  padding: theme.spacing(8, 6, 0, 6),
  width: width ? `${width}%` : '45%',
}));

export const ImageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '55%',
}));

export const Headline = styled((props) => (
  <Typography variant="h1" {...props} />
))(({ theme }) => ({
  fontSize: 36,
  lineHeight: 1.2,
  textAlign: 'initial',
  color: theme.palette.text.main,
  fontFamily: theme.typography.fontFamilyTitle,
  fontWeight: 700,
  marginBottom: theme.spacing(2),
}));

export const Text = styled((props) => (
  <Typography variant="body1" {...props} />
))(({ theme }) => ({
  fontSize: 18,
  textAlign: 'initial',
  fontWeight: 500,
  marginBottom: theme.spacing(4),

  '& a': {
    textDecoration: 'none',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

export const SkipButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey[500],
  color: theme.palette.text.reverted,
  minWidth: 80,
  textDecoration: 'none',
  textTransform: 'none',

  '&:hover': {
    backgroundColor: theme.palette.grey[600],
  },
}));
