import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@material-ui/core';
import { Close } from 'assets/icons';

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.reverted,
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },

  '& .MuiSvgIcon-root': {
    color: `${theme.palette.text.reverted} !important`,
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.defaultReverted,
}));

const DialogFormFrame = ({
  actions,
  formComponent,
  onClose,
  open,
  maxWidth,
  title,
  innerContentRef,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={maxWidth || 'sm'}
    >
      {onClose && (
        <CloseButton onClick={onClose}>
          <Close size={25} />
        </CloseButton>
      )}
      <StyledDialogTitle id="form-dialog-title">{title}</StyledDialogTitle>
      <DialogContent
        ref={innerContentRef}
        dividers
        style={{ position: 'relative', minHeight: '6rem' }}
      >
        {formComponent}
      </DialogContent>
      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </Dialog>
  );
};

DialogFormFrame.propTypes = {
  formComponent: PropTypes.node,
  title: PropTypes.string.isRequired,
};

export default DialogFormFrame;
