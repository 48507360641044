import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { LogoLoader } from 'components/Base';
import {
  SET_PLACEHOLDER_EXTRACTION_ERROR,
  SET_PLACEHOLDER_INTERPOLATION_ERROR,
} from 'state/consts/snackbarMessages';
import {
  clearSnackbar,
  getTemplate,
  setSendTemplateOptions,
  showErrorSnackbar,
} from 'state/actions';
import { useAsyncAction } from 'state';
import { useParams } from 'react-router-dom';
import {
  extractHandlebarsPlaceholdersFromTemplate,
  flattenPlaceholerObject,
  hasComplexContent,
  isDeepObject,
  initFieldsInArray,
  sortFlatArray,
  cleanStringData,
  interpolateSingleString,
} from 'components/Menu/Templates/Preview/utils';
import { useLocalStorage } from '../../state/hooks';

const PlaceholderContext = React.createContext(null);

export const usePlaceholders = () => useContext(PlaceholderContext);

const PlaceholderProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [originalTemplate, setOriginalTemplate] = useState(null);
  const [interpolatedTemplate, setInterpolatedTemplate] = useState(null);
  const [gotValues, setGotValues] = useState(false);

  // Handlebars placeholders as extracted from the template
  const [originalPlaceholderObject, setOriginalPlaceholderObject] = useState(
    null
  );

  // Store this in local storage, so user can change template while placeholder data is persisted,
  const [
    displayedPlaceholderObject,
    setDisplayedPlaceholderObject,
  ] = useLocalStorage('displayedPlaceholderObject', {});

  // Latest template id - Used to restore placeholder from local storage
  const [latestTemplateId, setLatestTemplateId] = useLocalStorage(
    'latestTemplateId',
    null
  );

  // Go get selectedTemplate and set it if not already existing in redux state.
  const {
    loading: loadingTemplate,
    error: errorLoadingTemplate,
  } = useAsyncAction({
    action: getTemplate,
    values: { id },
    onSuccess: (data) => setOriginalTemplate(data),
  });

  // Extract placeholders from template and set them
  const extractPlaceholders = () => {
    if (originalTemplate) {
      // ToDo: Wrong, but also does not hurt.
      dispatch(clearSnackbar());
      // Retrieve all placeholders from template
      let extractedPlaceholders;
      try {
        extractedPlaceholders = extractHandlebarsPlaceholdersFromTemplate(
          originalTemplate
        );
      } catch (error) {
        console.log(error);
        dispatch(showErrorSnackbar(SET_PLACEHOLDER_EXTRACTION_ERROR));
        extractedPlaceholders = {};
      }

      // Early stop, if no placeholders found
      if (!extractedPlaceholders.length) {
        setOriginalPlaceholderObject(null);
        setDisplayedPlaceholderObject({});
      }

      // Store extracted placeholders
      if (!latestTemplateId || latestTemplateId !== originalTemplate.uid) {
        // If no template id is stored, store uid
        setLatestTemplateId(originalTemplate.uid);
        // If no template has been set, set placeholders now.
        setDisplayedPlaceholderObject(extractedPlaceholders);
        // Store for later use, e.g. resetting preview screen.
        setOriginalPlaceholderObject(extractedPlaceholders);
      } else if (latestTemplateId === originalTemplate.uid) {
        // If template id is stored, check if it matches current template uid

        // Use existing placeholders in local storage if keys have not changed
        if (
          displayedPlaceholderObject === null ||
          Object.keys(displayedPlaceholderObject) !==
            Object.keys(extractedPlaceholders)
        ) {
          setDisplayedPlaceholderObject(extractedPlaceholders);
        }
        // Store for later use, e.g. resetting preview screen.
        setOriginalPlaceholderObject(extractedPlaceholders);
      }
    }
  };

  useEffect(() => {
    // Extract variables once originalTemplate is loaded + if not extracted yet.
    if (originalPlaceholderObject == null && originalTemplate) {
      extractPlaceholders();
    }
  }, [originalTemplate, originalPlaceholderObject]); // eslint-disable-line

  // Interpolate the template with the data
  // eslint-disable-next-line
  const interpolateTemplate = () => {
    // If template not loaded or no data, return
    if (!originalTemplate || !originalTemplate.html) return;

    // Initialize vars with original content
    let copiedTemplate = originalTemplate;
    let copiedHTML = originalTemplate.html;
    let copiedDesign = JSON.parse(originalTemplate.design);
    let copiedPreheaderText = copiedDesign.body?.values?.preheaderText || '';
    let copiedSubject = originalTemplate.subject;

    // Interpolation using handlebars
    copiedHTML = interpolateSingleString(
      displayedPlaceholderObject,
      copiedHTML
    );

    copiedSubject = interpolateSingleString(
      displayedPlaceholderObject,
      copiedSubject
    );

    if (copiedDesign?.body?.values.preheaderText) {
      copiedPreheaderText = interpolateSingleString(
        displayedPlaceholderObject,
        copiedPreheaderText
      );
      copiedDesign.body.values.preheaderText = copiedPreheaderText;
    }

    // Reconstruct template structure and set state
    setInterpolatedTemplate({
      ...copiedTemplate,
      subject: copiedSubject,
      design: JSON.stringify(copiedDesign),
      html: copiedHTML,
    });
  };

  // Reset interpolated template and variables
  const resetInterpolation = () => {
    setInterpolatedTemplate(null);
    setGotValues(false);
    setDisplayedPlaceholderObject(originalPlaceholderObject);
  };

  // Everytime displayedArrayStructure is updated, try to interpolate.
  useEffect(() => {
    // Only run this if all data is available
    if (gotValues) {
      try {
        interpolateTemplate();
      } catch (error) {
        dispatch(showErrorSnackbar(SET_PLACEHOLDER_INTERPOLATION_ERROR));
        resetInterpolation();
      }
    }
  }, [gotValues]); // eslint-disable-line

  const sendMailViaBackend = () => {
    dispatch(
      setSendTemplateOptions({
        open: true,
        template: originalTemplate,
        templateInputs: displayedPlaceholderObject,
      })
    );
  };

  const handleInterpolation = (variables) => {
    // If editor field is empty, display error message and return
    if (
      Object.keys(variables).includes('jsonStructure') &&
      !variables['jsonStructure']
    ) {
      dispatch(showErrorSnackbar(SET_PLACEHOLDER_INTERPOLATION_ERROR));
      resetInterpolation();
      return;
    }
    // Disable interpolation trigger
    setGotValues(false);
    // Clean data
    const cleanedData = JSON.parse(
      Object.keys(variables).includes('jsonStructure')
        ? cleanStringData(variables['jsonStructure'])
        : cleanStringData(JSON.stringify(variables))
    );
    setDisplayedPlaceholderObject(cleanedData);
    // Trigger interpolation
    setGotValues(true);
  };

  const templateCanBeSend = () => {
    return (
      !Object.keys(displayedPlaceholderObject).length ||
      displayedPlaceholderObject !== originalPlaceholderObject
    );
  };

  if (!originalTemplate || loadingTemplate) return <LogoLoader />;

  return (
    <PlaceholderContext.Provider
      value={{
        displayedTemplate: interpolatedTemplate || originalTemplate,
        templateCanBeSend,
        errorLoadingTemplate,
        flattenPlaceholerObject,
        handleInterpolation,
        hasComplexContent,
        isDeepObject,
        initFieldsInArray,
        sortFlatArray,
        resetInterpolation,
        sendMailViaBackend,
        displayedPlaceholderObject,
        originalPlaceholderObject,
      }}
    >
      {children}
    </PlaceholderContext.Provider>
  );
};

PlaceholderProvider.propTypes = {
  children: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    selectedTemplate: state.templates?.selectedTemplate,
  };
};

export default connect(mapStateToProps, null)(PlaceholderProvider);
