import React, { useState } from 'react';
import { DialogFormFrame } from 'components/Base';
import { IconButton, Tooltip } from '@material-ui/core';
import { InfoOutlined } from 'assets/icons';
import { useLazyLoading } from 'state/hooks';
import { getAwsAccountStatus } from 'state/actions';
import AwsAccountStatus from './AwsAccountStatus';

const AwsAccountStatusIcon = ({ awsAccountId }) => {
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [accountStatus, setAccountStatus] = useState(null);

  const [loadAccountStatus] = useLazyLoading({
    action: getAwsAccountStatus,
    onSuccess: (status) => setAccountStatus(status),
    onError: () => setAccountStatus(null),
  });

  const handleInfoClick = async () => {
    setInfoDialogOpen(true);
    await loadAccountStatus(awsAccountId);
  };

  return (
    <>
      <Tooltip title={'Account Status Info'}>
        <IconButton
          aria-label="open additional actions"
          onClick={handleInfoClick}
        >
          <InfoOutlined fontSize="medium" color="primary" />
        </IconButton>
      </Tooltip>
      <DialogFormFrame
        title="Account Status Info"
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
        closeOnBackdropClick={true}
        maxWidth={'sm'}
        formComponent={<AwsAccountStatus statusSummary={accountStatus} />}
      />
    </>
  );
};

export default AwsAccountStatusIcon;
