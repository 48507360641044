import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'consts/defaults';
import registerIllustration from 'assets/img/auth/register.png';
import loginIllustration from 'assets/img/auth/login.png';
import { Hidden } from '@material-ui/core';

const Layout = styled('div')({
  display: 'flex',
  minHeight: '100vh',
});

const LoginIllustration = styled('img')(({ theme }) => ({
  width: '100%',
  maxHeight: '100%',
}));

const RegisterIllustration = styled('img')(({ theme }) => ({
  width: '80%',
  maxHeight: '100%',
}));

const ImageContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50%',
  height: '100vh',
});

const FormContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexGrow: '1',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: theme.spacing(7, 10),
  background: theme.palette.primary.main,
  width: '50%',

  '& a': {
    color: theme.palette.secondary.main,
  },

  '@media (max-width: 960px )': {
    width: '100%',
  },
}));

const Form = styled('div')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: '550px',
  width: '100%',
}));

const AuthLayout = ({ title, children }) => {
  function renderIllustration(title) {
    switch (title) {
      case 'Register':
      case 'Forgot':
        return (
          <RegisterIllustration
            sizes="100vw"
            src={registerIllustration}
            alt="semplates product illustration"
          />
        );

      default:
      case 'Login':
        return (
          <LoginIllustration
            sizes="80vw"
            src={loginIllustration}
            alt="semplates product illustration"
          />
        );
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>Welcome to {APP_NAME}</title>
      </Helmet>
      <FormContainer>
        <Form>{children}</Form>
      </FormContainer>
      <Hidden smDown>
        <ImageContainer>{renderIllustration(title)}</ImageContainer>
      </Hidden>
    </Layout>
  );
};

AuthLayout.propTypes = {
  title: PropTypes.string.isRequired,
};

export default AuthLayout;
