import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { InfoOutlined } from 'assets/icons';
import { Tooltip, Typography } from '@material-ui/core';
import { styled, withStyles } from '@material-ui/core/styles';

const Icon = styled('div')(({ theme }) => ({
  color: theme.palette.grey[500],
  margin: theme.spacing(0, 1),
  cursor: 'pointer',
}));

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    background: theme.palette.common.white,
    borderRadius: 4,
    padding: theme.spacing(2),
    fontWeight: 500,
    boxShadow: '0 0 8px 4px rgba(0, 0, 0, 0.1)',
  },
}))(Tooltip);

const TooltipText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.reverted,
  fontWeight: 500,
}));

const InfoTooltip = ({ className, text, headline, size }) => {
  return (
    <StyledTooltip
      title={
        <Fragment>
          {headline && <Typography variant="h6">{headline}</Typography>}
          <TooltipText variant="body2">{text}</TooltipText>
        </Fragment>
      }
      placement="right"
    >
      <Icon className={className}>
        <InfoOutlined size={size === 'small' ? 12 : 16} />
      </Icon>
    </StyledTooltip>
  );
};

InfoTooltip.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string.isRequired,
  headline: PropTypes.string,
};

export default InfoTooltip;
