import { getCollapseContentRenderer } from 'components/Menu/CompanySettings/Aws/Permissions/AwsPermissionsUtils';

const awsPermissionsColumns = [
  {
    id: 'featureName',
    subId: 'featureDescription',
    label: 'Semplates Feature',
    type: 'subtitled',
    sortable: false,
    align: 'left',
    width: '50%',
  },
  {
    id: 'featureStatus',
    label: 'Feature Status',
    type: 'text',
    sortable: false,
    align: 'right',
    width: '30%',
  },
  {
    id: 'permissionsStatus',
    label: 'Relevant Permissions',
    type: 'collapsible',
    sortable: false,
    align: 'right',
    width: '20%',
    getCollapseContent: getCollapseContentRenderer,
  },
];

export default awsPermissionsColumns;
