import React, { useEffect, useState } from 'react';
import {
  AsyncDialogActions,
  CheckboxField,
  FieldWrapper,
  LogoLoader,
} from 'components/Base';
import { setImportTemplatesDialogOpen } from 'state/actions';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import { FastField as Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { styled } from '@material-ui/core/styles';
import Moment from 'react-moment';

const TemplateText = styled('span')({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
});

const CheckboxComponent = ({
  name,
  value,
  handleCheckboxChange,
  childrenComponent,
}) => {
  useEffect(() => {
    handleCheckboxChange(name, value);
  }, [value, handleCheckboxChange, name]);

  return (
    <Field
      component={CheckboxField}
      name={name}
      className="form-control rounded-0"
      childrenComponent={childrenComponent}
    />
  );
};

const ImportTemplatesForm = ({
  loading,
  templates,
  templateLimit,
  templateCount,
  onSubmit,
}) => {
  const formState = {};
  templates.map((template) =>
    Object.assign(formState, { [template.name]: Yup.bool() })
  );
  const ImportSchema = Yup.object().shape(formState);
  const [showSelectAll, setShowSelectAll] = useState(true);

  const initialValues = templates.reduce((acc, template) => {
    acc[template.name] = false;
    return acc;
  }, {});

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={ImportSchema}
      onSubmit={(values) =>
        onSubmit(Object.keys(values).filter((key) => values[key]))
      }
    >
      {({ isSubmitting, values, setValues }) => {
        const formError =
          templates.filter((v) => values[v]).length >=
          templateLimit - templateCount;

        const handleCheckboxChange = (name, value) => {
          const allSelected = templates.every((template) =>
            template.name === name ? value : values[template.name]
          );
          setShowSelectAll(allSelected);
        };

        const handleSelectAll = () => {
          const newValues = {};
          templates.map((template) =>
            Object.assign(newValues, { [template.name]: true })
          );
          setValues(newValues);
          setShowSelectAll(true);
        };

        const handleDeselectAll = () => {
          const newValues = {};
          templates.map((template) =>
            Object.assign(newValues, { [template.name]: false })
          );
          setValues(newValues);
          setShowSelectAll(false);
        };

        if (isSubmitting || loading) return <LogoLoader />;

        return (
          <Form>
            <Grid container spacing={0}>
              {templates.map(({ name, createdTimestamp }) => (
                <Grid item xs={12} key={name}>
                  <FieldWrapper>
                    <CheckboxComponent
                      name={name}
                      value={values[name]}
                      handleCheckboxChange={handleCheckboxChange}
                      childrenComponent={
                        <TemplateText>
                          &nbsp; <Typography variant="h5">{name}</Typography>{' '}
                          &nbsp; (created: &nbsp;{' '}
                          <Moment format="MMMM D, YYYY" utc local>
                            {createdTimestamp}
                          </Moment>
                          )
                        </TemplateText>
                      }
                    />
                  </FieldWrapper>
                </Grid>
              ))}
            </Grid>
            {formError && (
              <FormHelperText>
                You chose more templates than your plan allows
              </FormHelperText>
            )}
            <Box
              style={{ display: 'flex', justifyContent: 'space-between' }}
              mt={2}
            >
              <Button
                style={{ width: '150px' }}
                size="small"
                color="secondary"
                variant={'contained'}
                onClick={() =>
                  showSelectAll ? handleDeselectAll() : handleSelectAll()
                }
              >
                {showSelectAll ? 'Deselect all' : 'Select all'}
              </Button>
              <AsyncDialogActions
                loading={isSubmitting || loading}
                submitText="Import"
              />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    templates: state.templates.importableTemplates,
    templateLimit: state.company.subscription.templateLimit,
    templateCount: state.company.subscription.templateCount,
  };
};

export const mapDispatchToProps = {
  setImportTemplatesDialogOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportTemplatesForm);
