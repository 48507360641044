import React from 'react';
import { LogoLoader } from 'components/Base';
import { Box, Card, CardContent } from '@material-ui/core';
import Chart from 'react-apexcharts';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

const CardWrapper = ({ children }) => {
  return (
    <Card style={{ marginTop: '16px', position: 'relative' }}>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

const AwsAccountSendingStatisticsCard = ({ loading, sendingStatistics }) => {
  if (loading || !sendingStatistics) {
    return (
      <CardWrapper>
        <Box pt={5} pb={5}>
          <LogoLoader />
        </Box>
      </CardWrapper>
    );
  }

  if (sendingStatistics?.sendDataPoints?.length === 0) {
    return (
      <CardWrapper>
        <Alert severity="info">
          <AlertTitle>No sending statistics available</AlertTitle>
          For this account and region, there are no sending statistics
          available. The account might be in sandbox mode or not enough emails
          were send via this account in the last 14 days.
        </Alert>
      </CardWrapper>
    );
  }

  return (
    <CardWrapper>
      <Chart
        options={sendingStatistics.chartOptions.options}
        series={sendingStatistics.chartOptions.series}
        type="line"
        height={350}
      />
    </CardWrapper>
  );
};

export default AwsAccountSendingStatisticsCard;
