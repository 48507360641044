import React from 'react';
import { Box, Checkbox, MenuItem, MenuList } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useTemplates } from 'components/Contexts';
import { StatusChip } from './';

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  marginRight: 4,
  color: theme.palette.primary.main,
}));

const StatusFilter = () => {
  const statuses = ['draft', 'published', 'unpublished', 'archived'];
  const { statusFilterState, updateQueryParams } = useTemplates();

  const handleStatusFilterChange = (status) => {
    let updatedFilter = statusFilterState;
    if (updatedFilter.includes(status)) {
      updatedFilter = updatedFilter.filter((item) => item !== status);
    } else {
      updatedFilter = [...updatedFilter, status];
    }
    updateQueryParams({ statusFilter: updatedFilter });
  };

  return (
    <MenuList disablePadding>
      {statuses.map((status) => (
        <MenuItem
          style={{ padding: 0 }}
          key={status}
          value={status}
          onClick={() => handleStatusFilterChange(status)}
        >
          <StyledCheckbox checked={statusFilterState?.includes(status)} />
          <Box pr={1}>
            <StatusChip status={status} />
          </Box>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default StatusFilter;
