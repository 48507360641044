import React from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  styled,
} from '@material-ui/core';
import { StyledIconButton, StyledButton } from 'components/Base';
import { Refresh } from 'assets/icons';

export const Header = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const TitleContainer = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  position: 'relative',
}));

const ActionItemsContainer = styled('div')({
  position: 'absolute',
  right: 0,
});

const PaddedFlexBox = styled(Box)(({ theme }) => ({
  '& > * + *': {
    marginLeft: theme.spacing(4),
  },
}));

export const TitleActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.reverted,
  height: '35px',
}));

const SettingsHeader = ({
  children,
  additionalActionItem,
  buttonText,
  title,
  onClick,
  onRefresh,
  refreshing,
}) => {
  return (
    <Header>
      <TitleContainer item container direction={'row'} alignItems={'flex-end'}>
        <Typography variant="h5">{title}</Typography>
        <ActionItemsContainer>
          <PaddedFlexBox
            display={'flex'}
            alignItems={'flex-end'}
            style={{ flexDirection: 'row' }}
          >
            <Box>{additionalActionItem && additionalActionItem}</Box>
            {buttonText && onClick && (
              <Box>
                <StyledButton
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={onClick}
                >
                  {buttonText}
                </StyledButton>
              </Box>
            )}
            {children && children}

            {onRefresh && (
              <Box>
                <StyledIconButton onClick={onRefresh} disabled={refreshing}>
                  <Refresh size={30} />
                </StyledIconButton>
              </Box>
            )}
          </PaddedFlexBox>
        </ActionItemsContainer>
      </TitleContainer>
      <Divider />
    </Header>
  );
};

export default SettingsHeader;
