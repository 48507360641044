const verifiedMailColumns = [
  {
    id: 'identity',
    label: 'E-Mail',
    type: 'text',
    sortable: true,
  },
  {
    id: 'senderName',
    label: 'Sender Name',
    type: 'editableColumn',
    sortable: true,
  },
  {
    align: 'center',
    id: 'isDefault',
    label: 'Role',
    type: 'setDefault',
    sortable: false,
  },
  {
    align: 'center',
    id: 'isTestSender',
    label: 'Use for Testing',
    type: 'checkbox',
    sortable: false,
  },
  {
    align: 'center',
    id: 'verified',
    label: 'Actions',
    type: 'actions',
    sortable: false,
  },
];

export default verifiedMailColumns;
