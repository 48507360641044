import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Searchbar } from 'components/Menu/Templates';
import CreateTemplateDropdown from '../Overview/CreateTemplateDropdown';
import { useTemplates } from 'components/Contexts';

const StyledBox = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  justifyContent: 'space-around',
  display: 'flex',
}));

const TemplateFilterBar = () => {
  const { updateQueryParams } = useTemplates();

  const handleSearchValueChange = (value) => {
    const newFilter = value?.length ? value : '';
    updateQueryParams({ filter: newFilter });
  };

  return (
    <StyledBox>
      <Searchbar
        onCancelSearch={() => updateQueryParams({ filter: '' })}
        submitAfterType={true}
        onSubmit={handleSearchValueChange}
        customStyles={{ height: '40px', width: `calc(100% - 284px)` }}
      />
      <CreateTemplateDropdown style={{ width: '270px' }} />
    </StyledBox>
  );
};

export default TemplateFilterBar;
