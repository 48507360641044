import React from 'react';
import { AppBar as MuiAppBar, Button, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { UserMenu } from '.';

export const StyledAppBar = styled(({ children, ...props }) => (
  <MuiAppBar {...props}>{children}</MuiAppBar>
))(({ theme }) => ({
  width: `calc(100% - ${theme.custom.drawerWidth}px)`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  height: theme.custom.appBarHeight,
  background: theme.palette.background.main,
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 7px 5px -8px',
  alignItems: 'end',
  flexDirection: 'row',
  zIndex: 3,
}));

export const StyledEnd = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  float: 'right',
  position: 'absolute',
  right: 0,
  backgroundColor: theme.palette.background.main,
}));

export const TitleContainer = styled('div')(({ theme, withcontent }) => ({
  position: 'relative',
  width: withcontent && '25%',
  minWidth: withcontent && '25%',
  maxWidth: withcontent && '25%',
  paddingLeft: theme.spacing(3),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'flex',
}));

export const AppBarButtonPrimary = styled(Button)(({ theme }) => ({
  height: '100%',
  borderRadius: 0,
  padding: theme.spacing(1.5, 3),
  color: theme.palette.text.main,
  overflow: 'hidden',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const AppBarButtonSecondary = styled(Button)(({ theme }) => ({
  height: '100%',
  borderRadius: 0,
  padding: theme.spacing(1.5, 3),
  color: theme.palette.text.main,
  overflow: 'hidden',

  '&:after': {
    content: '""',
    position: 'absolute',
    height: '3px',
    width: '100%',
    bottom: 0,
    transform: 'translate(100%)',
    transition: 'transform 0.125s ease',
    background: theme.palette.primary.main,
  },

  '&:hover:after': {
    transform: 'translate(0)',
  },
}));

const StyledAppTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.main,
  fontFamily: theme.typography.fontFamilyTitle,
  fontWeight: 600,
  paddingBottom: theme.spacing(1),
}));

const AppBar = ({ title, customWidth }) => {
  return (
    <StyledAppBar position="fixed" customwidth={customWidth}>
      <TitleContainer>
        <StyledAppTitle variant="h1">{title}</StyledAppTitle>
      </TitleContainer>
      <StyledEnd>
        <UserMenu />
      </StyledEnd>
    </StyledAppBar>
  );
};

export default AppBar;
