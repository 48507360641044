import React from 'react';
import { Drawer as MuiDrawer, Typography } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import DrawerMenu from 'components/Structure/DrawerMenu';
import semplatesLogo from 'assets/svgs/semplates_logo.svg';
import { APP_NAME } from 'consts/defaults';

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.background.paper
        : theme.palette.primary.main,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    display: 'flex',
    flexFlow: 'column',
    width: theme.custom.drawerWidth,
    overflowX: 'hidden',
    border: 'none',
  },
  appName: {
    fontSize: '22px',
    fontWeight: 700,
    letterSpacing: 1.6,
    color: theme.palette.text.defaultReverted,
  },
}));

const DrawerHead = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(3.3, 1, 1, 1),
  alignItems: 'center',
  color: theme.palette.text.main,
  maxHeight: theme.custom.appBarHeight,
}));

const Image = styled('img')(({ theme }) => ({
  padding: theme.spacing(1, 1, 1.7, 0.5),
  maxHeight: 55,
}));

const Drawer = () => {
  const classes = useStyles();
  const drawerClass = classes.drawer;

  return (
    <MuiDrawer
      variant="permanent"
      className={drawerClass}
      classes={{ paper: drawerClass }}
    >
      <DrawerHead>
        <Image src={semplatesLogo} />
        <Typography variant="h5" className={classes.appName}>
          {APP_NAME}
        </Typography>
      </DrawerHead>
      <DrawerMenu />
    </MuiDrawer>
  );
};

export default Drawer;
