import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { AsyncDialogActions } from 'components/Base';
import awsCredentialsSchema from 'consts/schemas/awsCredentialsSchema';
import AwsCredentialsFields from 'components/WelcomeSlides/Forms/AwsCredentialsFields';

const AwsCredentialsForm = ({
  confirmAdditionalCosts,
  loading,
  onClose,
  onSubmit,
  credentials,
}) => {
  return (
    <Formik
      initialValues={{
        alias: credentials?.alias || '',
        awsRegion: credentials?.awsRegion || '',
        roleArn: credentials?.roleArn || '',
        confirmAdditionalCosts: credentials ? true : confirmAdditionalCosts,
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={awsCredentialsSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <AwsCredentialsFields alias={credentials?.alias}>
          <AsyncDialogActions
            onClose={onClose}
            loading={isSubmitting || loading}
            submitText={
              credentials?.alias ? 'Update Credentials' : 'Submit Credentials'
            }
          />
        </AwsCredentialsFields>
      )}
    </Formik>
  );
};

AwsCredentialsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AwsCredentialsForm;
