import React from 'react';
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { PlusCircle } from 'assets/icons';
import { StyledListItem } from './styles';

const AddIdentityListItem = ({ onClick }) => {
  return (
    <ListItem key="newMail" style={{ paddingTop: '1.5rem' }}>
      <StyledListItem width={35}> Add a new one</StyledListItem>
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={onClick} aria-label="delete">
          <PlusCircle size={22} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

AddIdentityListItem.propTypes = {};

export default AddIdentityListItem;
