import React, { useState } from 'react';
import { AsyncDialogActions } from 'components/Base';
import { useLazyLoading } from 'state/hooks';
import { acceptUpdatedTerms, signOut } from 'state/actions';
import { Box, Grid, Modal, Typography } from '@material-ui/core';
import { StyledDialogTitle } from '../Base/DialogFormFrame';
import { CenteredDialog } from './styles';
import { terms } from '../Legal';
import { styled } from '@material-ui/core/styles';

const Highlight = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: '600',
  fontSize: theme.spacing(2),
  textDecoration: 'none',
  cursor: 'pointer',
  margin: theme.spacing(0, 1),
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const TermsWindow = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  maxHeight: '40vH',
  overflow: 'scroll',
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(2),
}));

const TermsUpdatedDialog = () => {
  const [termsOpen, setTermsOpen] = useState(false);

  const [handleSubmit, { loading }] = useLazyLoading({
    action: acceptUpdatedTerms,
  });

  const [handleLogout] = useLazyLoading({
    action: signOut,
  });

  return (
    <Modal open disableBackdropClick={true}>
      <CenteredDialog>
        <StyledDialogTitle>
          We updated our Terms and Conditions
        </StyledDialogTitle>
        <Box p={3}>
          <Typography variant="subtitle1">
            Please accept the most recent version of our
            <Highlight onClick={() => setTermsOpen(!termsOpen)}>
              terms and conditions
            </Highlight>
            to further use our services.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}></Grid>
            {termsOpen && (
              <Grid item>
                <TermsWindow
                  dangerouslySetInnerHTML={{ __html: terms }}
                ></TermsWindow>
              </Grid>
            )}
            <Grid item xs={12}>
              <AsyncDialogActions
                onClose={handleLogout}
                onSubmit={handleSubmit}
                loading={loading}
                submitText="Accept"
                secondaryText="Logout"
              />
            </Grid>
          </Grid>
        </Box>
      </CenteredDialog>
    </Modal>
  );
};

export default TermsUpdatedDialog;
