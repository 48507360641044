import React, { Fragment } from 'react';
import { loadTemplateStarters } from 'state/actions';
import { connect } from 'react-redux';
import { Box, Button, Card, CardContent } from '@material-ui/core';
import { LogoLoader } from 'components/Base';
import { styled } from '@material-ui/core/styles';
import { useAsyncAction } from 'state';
import AppBar from 'components/Structure/AppBar';
import { useSubscription } from '../../../Contexts';
import useWindowSize from 'components/hooks/useWindowSize';

const IFrame = styled('iframe')(({ theme }) => ({
  border: 'none',
  overflow: 'hidden',
  width: '100% !important',
  borderradius: '5px',
  boxShadow: theme.shadows[1],
  borderRadius: '5px',
}));

const Overlay = styled(Card)(({ theme }) => ({
  background: theme.palette.primary.light,
  height: '100%',
  width: '100%',
  opacity: 0,
  position: 'absolute',
  zIndex: 3,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1),
  '&:hover': {
    opacity: 0.97,
  },
}));

const TemplateTitle = styled('h3')(({ theme }) => ({
  color: theme.palette.text.reverted,
  margin: '0px',
}));

const TemplateSubtitle = styled('p')(({ theme }) => ({
  color: theme.palette.text.reverted,
}));

const TemplateOpenButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.reverted,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '40px',
  },
  [theme.breakpoints.up('xl')]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridGap: '40px',
  },
}));

const Column = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
}));

const FrameContainer = styled('div')(() => ({
  position: 'relative',
}));

const adjustIframeHeight = (iframe) => {
  try {
    if (iframe && iframe.contentWindow.document.body) {
      const iframeContentHeight =
        iframe.contentWindow.document.body.scrollHeight;
      // eslint-disable-next-line no-param-reassign
      iframe.style.height = `${iframeContentHeight}px`;
    }
  } catch (error) {
    throw error;
  }
};

const TemplateStarters = ({ samples }) => {
  const { openTemplateDialog } = useSubscription();

  const { loading } = useAsyncAction({ action: loadTemplateStarters });

  const { width } = useWindowSize();

  const onCreate = (subject, name) => {
    openTemplateDialog({ subject, name });
  };

  // Function to create iframes for each template
  const createIframes = (templateSubset, columnIndex) =>
    templateSubset.map((sample, index) => (
      <FrameContainer key={`${columnIndex}-${index}`}>
        <Overlay className="overlay">
          <CardContent>
            <TemplateTitle>{sample.title} </TemplateTitle>
            <TemplateSubtitle>{sample.subtitle}</TemplateSubtitle>
            <Box m={2} mt={4}>
              <TemplateOpenButton
                onClick={() => onCreate(sample.subject, sample.default_name)}
                variant="contained"
              >
                Use this template
              </TemplateOpenButton>
            </Box>
          </CardContent>
        </Overlay>
        <IFrame
          srcDoc={sample.html_code}
          onLoad={(e) => adjustIframeHeight(e.target)}
          scrolling="no"
        />
      </FrameContainer>
    ));

  const renderTemplateGrid = (templates) => {
    // Create columns as arrays
    const columns = width < 1920 ? [[], [], []] : [[], [], [], [], []];

    // Distribute templates across columns
    templates.forEach((template, index) => {
      // Calculate the column index
      const columnNumber = width < 1920 ? 3 : 5;
      const columnIndex = index % columnNumber;
      columns[columnIndex].push(template);
    });

    // Create column elements
    return (
      <Grid>
        {columns.map((column, columnIndex) => (
          <Column key={columnIndex}>
            {createIframes(column, columnIndex)}
          </Column>
        ))}
      </Grid>
    );
  };

  if (loading) return <LogoLoader />;

  return (
    <Fragment>
      <AppBar title={'Template Starters'} />
      {renderTemplateGrid(samples)}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    samples: state.templates.samples,
  };
};

export default connect(mapStateToProps)(TemplateStarters);
