export const setDrawerSubNavOpen = (open) => ({
  type: 'DRAWER_SUBNAV_OPEN_SET',
  payload: open,
});

export const setSendTemplateOptions = (sendTemplateOptions) => ({
  type: 'SEND_TEMPLATE_OPTIONS_SET',
  payload: sendTemplateOptions,
});

export const setImportTemplatesDialogOpen = (state) => ({
  type: 'IMPORT_TEMPLATES_DIALOG_OPEN',
  payload: state,
});

export const setThemeMode = (mode) => ({
  type: 'SET_THEME_MODE',
  payload: mode,
});

export const showSuccessSnackbar = (message) => ({
  type: 'SNACKBAR_SUCCESS',
  payload: message,
});

export const showErrorSnackbar = (message) => ({
  type: 'SNACKBAR_ERROR',
  payload: message,
});

export const showInfoSnackbar = (message) => ({
  type: 'SNACKBAR_INFO',
  payload: message,
});

export const clearSnackbar = () => ({
  type: 'SNACKBAR_CLEAR',
});

export const showUpgradeDialog = (errorCode) => ({
  type: 'UPGRADE_DIALOG_OPEN',
  payload: errorCode,
});

export const setShimmerOverlay = (value) => ({
  type: 'SET_SHIMMER_OVERLAY',
  payload: value,
});

export const clearUpgradeDialog = () => ({
  type: 'UPGRADE_DIALOG_CLOSE',
});
