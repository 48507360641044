import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import DialogFormFrame from './DialogFormFrame';
import { LoadingButton, StyledInvertedButton } from './';
import Alert from '@material-ui/lab/Alert';

const AlertDialog = ({
  loading,
  onClose,
  onSubmit,
  open,
  submitText,
  text,
  title,
}) => {
  return (
    <DialogFormFrame
      onClose={onClose}
      title={title}
      open={open}
      formComponent={
        <Alert severity="warning">
          <Typography>{text}</Typography>
        </Alert>
      }
      actions={
        <Fragment>
          <StyledInvertedButton onClick={onClose}>Cancel</StyledInvertedButton>
          <LoadingButton
            onClick={onSubmit}
            color="primary"
            autoFocus
            variant={'contained'}
            loading={loading}
          >
            {submitText}
          </LoadingButton>
        </Fragment>
      }
    />
  );
};

AlertDialog.propTypes = {
  formComponent: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default AlertDialog;
