import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const Row = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
}));

export const SubtitleTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0.5, 0),
  fontSize: '14px',
}));

const Metadata = ({ template }) => {
  let templateDesign = JSON.parse(template.design);
  let preheaderText = templateDesign?.body?.values?.preheaderText || '';
  return (
    <Box pb={2}>
      <Row>
        <SubtitleTypography variant="h6">
          <Box fontWeight={600} mr={1} component="span">
            Subject:
          </Box>
          {template.subject}
        </SubtitleTypography>
      </Row>
      <Row>
        <SubtitleTypography variant="h6">
          <Box fontWeight={600} component="span">
            Mail From:
          </Box>{' '}
          {preheaderText}
        </SubtitleTypography>
      </Row>
    </Box>
  );
};
export default Metadata;
