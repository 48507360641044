import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from 'state/reducers';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'user'],
};

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const configureStore = ({ firebaseInstance, axiosInstance }) => {
  const middlewares = [
    thunk.withExtraArgument({
      getFirebase: firebaseInstance.getFirebase,
      axios: axiosInstance,
    }),
  ];

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, enhancer);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers/rootReducer', () => {
      const nextRootReducer = require('./reducers/rootReducer').default;
      store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
    });
  }

  return store;
};

export default configureStore;
