import React from 'react';
import { FastField as Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FieldWrapper } from 'components/Base';
import { TextField } from 'formik-material-ui';
import { Box } from '@material-ui/core';

const CreateSchema = Yup.object().shape({
  templateName: Yup.string().matches(
    '^[a-zA-Z0-9_-]*$',
    'Only alphanumeric letters, minuses or underscores are allowed.'
  ),
  subject: Yup.string(),
});

const MetadataForm = ({ template, onSubmit, formikRef }) => {
  const templateDesign = JSON.parse(template.design);

  const handleSubmit = (formValues) => {
    if (formValues.preheaderText !== templateDesign.body.values.preheaderText) {
      formValues.design = templateDesign;
      formValues.design.body.values.preheaderText = formValues.preheaderText;
    }
    delete formValues.preheaderText;
    return onSubmit({
      uid: template.uid,
      ...formValues,
    });
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        templateName: template.templateName,
        subject: template.subject || '',
        preheaderText: templateDesign.body?.values?.preheaderText || '',
      }}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={CreateSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box mb={2}>
            <FieldWrapper
              label="Template Name"
              subtitle="The name of the template that will be shown in the list overview and AWS console."
            >
              <Field
                component={TextField}
                fullWidth
                name="templateName"
                variant="outlined"
                size="small"
              />
            </FieldWrapper>
          </Box>
          <Box mb={2}>
            <FieldWrapper
              label="Subject Line"
              subtitle="The subject line is the text line that is displayed first when viewing an email from the inbox."
            >
              <Field
                component={TextField}
                fullWidth
                name="subject"
                variant="outlined"
                size="small"
              />
            </FieldWrapper>
          </Box>
          <Box mb={2}>
            <FieldWrapper
              label="Preheader Text"
              subtitle="A preheader is the short summary text that follows the subject line when viewing an email from the inbox."
            >
              <Field
                component={TextField}
                fullWidth
                name="preheaderText"
                variant="outlined"
                size="small"
              />
            </FieldWrapper>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
export default MetadataForm;
