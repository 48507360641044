import React from 'react';
import { Grid, Link, styled, useTheme } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';

const FooterContainer = styled(({ forceFullWidth, children, ...props }) => (
  <div {...props}>{children}</div>
))(({ theme, forceFullWidth }) => ({
  backgroundColor: theme.palette.secondary.dark,
  width: `calc(100% - ${forceFullWidth ? '100%' : theme.custom.drawerWidth}px)`,
  marginLeft: 'auto',
  boxSizing: 'border-box',
  padding: theme.spacing(1, 3),
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  transition: `width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms`,
}));

const IconItem = styled('a')(({ theme }) => ({
  color: theme.palette.text.main,
  cursor: 'pointer',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.text.main,
  margin: theme.spacing(0, 0.5, 0, 0.5),
  boxShadow: '1px 1px 2px 1px #ccc',
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.main,
  cursor: 'pointer',
  textDecoration: 'none',
  textWrap: 'wrap',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const ICONS_LIST = [];

const Footer = ({ user }) => {
  const theme = useTheme();

  return (
    <FooterContainer forceFullWidth={user === null}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        wrap="wrap"
      >
        <Grid
          item
          xl={12}
          md={3}
          style={{ padding: theme.spacing(1, 0, 1, 0) }}
        >
          <StyledLink
            to="/terms-of-use"
            component={RouterLink}
            target="_blank"
            style={{
              paddingRight: theme.spacing(2),
            }}
          >
            Disclaimer
          </StyledLink>
          <StyledLink
            to="/privacy-statement"
            component={RouterLink}
            target="_blank"
          >
            Privacy statement
          </StyledLink>
        </Grid>
        <Grid
          xl={12}
          md={6}
          item
          style={{ color: theme.palette.text.main, textAlign: 'center' }}
        >
          &copy; Copyright Semplates 2020. All rights reserved
        </Grid>
        <Grid
          item
          xl={12}
          md={3}
          style={{ padding: theme.spacing(1, 0, 1, 0) }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {ICONS_LIST.map(({ Icon, to }, index) => {
              return (
                <Link
                  key={`${to}-${index}`}
                  href={to}
                  component={IconItem}
                  target="_blank"
                >
                  <Icon key={`${index}${to}`} />
                </Link>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </FooterContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.authUser,
  };
};

export default connect(mapStateToProps)(Footer);
