import React from 'react';
import { NoData } from 'components/Base';
import noCredentials from 'assets/svgs/online_protection.svg';
import { useSubscription } from '../../../Contexts';

const AwsNotConnected = () => {
  const { openAwsDialog } = useSubscription();
  return (
    <div style={{ height: '80vh' }}>
      <NoData
        style={{ height: '100%' }}
        description={'You have not connected AWS SES yet.'}
        image={noCredentials}
        onClick={openAwsDialog}
        buttonText={'Connect now'}
      />
    </div>
  );
};

export default AwsNotConnected;
