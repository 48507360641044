import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { FastField as Field, Form, Formik } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  CheckboxField,
  errors,
  FieldWrapper,
  LoadingButton,
} from 'components/Base';
import { Actions, BoldText, Text } from './styles';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { styled } from '@material-ui/core/styles';
import { ForumOutlined, InfoOutlined } from 'assets/icons';

const plans = [
  {
    value: 'starter',
    title: 'Starter',
    subtitle: 'Get started and pay as you go',
    monthlyPrice: 39,
    annualPrice: 399,
  },
  {
    value: 'business',
    title: 'Business',
    subtitle: 'Collaborate with your team and flexibly adjust your plan ',
    monthlyPrice: 89,
    annualPrice: 899,
  },
  {
    value: 'enterprise',
    title: 'Enterprise',
    subtitle: 'Custom pricing for your company',
  },
];

const BillingPeriodInfo = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  fontWeight: 600,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

const PlanCard = styled('div')(({ theme, selectedplan, currentplan }) => ({
  minHeight: '240px',
  borderRadius: '8px',
  height: '100px',
  border:
    selectedplan === currentplan
      ? `2px solid ${theme.palette.secondary.light}`
      : `2px solid ${theme.palette.grey.main}`,
  cursor: 'pointer',
  transition: 'all 0.125s ease-in',
  color:
    selectedplan !== currentplan &&
    selectedplan !== null &&
    theme.palette.grey[600],
  opacity: selectedplan !== currentplan && selectedplan !== null ? 0.5 : 1,
  fontFamily: theme.typography.fontFamilyTitle,
  '&:hover': {
    backgroundColor:
      selectedplan === currentplan ? 'none' : theme.palette.grey[100],
  },
}));

const StyledInfo = styled(InfoOutlined)(({ theme }) => ({
  display: 'block',
  float: 'right',
  top: theme.spacing(1.5),
  right: theme.spacing(1.5),
  color: theme.palette.secondary.main,
}));

const StyledForum = styled(ForumOutlined)({
  fontSize: '60px',
});

const PlanTitle = styled(BoldText)(({ theme, selectedplan, currentplan }) => ({
  fontSize: '24px',
  marginBottom: theme.spacing(0.5),
  color:
    selectedplan !== currentplan && selectedplan !== null
      ? theme.palette.grey[600]
      : theme.palette.text.main,
}));

const PlanText = styled(PlanTitle)(({ theme }) => ({
  padding: theme.spacing(0),
  paddingRight: theme.spacing(2),
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: 1.4,
}));

const PlanPrice = styled(PlanTitle)(
  ({ theme, selectedplan, currentplan, coloroverwrite }) => ({
    color:
      coloroverwrite === 'true'
        ? theme.palette.error.main
        : selectedplan !== currentplan && selectedplan !== null
        ? theme.palette.grey[600]
        : theme.palette.text.main,
    fontSize: '36px',
    fontWeight: 700,
    marginBottom: theme.spacing(0.25),
  })
);

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-multiline': {
    padding: theme.spacing(2),
  },

  '& .MuiOutlinedInput-root': {
    background: theme.palette.background.main,
    border: `2px solid ${theme.palette.grey.main}`,
  },

  '& .MuiOutlinedInput-input': {
    background: theme.palette.background.main,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0px !important',
  },
}));

const CreateSchema = Yup.object().shape({
  annualBilling: Yup.bool()
    .required()
    .default(false),
  demoRequested: Yup.bool()
    .required()
    .default(false),
  message: Yup.string().default(''),
});

const Card = ({ onClick, selectedplan, option, values }) => {
  return (
    <PlanCard
      onClick={onClick}
      selectedplan={selectedplan}
      currentplan={option.value}
    >
      <Box m={2}>
        <a href="https://semplates.io/pricing" target="_blank" rel="noreferrer">
          <StyledInfo />
        </a>
        <PlanTitle selectedplan={selectedplan} currentplan={option.value}>
          {option.title}
        </PlanTitle>
        <Box height={60}>
          <PlanText>{option.subtitle}</PlanText>
        </Box>

        <Fragment>
          <Box display="flex" alignItems="end" mt={3}>
            {option.annualPrice && (
              <PlanPrice
                selectedplan={selectedplan}
                currentplan={option.value}
                coloroverwrite={values.annualBilling.toString()}
              >
                $
                {values.annualBilling
                  ? option.annualPrice
                  : option.monthlyPrice}
              </PlanPrice>
            )}
            {values.annualBilling && option.annualPrice && (
              <Box ml={1} mb={0.5}>
                <Typography
                  style={{
                    textDecoration: 'line-through',
                    fontSize: '20px',
                  }}
                >
                  ${12 * option.monthlyPrice}
                </Typography>
              </Box>
            )}
          </Box>
          {option.annualPrice && (
            <Fragment>
              <PlanText>
                {values.annualBilling ? 'per year' : 'per month'}
              </PlanText>
            </Fragment>
          )}
        </Fragment>
        {!option.annualPrice && <StyledForum />}
      </Box>
    </PlanCard>
  );
};

const UpgradePlanForm = ({ onSubmit, loading }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  return (
    <Formik
      initialValues={CreateSchema.default()}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={CreateSchema}
      onSubmit={(values) => onSubmit({ ...values, selectedPlan })}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Box mx={6} mt={2}>
            <BillingPeriodInfo>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <BoldText>Get two month off with annual billing</BoldText>
                <Box mr={1}>
                  <BoldText>Monthly billing</BoldText>
                  <Field
                    label="annually"
                    name="annualBilling"
                    component={Switch}
                    type="checkbox"
                  />
                  <BoldText>Annual billing</BoldText>
                </Box>
              </Box>
            </BillingPeriodInfo>
            <Box mt={3}>
              <Grid container spacing={3}>
                {plans.map((option) => (
                  <Grid item xs={4} key={option.value}>
                    <Card
                      onClick={() => setSelectedPlan(option.value)}
                      selectedplan={selectedPlan}
                      option={option}
                      values={values}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box fontWeight="bold" mt={4}>
              <BoldText>Send us a message (optional)</BoldText>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <FieldWrapper>
                  <Field
                    component={StyledTextField}
                    multiline={true}
                    minRows={1}
                    fullWidth
                    name="message"
                    variant="outlined"
                  />
                </FieldWrapper>
              </Grid>
              <Grid item>
                <Field
                  component={CheckboxField}
                  name="demoRequested"
                  color="secondary"
                  childrenComponent={
                    <Box m={1}>
                      <Text>You have questions or want a guided tour?</Text>
                      <BoldText>Book a demo</BoldText>
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Actions justifyContent="center">
            <LoadingButton
              loading={isSubmitting || loading}
              type="submit"
              color="secondary"
            >
              Upgrade now
            </LoadingButton>
          </Actions>
        </Form>
      )}
    </Formik>
  );
};

UpgradePlanForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    upgradeTitle:
      errors[state.layout.upgradeDialogErrorCode]?.title || undefined,
    upgradeSubtitle:
      errors[state.layout.upgradeDialogErrorCode]?.description || undefined,
    upgradeRequested: state.company.subscription?.upgradeRequested,
  };
};

export default connect(mapStateToProps)(UpgradePlanForm);
