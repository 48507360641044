import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useAsyncAction } from 'state/hooks';
import { getAwsAccounts } from 'state/actions';
import { LogoLoader } from '../Base';

const AwsAccountsContext = createContext(null);

const AwsAccountsProvider = ({ children }) => {
  const { loading } = useAsyncAction({ action: getAwsAccounts });

  if (loading) return <LogoLoader />;

  return <AwsAccountsContext.Provider>{children}</AwsAccountsContext.Provider>;
};

AwsAccountsContext.propTypes = {
  children: PropTypes.any,
};

export { AwsAccountsProvider, AwsAccountsContext };

export default AwsAccountsContext;
