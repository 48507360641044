import React from 'react';

import {
  Aws,
  ChevronsUp,
  Detail,
  Domain,
  Verified,
  MailOutline,
  MailSend,
  Team,
  User,
  UserPlus,
  World,
} from 'assets/icons';

export const Status = {
  disabled: 'disabled',
  possible: 'possible',
  done: 'done',
};

const iconStyle = { width: '100%', height: '100%' };

export const onboardingData = {
  awsSettings: {
    title: 'Set up your AWS SES Account',
    sizing: { lg: 6, md: 6, xs: 12 },
    items: {
      accountConnection: {
        sizing: { xs: 12 },
        title: 'AWS account connection',
        subtitle: 'Upload your credentials to connect SES',
        icon: <Aws size={50} style={iconStyle} />,
        id: 'credentials',
        action: 'Add Aws credentials',
      },

      emailVerification: {
        sizing: { md: 6, xs: 12 },
        title: 'Verify your email',
        subtitle: 'to send emails from this address',
        id: 'email',
        icon: <Verified size={50} style={iconStyle} />,
        action: 'Verify',
        disabledText: 'Add Aws credentials first',
      },
      templateCreation: {
        sizing: { md: 6, xs: 12 },
        title: 'Create a template',
        subtitle: 'to send emails using your domain',
        id: 'template',
        icon: <MailOutline size={50} style={iconStyle} />,
        action: 'Create',
        disabledText: 'Add Aws credentials first',
      },
      domainVerification: {
        sizing: { md: 6, xs: 12 },
        title: 'Verify your domain',
        subtitle: 'to pass security filters & spam checks',
        icon: <World size={50} style={iconStyle} />,
        id: 'domain',
        action: 'Verify',
        disabledText: 'Add Aws credentials first',
      },
      sendTemplate: {
        sizing: { md: 6, xs: 12 },
        title: 'Send a template',
        icon: <MailSend size={50} style={iconStyle} />,
        id: 'send',
        action: 'Send',
        disabledText: 'Create template and verify email address ',
      },
    },
  },
  collaboration: {
    sizing: { lg: 3, md: 3, xs: 12 },
    items: {
      collaboration: {
        title: 'Team settings',
        sizing: { xs: 12 },
        icon: <Team size={50} style={iconStyle} />,
        action: 'Check Team Settings',
      },
      yourUser: {
        title: 'Update profile',
        sizing: { xs: 12 },
        action: 'Update Profile',
        icon: <User size={50} style={iconStyle} />,
      },
      inviteMembers: {
        title: 'Invite colleagues',
        sizing: { xs: 12 },
        icon: <UserPlus size={50} style={iconStyle} />,
        action: 'Invite Member',
      },
    },
  },
  companySettings: {
    sizing: { lg: 3, md: 3, xs: 12 },
    items: {
      companySettings: {
        title: 'Company settings',
        subtitle: 'All about your company',
        sizing: { xs: 12 },
        id: 'company',
        icon: <Domain size={50} style={iconStyle} />,
        action: 'Check Company Settings',
      },
      upgradeCompanySettings: {
        title: 'Update details',
        sizing: { xs: 12 },
        id: 'settings',
        icon: <Detail size={50} style={iconStyle} />,
        action: 'Update company',
      },
      upgradePlan: {
        title: 'Upgrade plan',
        sizing: { xs: 12 },
        id: 'plan',
        icon: <ChevronsUp size={50} style={iconStyle} />,
        action: 'Upgrade',
      },
    },
  },
};

let progressTemplate = {
  percentage: 0,
  awsConnected: false,
  companyAddressComplete: false,
  usersExisting: false,
  templatesExisting: false,
  verifiedDomains: false,
  emailIdentitiesExisting: false,
  domainIdentitiesExisting: false,
};

export const getUpdatedStatus = (key, mProgress) => {
  const progress = {
    ...progressTemplate,
    ...mProgress,
  };
  switch (key) {
    case 'accountConnection':
      return progress.awsConnected ? Status.done : Status.possible;
    case 'emailVerification':
      return progress.emailIdentitiesExisting
        ? Status.done
        : progress.awsConnected
        ? Status.possible
        : Status.disabled;
    case 'templateCreation':
      return progress.templatesExisting
        ? Status.done
        : progress.awsConnected
        ? Status.possible
        : Status.disabled;
    case 'domainVerification':
      return progress.domainIdentitiesExisting
        ? Status.done
        : progress.awsConnected
        ? Status.possible
        : Status.disabled;
    case 'sendTemplate':
      return progress.identitiesExisting && progress.templatesExisting
        ? Status.possible
        : Status.disabled;
    case 'collaboration':
      return Status.possible;
    case 'yourUser':
      return Status.possible;
    case 'inviteMembers':
      return progress.usersExisting ? Status.done : Status.possible;
    case 'companySettings':
      return Status.possible;
    case 'upgradeCompanySettings':
      return progress.companyAddressComplete ? Status.done : Status.possible;
    case 'upgradePlan':
      return Status.possible;

    default:
      return Status.possible;
  }
};
