import React, { Fragment } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { PrivateRoute } from 'components/Auth';
import {
  Login,
  OktaInitiatedLogin,
  PasswordForgot,
  PrivacyStatement,
  Register,
  Terms,
  TokenizedLogin,
} from './containers/';
import { ConversionProvider, VersionProvider } from './components/Contexts';
import { MaintenanceBanner } from './components/UserActionRequired';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'consts';
import { QueryParamProvider } from 'use-query-params';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { darkTheme, theme } from 'styles';
import { connect } from 'react-redux';
import { Snackbar } from 'components/Base';
import { privateRoutes } from './consts';
import PrivateRoutes from './PrivateRoutes';

const App = ({ themeMode }) => {
  const selectedTheme = themeMode === 'light' ? theme : darkTheme;

  return (
    <ThemeProvider theme={createTheme(selectedTheme)}>
      <CssBaseline />
      <Fragment>
        <Helmet>
          <title>{APP_NAME}</title>
          <meta name="description" content="send mails easily" />
        </Helmet>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <MaintenanceBanner />
            <ConversionProvider>
              <VersionProvider>
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/okta-login" component={OktaInitiatedLogin} />
                  <Route path="/tokenized-login" component={TokenizedLogin} />
                  <Route path="/password-forgot" component={PasswordForgot} />
                  <Route path="/register" component={Register} />
                  <Route path="/terms-of-use" component={Terms} />
                  <Route
                    path="/privacy-statement"
                    component={PrivacyStatement}
                  />
                  <PrivateRoute exact path={privateRoutes}>
                    <PrivateRoutes privateRoutes={privateRoutes} />
                  </PrivateRoute>
                  <Route path="*" render={() => <Redirect to="/" />} />
                </Switch>
                <Snackbar />
              </VersionProvider>
            </ConversionProvider>
          </QueryParamProvider>
        </Router>
      </Fragment>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.layout.themeMode,
  };
};

export default connect(mapStateToProps)(App);
