export const firebaseConfig = process.env.REACT_APP_FIREBASE_CONFIG
  ? JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)
  : {
      apiKey: 'dummy',
      authDomain: 'dummy',
      projectId: 'semplates-dev',
      storageBucket: 'semplates-dev.appspot.com',
      messagingSenderId: 'dummy',
      appId: 'dummy',
    };
