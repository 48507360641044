import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { ExclamationCircle } from 'assets/icons';
import { LoadingButton } from '.';

const Container = styled(({ center, ...props }) => <div {...props} />)(
  ({ theme, center }) => {
    let styles = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.palette.text.reverted,
      fontWeight: 500,
    };

    if (center) {
      styles = {
        ...styles,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
      };
    }

    return styles;
  }
);

const ErrorState = ({ error, center = true, className, action }) => {
  let displayError = '';

  if (error instanceof Error) displayError = error.message;
  if (typeof error === 'string') displayError = error;
  if (!error)
    displayError = 'Something went wrong fetching your data. Please reload.';

  return (
    <Container center={center} className={className}>
      <Box mb={1}>
        <ExclamationCircle size={24} />
      </Box>
      {displayError}
      {action && (
        <Box mt={2}>
          <LoadingButton
            size="small"
            onClick={action.onClick}
            color="secondary"
            variant="outlined"
          >
            {action.label}
          </LoadingButton>
        </Box>
      )}
    </Container>
  );
};

ErrorState.propTypes = {
  error: PropTypes.any,
  center: PropTypes.bool,
  className: PropTypes.string,
};

export default ErrorState;
