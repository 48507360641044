import React from 'react';
import { TagSelector } from '.';

const TagInputField = ({ template }) => {
  const handleTagChange = (item) => {
    template.tags = item.map(({ id }) => id);
  };

  return (
    <TagSelector defaultValue={template.tags} onChange={handleTagChange} />
  );
};

export default TagInputField;
