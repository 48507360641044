import { styled } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';

export const CenteredDialog = styled(Card)(({ theme }) => ({
  position: 'absolute',
  width: '60vw',
  top: '40%',
  left: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
  outline: 0,
}));
