import React from 'react';
import { connect } from 'react-redux';
import { activateDefaultAwsAccount } from 'state/actions';
import { FormControl, MenuItem, Select, styled } from '@material-ui/core';
import AwsAccountStatusIcon from '../Menu/CompanySettings/Aws/Status/AwsAccountStatusIcon';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: '7px 10px 5px 10px',
  minWidth: '135px',
}));

const selectedDisplayProps = {
  style: {
    paddingLeft: '5px',
    fontWeight: 600,
    fontSize: 15,
  },
};

const AccountSelector = ({
  awsAccounts,
  defaultAccount,
  activateDefaultAwsAccount,
}) => {
  const handleAccountChange = async (event) => {
    const accountId = event.target.value;
    await activateDefaultAwsAccount(accountId);
    window.location.reload();
  };

  if (!defaultAccount || awsAccounts.length <= 1) return <div />;

  return (
    <>
      <StyledFormControl>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={defaultAccount.id}
          defaultValue={defaultAccount.id}
          MenuProps={{
            disableScrollLock: true,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          SelectDisplayProps={selectedDisplayProps}
          onChange={handleAccountChange}
          autoWidth={true}
          native={false}
        >
          {awsAccounts.map((account) => {
            return (
              <MenuItem key={account.id} value={account.id}>
                {account.alias}
              </MenuItem>
            );
          })}
        </Select>
      </StyledFormControl>
      <AwsAccountStatusIcon awsAccountId={defaultAccount.id} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    awsAccounts: state.aws?.all,
    defaultAccount: state.aws?.default,
  };
};
export const mapDispatchToProps = {
  activateDefaultAwsAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSelector);
