import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Link } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { OpenInNew } from 'assets/icons';
import InfoTooltip from './InfoTooltip';

const Title = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.main,
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  lineHeight: 1,
  color: theme.palette.text.main,
}));

const Note = styled(Typography)(({ theme }) => ({
  fontSize: 14,
}));

const StyledTooltip = styled(InfoTooltip)({
  marginTop: 0,
  marginBottom: 0,
});

const FieldWrapper = ({
  label,
  subtitle,
  children,
  variant,
  className,
  linkText,
  helperText,
  inverted,
  link,
  addBottomPadding,
}) => {
  const styles =
    variant === 'small'
      ? {
          fontWeight: 700,
          fontSize: 14,
        }
      : {};
  if (inverted) styles.color = '#fff';

  return (
    <div className={className} style={{ width: '100%' }}>
      <Title>
        {label && (
          <Label variant="h6" style={styles}>
            <Box display="flex">
              <span>{label}</span>
              {helperText && <StyledTooltip size="small" text={helperText} />}
            </Box>
          </Label>
        )}
        {subtitle && !linkText && (
          <Note
            variant="subtitle2"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
        {subtitle && linkText && link && (
          <Note variant="subtitle2">
            {subtitle}{' '}
            <Link target="_blank" href={link} style={{ fontWeight: 700 }}>
              {linkText}
              <OpenInNew
                style={{
                  fontSize: '1rem',
                  marginLeft: 2,
                  marginBottom: 2,
                  verticalAlign: 'middle',
                }}
              />
            </Link>
          </Note>
        )}
      </Title>
      <Box pb={addBottomPadding ? 0.5 : 0}>{children}</Box>
    </div>
  );
};

FieldWrapper.propTypes = {
  label: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default memo(FieldWrapper);
