import { combineReducers } from 'redux';
import auth from './authReducer';
import aws from './awsAccountsReducer';
import company from './companyReducer';
import layout from './layoutReducer';
import identities from './identityReducer';
import templates from './templateReducer';
import tags from './tagReducer';
import user from './userReducer';

const rootReducer = combineReducers({
  auth,
  aws,
  company,
  layout,
  identities,
  tags,
  templates,
  user,
});

const reducer = (state, action) => {
  if (action.type === 'SIGN_OUT') {
    state = undefined;
  }

  return rootReducer(state, action);
};

export default reducer;
