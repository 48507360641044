import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import { Grid, MenuItem } from '@material-ui/core';
import { AsyncDialogActions, FieldWrapper } from 'components/Base';
import * as Yup from 'yup';

const Schema = Yup.object().shape({
  name: Yup.string()
    .matches(
      '^[a-zA-Z0-9_-]*$',
      'Only alphanumeric letters, minuses or underscores are allowed.'
    )
    .required('This field is required')
    .default(),
  destination: Yup.string(),
});

const DuplicateTemplateForm = ({
  onSubmit,
  loading,
  template,
  awsAccounts,
  defaultAccount,
}) => {
  return (
    <Formik
      initialValues={{
        name: `${template?.templateName}_COPY`,
        destination: defaultAccount ? defaultAccount.id : undefined,
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldWrapper
                label="Template Name (editable)"
                subtitle="Will be shown in the overview and will be used by AWS SES."
              >
                <Field
                  component={TextField}
                  fullWidth
                  name="name"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            {awsAccounts.length > 1 && (
              <Grid item xs={12}>
                <FieldWrapper
                  label="Destination"
                  subtitle="Choose a connected AWS account as destination"
                >
                  <Field
                    component={Select}
                    fullWidth
                    name="destination"
                    variant="outlined"
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {awsAccounts.map((account) => {
                      return (
                        <MenuItem key={account.id} value={account.id}>
                          {account.alias}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </FieldWrapper>
              </Grid>
            )}
          </Grid>
          <AsyncDialogActions
            loading={loading}
            submitText="Duplicate Template"
          />
        </Form>
      )}
    </Formik>
  );
};

DuplicateTemplateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    awsAccounts: state.aws?.all,
    defaultAccount: state.aws?.default,
  };
};

export default connect(mapStateToProps)(DuplicateTemplateForm);
