import React from 'react';
import {
  Box,
  styled,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const VisuallyHiddenSpan = styled('span')({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
});

function EnhancedTableHeader(props) {
  const { headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: 600, borderBottom: '1px solid grey' }}
            key={`${headCell.id}`}
            align={headCell.align || (headCell.numeric ? 'right' : 'left')}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Box display="flex">
              {headCell.sortable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <VisuallyHiddenSpan>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </VisuallyHiddenSpan>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
              {headCell?.filterComponent}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHeader;
