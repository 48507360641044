import React from 'react';
import {
  Box,
  Grid,
  LinearProgress,
  styled,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { OnboardingCard, SettingsCard, SettingsUpgradeCard } from './Cards';
import {
  companyMembers,
  companySettings,
  companySubscription,
  domainManagement,
  identities,
  templateStarters,
  userSettings,
} from '../../../consts/routes';
import {
  Aws,
  MailOutline,
  Membership,
  Settings,
  Team,
  User,
  Verified,
  World,
} from '../../../assets/icons';
import { useHistory } from 'react-router-dom';
import { useSubscription } from 'components/Contexts';
import { Badge, Loader } from 'components/Base';
import OverviewRow from './OverviewRow';
import { useAsyncAction } from '../../../state';
import { loadCompanyInfo } from '../../../state/actions';

const Root = styled('div')(({ theme, shorttitlerow }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: '23px 24px',
  backgroundColor: theme.palette.background.main,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: shorttitlerow ? '6vh 60vh auto' : '10vh 60vh auto',
  gridTemplateAreas: `"title"
  "main"
  "settings"`,
  columnGap: theme.spacing(0.5),
  rowGap: theme.spacing(1),
  alignItems: 'start',
}));

const TitleArea = styled('div')(({ theme, nosubtitle }) => ({
  minHeight: nosubtitle ? '45px' : '80px',
  gridArea: 'title',
  width: '90%',
}));

const MainArea = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0, 2, 0),
  height: '80%',
  gridArea: 'main',
}));

const SettingsArea = styled('div')(({ theme }) => ({
  gridArea: 'settings',
}));

const DashboardText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  fontSize: '16px',
}));

const DashboardTitle = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  color: theme.palette.text.main,
  fontWeight: 700,
  marginBottom: theme.spacing(1),
  marginTop: '1px',
}));

const AreaTitle = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  fontWeight: 700,
  fontSize: '20px',
  marginBottom: theme.spacing(1),
  marginTop: '1px',
}));

const StyledProgress = styled(LinearProgress)(({ theme }) => ({
  height: '8px',
  width: '75%',
  backgroundColor:
    theme.palette.type === 'dark'
      ? theme.palette.text.reverted
      : theme.palette.grey[200],
  borderRadius: '8px',
}));

const DashboardTitleRow = ({ awsConnected, firstName, setupCompleted }) => {
  const welcomeText = awsConnected ? 'Welcome back, ' : 'Welcome ';
  const onboardingText =
    "🦊 It's only a few steps to your first email sent out with Semplates! Connect your AWS account. Setup domain and email identities. Then start creating powerful templates.";
  return (
    <TitleArea nosubtitle={setupCompleted ? 1 : 0}>
      <DashboardTitle variant="h1">{welcomeText + firstName}</DashboardTitle>
      {!setupCompleted && (
        <DashboardText variant="body1">{onboardingText}</DashboardText>
      )}
    </TitleArea>
  );
};

const DashboardMainRow = ({
  awsConnected,
  emailIdentitiesExisting,
  domainIdentitiesExisting,
  templatesExisting,
  progress,
  setupCompleted,
}) => {
  const rowTitle = setupCompleted ? 'Your overview' : 'Your progress';
  const history = useHistory();

  const onboardingCards = [
    {
      Icon: Aws,
      title: awsConnected ? 'AWS account connected' : 'Connect AWS account',
      completed: awsConnected,
      handleClick: () => history.push(companySettings),
      buttonText: 'Add credentials',
      successMessage: 'You connected your AWS SES account.',
      infoUrl: 'https://semplates.io/documentation/setup-guide/connect-aws-ses',
    },
    {
      Icon: World,
      title: domainIdentitiesExisting
        ? 'Domain verified'
        : 'Verify your domain',
      completed: domainIdentitiesExisting && awsConnected,
      disabled: !awsConnected,
      handleClick: () => history.push(domainManagement),
      buttonText: 'Verify domain',
      successMessage: 'You verified your domain identity.',
      infoUrl:
        'https://semplates.io/documentation/setup-guide/verify-domain-identities-dkim-spf',
    },
    {
      Icon: Verified,
      title: emailIdentitiesExisting
        ? 'Email verified'
        : 'Verify email address',
      completed: emailIdentitiesExisting && awsConnected,
      disabled: !awsConnected,
      handleClick: () => history.push(identities),
      buttonText: 'Verify email',
      successMessage: 'You verified your sending identity.',
      infoUrl:
        'https://semplates.io/documentation/setup-guide/verify-sending-identities',
    },
    {
      Icon: MailOutline,
      title: templatesExisting ? 'Template created' : 'Create a template',
      completed: templatesExisting,
      handleClick: () => history.push(templateStarters),
      buttonText: 'Create template',
      successMessage: 'You created your first template.',
      infoUrl:
        'https://semplates.io/documentation/setup-guide/create-review-send-templates',
    },
  ];

  return (
    <MainArea>
      <Box display={'flex'} style={{ flexDirection: 'row' }} pb={1}>
        <AreaTitle>{rowTitle}</AreaTitle>
        {!setupCompleted && <Badge text={progress.toString() + '%'} />}
      </Box>
      {!setupCompleted && (
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Box mb={2}>
              <StyledProgress
                variant="determinate"
                value={progress}
                color="secondary"
              />
            </Box>
          </Grid>
          {onboardingCards.map((props, idx) => {
            return (
              <Grid item lg={3} sm={6} key={`onboardingRowCard-${idx}`}>
                <OnboardingCard step={idx + 1} {...props} />
              </Grid>
            );
          })}
        </Grid>
      )}
      {setupCompleted && <OverviewRow />}
    </MainArea>
  );
};

const DashboardSettingsRow = ({ isAdmin }) => {
  const rowTitle = 'Your settings';
  const { isDefaultPlan, openUpgradeDialog } = useSubscription();
  const history = useHistory();

  const settingsCards = [
    {
      title: 'View your profile settings',
      handleClick: () => history.push(userSettings),
      Icon: User,
    },
    {
      title: 'View your subscription',
      handleClick: () => history.push(companySubscription),
      Icon: Settings,
    },
    {
      title: !isAdmin
        ? 'Manage your team'
        : isDefaultPlan
        ? 'Unlock team'
        : 'View your team settings',
      disabledSubtitle: !isAdmin
        ? 'available for admins'
        : isDefaultPlan
        ? 'by upgrading your plan'
        : null,
      disabledButtonText: isAdmin && 'Upgrade',
      handleClick: !isAdmin
        ? null
        : isDefaultPlan
        ? () => openUpgradeDialog()
        : () => history.push(companyMembers),
      Icon: Team,
      disabled: !isAdmin || isDefaultPlan,
    },
  ];

  const upgradeCardContent = {
    title: 'Need more features?',
    text: 'Semplates grows with you; upgrade now to unlock more features',
    buttonText: 'See plans',
    Icon: Membership,
  };

  return (
    <SettingsArea>
      <AreaTitle>{rowTitle}</AreaTitle>
      <Box my={2}>
        <Grid container spacing={1} alignItems="stretch">
          {settingsCards.map((props, index) => {
            return (
              <Grid item lg={3} sm={6} key={`settingscard-${index}`}>
                <SettingsCard {...props} showNudge={isDefaultPlan} />
              </Grid>
            );
          })}
          {isDefaultPlan && (
            <Grid item lg={3} sm={6}>
              <SettingsUpgradeCard {...upgradeCardContent} />
            </Grid>
          )}
        </Grid>
      </Box>
    </SettingsArea>
  );
};

const DashboardMain = ({
  isAdmin,
  awsConnected,
  emailIdentitiesExisting,
  domainIdentitiesExisting,
  templatesExisting,
  firstName,
  progress,
}) => {
  const { loading } = useSubscription();
  const setupCompleted = progress >= 100;
  useAsyncAction({
    action: loadCompanyInfo,
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <Root shorttitlerow={setupCompleted ? 1 : 0}>
      <DashboardTitleRow
        awsConnected={awsConnected}
        firstName={firstName}
        setupCompleted={setupCompleted}
      />
      <DashboardMainRow
        awsConnected={awsConnected}
        emailIdentitiesExisting={emailIdentitiesExisting}
        domainIdentitiesExisting={domainIdentitiesExisting}
        templatesExisting={templatesExisting}
        progress={progress}
        setupCompleted={setupCompleted}
      />
      <DashboardSettingsRow awsConnected={awsConnected} isAdmin={isAdmin} />
    </Root>
  );
};

const mapStateToProps = (state) => {
  return {
    firstName: state.user?.firstName || '',
    awsConnected: state.company?.summary?.awsConnected || false,
    emailIdentitiesExisting:
      state.company?.summary?.emailIdentitiesExisting || false,
    domainIdentitiesExisting:
      state.company?.summary?.domainIdentitiesExisting || false,
    templatesExisting: state.company?.summary?.templatesExisting || false,
    progress: state.company?.summary?.progress || 0,
    isAdmin: state.user?.role ? state.user.role === 'admin' : false,
  };
};

export default connect(mapStateToProps)(DashboardMain);
