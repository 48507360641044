import React, { useEffect } from 'react';
import Bugsnag from '@bugsnag/js';

const BugsnagContext = React.createContext(null);

const BugsnagProvider = ({ children }) => {
  const { user } = JSON.parse(window.localStorage.getItem('persist:root'));

  useEffect(() => {
    const { email } = user;

    Bugsnag.setUser(email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <BugsnagContext.Provider>{children}</BugsnagContext.Provider>;
};

export { BugsnagProvider };

export default BugsnagContext;
