import React from 'react';
import { Box, Card, styled, useTheme } from '@material-ui/core';
import { ArrowRightAlt, Done, Lock } from 'assets/icons';
import { Status } from './data';

const CardHeader = styled('div')(({ theme, color }) => ({
  fontWeight: 800,
  fontSize: theme.spacing(2.25),
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: color,
}));

const SquaredActionCard = styled(Card)(({ theme, status }) => ({
  padding: theme.spacing(0, 1, 0, 0),
  height: theme.spacing(27),
  position: 'relative',
  paddingTop: theme.spacing(1),
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: status !== Status.disabled && 'pointer',
  backgroundColor:
    !status || status === Status.done
      ? theme.palette.primary.main
      : status === Status.possible
      ? theme.palette.primary.light
      : theme.palette.grey.inactive,
}));

const StyledBox = styled(Box)({
  height: '10%',
});

const StyledIconBox = styled(Box)(({ theme, color }) => ({
  paddingLeft: theme.spacing(1),
  display: 'inline-block',
  position: 'absolute',
  bottom: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.text.reverted,
}));

const IconContainer = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(7),
  width: theme.spacing(10),
  height: theme.spacing(10),
  borderRadius: '100%',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.main,
  color: theme.palette.text.main,
}));

const Description = styled('div')(({ theme, color }) => ({
  width: '100%',
  textAlign: 'center',
  padding: theme.spacing(0.5),
  fontSize: theme.spacing(1.5),
  color: color,
}));

const ActionArea = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  position: 'absolute',
  bottom: theme.spacing(1),
  width: '100%',
}));

const ActionDescription = styled('div')(({ theme, color, status }) => ({
  fontWeight: 600,
  fontSize: theme.spacing(1.7),
  textAlign: 'center',
  cursor: status === Status.disabled ? 'initial' : 'pointer',
  color: color,
}));

const CardComponent = ({ item, loading }) => {
  const theme = useTheme();

  const getTextColor = (status) => {
    return !status || status === Status.done
      ? theme.palette.text.main
      : status === Status.possible
      ? theme.palette.text.main
      : theme.palette.text.reverted;
  };

  const getIcon = (icon) => {
    if (item.status === Status.possible)
      return (
        <StyledIconBox color={getTextColor(item.status)}>
          <ArrowRightAlt size={22} />
        </StyledIconBox>
      );
    if (item.status === Status.done)
      return (
        <StyledIconBox color={getTextColor(item.status)}>
          <Done size={22} />
        </StyledIconBox>
      );
    if (item.status === Status.disabled)
      return (
        <StyledIconBox color={getTextColor(item.status)}>
          <Lock size={22} />
        </StyledIconBox>
      );
  };

  return (
    <SquaredActionCard
      status={item.status}
      onClick={item.status !== Status.disabled ? item.onClick : () => {}}
    >
      {!item.action && <StyledBox />}
      {item.title && (
        <CardHeader color={getTextColor(item.status)}>{item.title}</CardHeader>
      )}
      {item.subtitle && (
        <Description color={getTextColor(item.status)}>
          {item.subtitle}
        </Description>
      )}
      {<IconContainer>{item.icon}</IconContainer>}
      {
        <ActionArea>
          <ActionDescription
            color={getTextColor(item.status)}
            status={item.status}
          >
            {!loading && item.action && item.status !== Status.disabled
              ? item.action
              : item.disabledText}
          </ActionDescription>
        </ActionArea>
      }
      {!item.action && <StyledBox />}
      {getIcon(item.status)}
    </SquaredActionCard>
  );
};

export default CardComponent;
