import React from 'react';
import { styled, Typography } from '@material-ui/core';
import { Settings } from 'assets/icons';
import { useHistory } from 'react-router-dom';
import { companySubscription } from '../../consts/routes';
import { useSubscription } from '../Contexts';

const StyledContainer = styled('div')(({ theme }) => ({
  margin: '8px',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const StyledLine = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'center',
  textAlign: 'right',
  padding: '0px',
  margin: '0px',
  height: '20px',
  color: theme.palette.text.main,
}));

const StyledSettings = styled(Settings)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 0.5),
  fontWeight: 1000,
}));

const SubscriptionNudge = () => {
  const history = useHistory();
  const nudge = 'Upgrade now';

  const {
    isDefaultPlan,
    subscription: { name: planName },
    openUpgradeDialog,
  } = useSubscription();

  const handleSubscriptionDialog = () => {
    isDefaultPlan ? openUpgradeDialog() : history.push(companySubscription);
  };

  return (
    <StyledContainer onClick={handleSubscriptionDialog}>
      {isDefaultPlan && planName && (
        <>
          <StyledLine>
            <Typography variant="caption" style={{ fontWeight: '600' }}>
              {planName}
            </Typography>
          </StyledLine>
          <StyledLine>
            <Typography variant="caption" style={{ fontWeight: '600' }}>
              {nudge}
            </Typography>
            <StyledSettings fontSize={'small'} />
          </StyledLine>
        </>
      )}
    </StyledContainer>
  );
};

export default SubscriptionNudge;
