import React from 'react';
import { useWelcomeSlides } from 'components/WelcomeSlides/hooks';
import { SlideIndicator } from 'components/Base';
import { ControlBarContainer, StyledButton } from './styles';

const TextControlBar = () => {
  const {
    currentSlide,
    nextSlide,
    slides,
    setCurrentSlide,
  } = useWelcomeSlides();

  return (
    <ControlBarContainer>
      <SlideIndicator
        activeIndex={currentSlide}
        slideCount={slides.length}
        onClick={setCurrentSlide}
      />
      <StyledButton onClick={nextSlide} inverted>
        Next
      </StyledButton>
    </ControlBarContainer>
  );
};

export default TextControlBar;
