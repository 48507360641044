import React from 'react';
import { styled, Typography } from '@material-ui/core';

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 600,
  paddingBottom: theme.spacing(2),
}));

const FormTitle = ({ variant, children }) => {
  return <StyledTypography variant={variant}>{children}</StyledTypography>;
};
export default FormTitle;
