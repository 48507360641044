import React, { createContext, useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

const TrackingID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

const useTracking = () => useContext(TrackingContext);

const TrackingContext = createContext(null);

const TrackingProvider = (props) => {
  const { user } = useSelector((state) => state);
  const domain = user.email.split('@')[1];

  const [analytics, setAnalytics] = useState({
    isInitialized: false,
    trackers: ['semplatesFrontendTracker'],
  });

  useEffect(() => {
    const { isInitialized } = analytics;

    if (!isInitialized && TrackingID) {
      ReactGA.initialize(TrackingID);

      setAnalytics({
        ...analytics,
        isInitialized: true,
      });
    }
  }, [user, analytics]);

  const logEvent = ({ category = '', action = '' }) => {
    const label = `${domain}: ${user.firstName} ${user.lastName}`;
    if (analytics.isInitialized) {
      ReactGA.event(
        {
          category,
          action,
          label,
        },
        analytics.trackers
      );
    }
  };

  return <TrackingContext.Provider value={{ logEvent }} {...props} />;
};

export { TrackingProvider, useTracking };
