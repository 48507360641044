import React from 'react';
import PropTypes from 'prop-types';
import { DialogFormFrame } from 'components/Base';
import VerifyDomainForm from './VerifyDomainForm';
import { useLazyLoading } from 'state/hooks';
import { verifyDomain } from 'state/actions';

const VerifyDomainDialog = ({ open, onClose }) => {
  const [handleSubmit, { loading }] = useLazyLoading({
    action: verifyDomain,
    onSuccess: () => {
      onClose();
    },
  });

  return (
    <DialogFormFrame
      title="Verify new domain"
      open={open}
      onClose={onClose}
      formComponent={
        <VerifyDomainForm onSubmit={handleSubmit} loading={loading} />
      }
    />
  );
};

VerifyDomainDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VerifyDomainDialog;
