const INITIAL_STATE = {
  drawerSubNavOpen: false,
  importTemplatesDialogOpen: false,
  sendTemplateOptions: {
    open: false,
    template: null,
    templateInputs: null,
  },
  themeMode: 'light',
  shimmerOverlay: false,
  snackbarOpen: false,
  snackbarMessage: '',
  snackbarSeverity: 'success',
  upgradeDialogOpen: false,
  upgradeDialogErrorCode: undefined,
};

const layoutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'IMPORT_TEMPLATES_DIALOG_OPEN':
      return {
        ...state,
        importTemplatesDialogOpen: action.payload,
      };
    case 'SEND_TEMPLATE_OPTIONS_SET': {
      return {
        ...state,
        sendTemplateOptions: action.payload,
      };
    }
    case 'DRAWER_SUBNAV_OPEN_SET': {
      return {
        ...state,
        drawerSubNavOpen: action.payload,
      };
    }
    case 'SET_THEME_MODE': {
      return {
        ...state,
        themeMode: action.payload,
      };
    }
    case 'SET_SHIMMER_OVERLAY':
      return {
        ...state,
        shimmerOverlay: action.payload,
      };
    case 'SNACKBAR_SUCCESS':
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.payload,
        snackbarSeverity: 'success',
      };
    case 'SNACKBAR_ERROR':
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.payload,
        snackbarSeverity: 'error',
      };
    case 'SNACKBAR_INFO':
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.payload,
        snackbarSeverity: 'info',
      };
    case 'SNACKBAR_CLEAR':
      return {
        ...state,
        snackbarOpen: false,
      };
    case 'UPGRADE_DIALOG_OPEN':
      return {
        ...state,
        upgradeDialogOpen: true,
        upgradeDialogErrorCode: action.payload,
      };
    case 'UPGRADE_DIALOG_CLOSE':
      return {
        ...state,
        upgradeDialogOpen: false,
        upgradeDialogErrorCode: undefined,
      };
    case 'SET_TEMPLATE_ROWS_PER_PAGE':
      return {
        ...state,
        templateRowsPerPage: action.payload,
      };
    case 'SET_TEMPLATE_FILTER':
      return {
        ...state,
        templateFilter: action.payload,
        templateTablePage: 0,
      };
    case 'SET_TEMPLATE_FILTER_TAGS':
      return {
        ...state,
        templateFilterTags: action.payload,
        templateTablePage: 0,
      };
    case 'SET_TEMPLATE_TABLE_PAGE':
      return {
        ...state,
        templateTablePage: action.payload,
      };
    default:
      return state;
  }
};

export default layoutReducer;
