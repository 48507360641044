import React from 'react';
import { StyledAppBar } from 'components/Structure';
import { TitleContainer } from 'components/Structure/AppBar';
import { Typography } from '@material-ui/core';

const StartersAppbar = () => {
  return (
    <StyledAppBar position="fixed">
      <TitleContainer>
        <Typography variant="h5">Choose a template</Typography>
      </TitleContainer>
    </StyledAppBar>
  );
};

export default StartersAppbar;
