import React from 'react';
import PropTypes from 'prop-types';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid, MenuItem } from '@material-ui/core';
import { AsyncDialogActions, FieldWrapper } from 'components/Base';
import * as Yup from 'yup';

const CreateSchema = Yup.object().shape({
  email: Yup.string().required('Please enter a valid email address.'),
  role: Yup.string().required('Please select a role'),
  confirmAdditionalCosts: Yup.bool(),
});

const roles = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Template Maintainer',
    value: 'member',
  },
];

const InviteCompanyMemberForm = ({
  confirmAdditionalCosts,
  loading,
  member,
  onClose,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{
        email: member?.email || '',
        role: member?.role,
        confirmAdditionalCosts: confirmAdditionalCosts,
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={CreateSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldWrapper
                label="Email Address"
                subtitle="Enter the mail of the person you want to invite"
              >
                <Field
                  component={TextField}
                  fullWidth
                  name="email"
                  variant="outlined"
                  disabled={!!member?.email}
                />
              </FieldWrapper>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FieldWrapper label="Role" subtitle="Enumerable - Admin or User">
                <Field
                  component={TextField}
                  type="text"
                  name="role"
                  select
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {roles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </FieldWrapper>
            </Grid>
            <AsyncDialogActions
              onClose={onClose}
              loading={isSubmitting || loading}
              submitText={member?.email ? 'Update' : 'Invite'}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

InviteCompanyMemberForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default InviteCompanyMemberForm;
