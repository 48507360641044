import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout, SettingsContainer } from 'components/Structure';
import {
  companyMembers,
  companySettings,
  companySubscription,
} from 'consts/routes';
import AwsAccounts from './AwsAccounts';
import { Members, Subscriptions } from 'components/Menu/CompanySettings';

const CompanySettings = () => {
  return (
    <Layout title="Company Settings">
      <SettingsContainer>
        <Switch>
          <Route exact path={companySettings} component={AwsAccounts} />
          <Route exact path={companyMembers} component={Members} />
          <Route exact path={companySubscription} component={Subscriptions} />
        </Switch>
      </SettingsContainer>
    </Layout>
  );
};

export default CompanySettings;
