import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  AwsNotConnected,
  AwsAccountsSettings,
} from 'components/Menu/CompanySettings/Aws';
import { LogoLoader } from 'components/Base';

const AwsAccounts = ({ awsConnected }) => {
  if (awsConnected === undefined) {
    return <LogoLoader />;
  }

  if (!awsConnected) {
    return <AwsNotConnected />;
  }

  return (
    <Fragment>
      <Grid container direction={'row'} spacing={4}>
        <Grid item xs={12}>
          <AwsAccountsSettings />
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    awsConnected: state.aws.all?.length > 0 || false,
  };
};

export default connect(mapStateToProps)(AwsAccounts);
