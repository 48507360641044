// Hell Beige: FEF8F0
// Dunkleres Beige: F0E1C6
// Dunkel Türkis: 285958
// Orange: F19273
import { updateTheme } from './themeOverrides';

const darkThemeDefaults = {
  palette: {
    type: 'dark',
    primary: {
      light: '#09759C',
      main: '#064861',
      dark: '#043647',
    },
    secondary: {
      superlight: '#FFFCE9',
      light: '#F9E48A',
      main: '#FFD301',
      dark: '#E6BF00',
    },
    text: {
      default: '#032733',
      defaultReverted: '#ffffff',
      reverted: '#032733',
      main: '#ffffff',
    },
    alert: {
      info: '#E5F5FD',
    },
    warning: {
      light: '#E79FAC',
      main: '#E38190',
    },
    error: {
      main: '#f44336',
    },
    succes: {
      main: '#1DED4A',
    },
    info: {
      light: '#F9E48A',
      main: '#FFD301',
      dark: '#E6BF00',
    },
    background: {
      light: '#FFFCE9',
      main: '#0d1b1e',
      dark: '#0d1b1e',
      default: '#0d1b1e',
      paper: '#102428',
    },
    grey: {
      main: '#3C807E',
      100: '#3a3b3c',
      200: '#3a3b3c',
      400: '#424242',
      inactive: '#A0A8AB',
      borderColor: '#06486133',
    },
    analytics: {
      first: '#084861',
      second: '#425D62',
      third: '#6F7560',
      fourth: '#9B9057',
      fifth: '#C8AF4B',
      sixth: '#F8D139',
    },
  },
  typography: {
    fontFamily: ['Montserrat', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
      ','
    ),
    fontFamilyTitle: ['Exo'].join(','),
    tableTitle: {
      color: 'red',
    },
  },
  custom: {
    appBarHeight: 60,
    drawerClosedWidth: 73,
    drawerWidth: 220,
    dropContainerHeight: 660,
    dropContainerWidth: 375,
    editBorderStyle: '1px dashed transparent',
    editBorderStyleHover: '1px dashed #616161',
    editorSidebarWidth: 500,
    profileAreaWidth: 250,
    sideAreaWidth: 300,
    buttonHeight: 36,
    tagHeight: 30,
    standardShadow: '0px 8px 8px 0px #0000000D',
  },
};
export default updateTheme(darkThemeDefaults);
