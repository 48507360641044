import React, { Fragment, useState } from 'react';
import { DeleteOutline as DeleteIcon } from 'assets/icons';
import {
  AlertDialog,
  LogoLoader,
  OverviewTable,
  SettingsHeader,
} from 'components/Base';
import { VerifyDomainDialog } from './index';
import { useAsyncAction, useLazyLoading } from 'state/hooks';
import {
  getDomainIdentities,
  syncDomainIdentities,
  removeIdentity,
  getDomainIdentityDetails,
} from 'state/actions';
import { connect } from 'react-redux';
import { verifiedDomainColumns } from 'consts';
import { AwsNotConnected } from '../CompanySettings/Aws';
import { InfoOutlined } from '@material-ui/icons';
import DomainDetailsTableContent from './DomainDetailsTableContent';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

const DomainManager = ({ awsConnected, domainIdentities, domainCount }) => {
  const [domainDialogOpen, setDomainDialogOpen] = useState(false);
  const [scopedDomain, setScopedDomain] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { loading, error } = useAsyncAction({ action: getDomainIdentities });

  const [domainDetails, setDomainDetails] = useState({});
  const [showDomainDetails, setShowDomainDetails] = useState(false);

  const [getDomainDetailsAction, { loading: detailsLoading }] = useLazyLoading({
    action: getDomainIdentityDetails,
    onSuccess: (result) => {
      if (typeof result === 'string') {
        setDomainDetails({
          error: true,
          domainName: result,
        });
      } else {
        setDomainDetails(result);
      }
    },
  });

  const [handleRefresh, { loading: refreshing }] = useLazyLoading({
    action: syncDomainIdentities,
  });

  const [handleRemoveIdentity, { loading: deleting }] = useLazyLoading({
    action: removeIdentity,
  });

  const handleCloseDialog = async () => {
    setDomainDialogOpen(false);
    setDeleteDialogOpen(false);
    setScopedDomain(null);
  };

  const confirmRemoveIdentity = (row) => {
    setScopedDomain(row);
    setDeleteDialogOpen(true);
  };

  const handleShowDomainDetail = (row) => {
    setScopedDomain(row);
    if (row.uid === scopedDomain?.uid) {
      setShowDomainDetails(!showDomainDetails);
    } else {
      setShowDomainDetails(true);
      if (!domainDetails[row.identity]) {
        getDomainDetailsAction(row.identity);
      }
    }
  };

  if (loading) return <LogoLoader />;

  if (error && error.includes('403')) {
    return (
      <Alert severity="error">
        <AlertTitle>Access Denied</AlertTitle>
        <Typography variant="body2">
          You don't have the necessary permissions to view this information.
          Please contact your administrator to request access.
        </Typography>
      </Alert>
    );
  }

  if (!awsConnected) return <AwsNotConnected />;

  return (
    <Fragment>
      <SettingsHeader
        title="Verified Domains"
        buttonText={'Add Domain'}
        onClick={() => setDomainDialogOpen(true)}
        refreshing={refreshing}
        onRefresh={handleRefresh}
      />
      <OverviewTable
        data={domainIdentities}
        columns={verifiedDomainColumns}
        anyDialogOpen={deleteDialogOpen || domainDialogOpen}
        total={domainCount}
        actions={[
          {
            icon: <InfoOutlined size={22} />,
            tooltip: 'Show Account Status Info',
            onClick: handleShowDomainDetail,
          },
          {
            icon: <DeleteIcon size={22} />,
            tooltip: 'Remove',
            onClick: confirmRemoveIdentity,
          },
        ]}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <AlertDialog
        onClose={handleCloseDialog}
        open={deleteDialogOpen}
        title={'Remove domain'}
        onSubmit={async () => {
          await handleRemoveIdentity(scopedDomain);
          await handleCloseDialog();
          setScopedDomain(null);
        }}
        text={
          'Are you sure you want to delete the domain ' +
          scopedDomain?.domainName +
          '?'
        }
        submitText={'Remove'}
        loading={deleting}
      />
      {showDomainDetails && (
        <DomainDetailsTableContent
          domainDetails={domainDetails}
          loading={detailsLoading}
        />
      )}
      <VerifyDomainDialog open={domainDialogOpen} onClose={handleCloseDialog} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    awsConnected: state.aws.all?.length > 0,
    domainIdentities: state.identities.domains,
    domainCount: state.identities.domains.length,
  };
};

export default connect(mapStateToProps)(DomainManager);
