import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/remote-config';
import 'firebase/compat/storage';
import { firebaseConfig } from '../../consts/firebaseConfig';

class Firebase {
  constructor({ useMock }) {
    firebase.initializeApp(firebaseConfig);

    if (useMock) {
      firebase.auth().useEmulator('http://localhost:9099/');
      firebase.functions().useEmulator('localhost', 5001);
    }
    this.firebase = firebase;
  }

  getFirebase = () => this.firebase;
}

export default Firebase;
