/* eslint-disable no-use-before-define */
import React, { Fragment, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { countries } from 'components/Menu/CompanySettings/Shared/consts';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const CountryCodeAutoComplete = ({
  field,
  form: { errors, touched, setFieldValue, setFieldTouched },
}) => {
  const classes = useStyles();
  const { name } = field;
  const onChange = useCallback(
    (event, item) => {
      if (!item) setFieldValue(name, null);
      if (!item || !item.code) return;
      setFieldValue(name, item.code);
    },
    [name, setFieldValue]
  );

  const valueToOption = (value) => {
    return countries.find((el) => el.code === value) || { code: '', label: '' };
  };

  return (
    <Autocomplete
      id="country-select"
      options={countries}
      classes={{
        option: classes.option,
      }}
      {...field}
      value={valueToOption(field.value)}
      onChange={onChange}
      onBlur={() => setFieldTouched(name, true)}
      autoHighlight
      getOptionLabel={(option) => {
        return option?.label ? option.label : '';
      }}
      renderOption={(option) => (
        <Fragment>
          {option.label} ({option.code})
        </Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={touched[name] && !!errors[name]}
          helperText={touched[name] && errors[name]}
          variant="outlined"
          size="small"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'dropdown', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
export default CountryCodeAutoComplete;
