const signInProvidersColumns = [
  {
    id: 'provider',
    label: 'Provider',
    type: 'text',
    sortable: false,
    width: '50%',
  },
  {
    id: 'status',
    label: 'Status',
    type: 'text',
    sortable: false,
    align: 'right',
    width: '50%',
  },
  {
    id: 'action',
    label: 'Action',
    type: 'actions',
    sortable: false,
    align: 'right',
  },
];

export default signInProvidersColumns;
