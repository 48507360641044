import React from 'react';
import PropTypes from 'prop-types';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid, useTheme } from '@material-ui/core';
import { AsyncDialogActions, FieldWrapper } from 'components/Base';
import * as Yup from 'yup';
import ColorPickerField from './ColorPickerField';

const CreateSchema = Yup.object().shape({
  label: Yup.string().required('Please enter a label for this tag.'),
  color: Yup.string()
    .min(7)
    .max(7)
    .required('Please enter a valid Hex-Code'),
});

const CreateTagForm = ({ loading, tag, onClose, onSubmit }) => {
  const theme = useTheme();
  return (
    <Formik
      initialValues={{
        label: tag?.label || '',
        color: tag?.color || theme.palette.primary.light,
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={CreateSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldWrapper
                label="Tag label"
                subtitle="The label will be displayed on the chosen email template"
              >
                <Field
                  component={TextField}
                  fullWidth
                  name="label"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <ColorPickerField
                label="Color"
                subTitle="Enter a valid hex-code to color your tag"
                name="color"
                color={values.color}
                onChange={handleChange('color')}
              />
            </Grid>
            <AsyncDialogActions
              onClose={onClose}
              loading={loading}
              submitText={tag ? 'Update' : 'Create'}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

CreateTagForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CreateTagForm;
