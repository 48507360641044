import { useDispatch } from 'react-redux';
import { showSuccessSnackbar } from 'state/actions';

const useClipboard = (successMessage) => {
  const dispatch = useDispatch();
  return async (value) => {
    await navigator.clipboard.writeText(value);
    dispatch(showSuccessSnackbar(successMessage));
  };
};

export default useClipboard;
