import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { useDispatch } from 'react-redux';
import { showErrorSnackbar, showSuccessSnackbar } from 'state/actions';
import { signInProvidersColumns } from 'consts';
import {
  getAuth,
  unlink,
  GoogleAuthProvider,
  linkWithPopup,
  OAuthProvider,
} from 'firebase/auth';
import { OverviewTable } from 'components/Base';

const SignInProviders = ({ user }) => {
  const dispatch = useDispatch();

  const { currentUser } = getAuth();
  const providerData = currentUser?.providerData;

  const handleSSOProvider = async (provider) => {
    try {
      await linkWithPopup(currentUser, provider);
      dispatch(showSuccessSnackbar('Successfully linked Sign In method'));
    } catch (error) {
      dispatch(
        showErrorSnackbar(
          'Failed to link Sign In method. Please try again or contact support.'
        )
      );
      console.log(error);
    }
  };

  const handleUnlinkProvider = async (provider) => {
    try {
      await unlink(currentUser, provider);
      dispatch(showSuccessSnackbar('Successfully unlinked Sign In method'));
    } catch (error) {
      dispatch(
        showErrorSnackbar(
          'Failed to link Sign In method. Please try again or contact support.'
        )
      );
      console.log(error);
    }
  };

  const handleGoogleProvider = async () => {
    const googleProvider = new GoogleAuthProvider();
    await handleSSOProvider(googleProvider);
  };

  const handleMicrosoftProvider = async () => {
    const googleProvider = new OAuthProvider('microsoft.com');
    await handleSSOProvider(googleProvider);
  };

  // TODO: disconnect provider

  const providerIsConnected = (provider) => {
    return providerData?.some((data) => data.providerId.includes(provider));
  };

  const data = [
    {
      provider: 'Email & Password',
      status: providerIsConnected('password') ? 'Connected' : 'Not Connected',
      showConnectButton: false,
      showDisconnectButton:
        providerIsConnected('password') && providerData?.length > 1,
    },
    {
      provider: 'Google',
      status: providerIsConnected('google.com') ? 'Connected' : 'Not Connected',
      showConnectButton:
        !providerIsConnected('google.com') && !providerIsConnected('oidc'),
      showDisconnectButton:
        providerIsConnected('google.com') && providerData?.length > 1,
    },
    {
      provider: 'Microsoft',
      status: providerIsConnected('microsoft.com')
        ? 'Connected'
        : 'Not Connected',
      showConnectButton:
        !providerIsConnected('microsoft.com') && !providerIsConnected('oidc'),
      showDisconnectButton:
        providerIsConnected('microsoft.com') && providerData?.length > 1,
    },
    {
      id: 'okta',
      provider: 'Okta',
      status: providerIsConnected('oidc') ? 'Connected' : 'Not Connected',
      showConnectButton: false,
      showDisconnectButton: false,
    },
  ];

  const connectAction = {
    icon: <Button>Connect</Button>, // Replace with a suitable icon
    tooltip: 'Connect',
    onClick: (row) => {
      row.provider === 'Google'
        ? handleGoogleProvider()
        : handleMicrosoftProvider();
    },
    renderCondition: 'showConnectButton',
  };

  const disconnectButton = {
    icon: <Button>Disconnect</Button>, // Replace with a suitable icon
    tooltip: 'Disconnect',
    onClick: (row) => {
      // switch over provider
      switch (row.provider) {
        case 'Google':
          handleUnlinkProvider('google.com');
          break;
        case 'Microsoft':
          handleUnlinkProvider('microsoft.com');
          break;
        case 'Email & Password':
        default:
          handleUnlinkProvider('password');
          break;
      }
    },
    renderCondition: 'showDisconnectButton',
  };

  const actions = [connectAction, disconnectButton];

  return (
    <div>
      <Box mt={5} style={{ borderBottom: '1px solid lightgrey  ' }}>
        <Typography variant="h5" gutterBottom>
          Sign In Methods
        </Typography>
      </Box>
      <Box mt={3} mb={3}>
        <Grid spacing={3} container>
          <Grid item xs={12}>
            <OverviewTable
              data={data}
              columns={signInProvidersColumns}
              actions={actions}
              page={0}
              total={4}
              rowsPerPage={5}
            />
          </Grid>
          <Grid item xs={12}>
            {!providerIsConnected('oidc') && (
              <Box mb={2}>
                <Alert severity="info">
                  <AlertTitle>
                    <b>About Okta</b>
                  </AlertTitle>
                  Please contact your administrator and ask if Okta is the right
                  choice for your organization.<br></br> Once centrally enabled,
                  you will be able to log in to Semplates by clicking the tile
                  in your Okta dashboard.
                </Alert>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SignInProviders;
