const INITIAL_STATE = {
  role: 'member',
  welcomeSeen: true,
};

const applySetUserDetails = (state, { payload }) => {
  return {
    ...state,
    ...payload,
  };
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'USER_SET': {
      return applySetUserDetails(state, action);
    }
    default:
      return state;
  }
};

export default userReducer;
