import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useAsyncAction = ({
  action,
  onSuccess = () => {},
  onError = () => {},
  values = {},
  fetchPolicy = 'network-only',
}) => {
  const configString = JSON.stringify(values);
  const [state, setState] = useState({
    loading: true,
    partialLoading: false,
    error: undefined,
    data: undefined,
  });
  const dispatch = useDispatch();

  /* eslint-disable */
  const call = useCallback(
    async values => {
      setState(oldState => ({
        ...oldState,
        partialLoading: true,
        error: undefined,
      }));

      try {
        const data = await dispatch(action({ ...values }));
        setState(oldState => ({
          ...oldState,
          loading: false,
          partialLoading: false,
          data,
        }));

        onSuccess(data);
        return data;
      } catch (error) {
        setState(oldState => ({
          ...oldState,
          loading: false,
          partialLoading: false,
          error: error.message,
        }));

        onError(error.message);
        return undefined;
      }
    },
    [action]
  );

  useEffect(() => {
    call(values);
  }, [call, configString]);
  /* eslint-enable */

  return { ...state };
};
