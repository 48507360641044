import React, { useContext, useEffect, useState } from 'react';
import { useLazyLoading } from 'state/hooks';
import { getTags } from 'state/actions';
import { LogoLoader } from 'components/Base';
import PropTypes from 'prop-types';

const TagContext = React.createContext(null);

export const useTags = () => useContext(TagContext);

const TagProvider = ({ children }) => {
  const [tags, setTags] = useState();

  const [loadTags, { loading: tagsLoading }] = useLazyLoading({
    action: getTags,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!tags && !tagsLoading) {
        const { tags } = await loadTags();
        setTags(tags);
      }
    };
    fetchData();
  }, [tags, tagsLoading, loadTags]);

  if (tagsLoading) return <LogoLoader />;

  return <TagContext.Provider value={{ tags }}>{children}</TagContext.Provider>;
};

TagProvider.propTypes = {
  children: PropTypes.any,
};

export default TagProvider;
