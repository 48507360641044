import React from 'react';
import { FastField as Field } from 'formik';
import { TextField } from 'formik-material-ui';
import FieldWrapper from './FieldWrapper';

const TextInputField = ({ disabled, label, name, subtitle, size }) => {
  return (
    <FieldWrapper label={label} subtitle={subtitle}>
      <Field
        disabled={disabled}
        component={TextField}
        fullWidth
        name={name}
        variant="outlined"
        size={size}
      />
    </FieldWrapper>
  );
};

export default TextInputField;
