export const dashboard = '/';
export const analytics = '/analytics';
export const templates = '/templates';
export const starterRoute = '/starters';
export const templateVersions = templates + '/:id/versions';
export const templateStarters = templates + starterRoute;
export const templateEditor = templates + '/:id/editor';
export const templatePreview = templates + '/:id/preview';
export const tags = '/tags';
export const identities = '/identities';
export const domainRoute = '/domains';
export const domainManagement = identities + domainRoute;
export const companySettings = '/settings';
export const awsRoute = '/aws';
export const awsSettings = companySettings + '/aws';
export const detailsRoute = '/details';
export const detailsSettings = companySettings + '/details';
export const memberRoute = '/members';
export const companyMembers = companySettings + '/members';
export const subscriptionRoute = '/subscription';
export const companySubscription = companySettings + '/subscription';
export const userSettings = '/user';
export const userSecurity = userSettings + '/security';
export const userPrivacy = userSettings + '/privacy';
export const userTerms = userSettings + '/terms-of-use';

export const companySettingsRoutes = [
  companySettings,
  companyMembers,
  companySubscription,
];

export const identityRoutes = [domainManagement, identities];

export const templateRoutes = [
  templates,
  templateVersions,
  templateEditor,
  templatePreview,
  templateStarters,
];

export const userRoutes = [userSettings, userSecurity, userPrivacy, userTerms];

export const privateRoutes = [
  analytics,
  companySettings,
  dashboard,
  tags,
  ...companySettingsRoutes,
  ...identityRoutes,
  ...templateRoutes,
  ...userRoutes,
];
