import React, { Fragment, useState } from 'react';
import {
  AsyncDialogActions,
  DialogFormFrame,
  LogoLoader,
} from 'components/Base';
import {
  getImportableTemplates,
  importTemplates,
  setImportTemplatesDialogOpen,
  showUpgradeDialog,
} from 'state/actions';
import { useAsyncAction, useLazyLoading } from 'state/hooks';
import { connect } from 'react-redux';
import ImportTemplatesForm from './ImportTemplatesForm';
import { List, ListItem, Typography, styled } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { AlertTitle } from '@material-ui/lab';
import { useTracking } from 'components/Contexts/TrackingContext';

const PaddedAlert = styled(Alert)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const ImportTemplatesDialog = ({
  open,
  templates,
  templateLimit,
  templateCount,
  setImportTemplatesDialogOpen,
  showUpgradeDialog,
}) => {
  const { logEvent } = useTracking();
  const [importSuccess, setImportSuccess] = useState(false);
  const [importedTemplates, setImportedTemplates] = useState([]);
  const { loading, error } = useAsyncAction({
    action: getImportableTemplates,
  });

  const [
    getUpdatedImportableTemplates,
    { loading: updatingImportableTemplates },
  ] = useLazyLoading({
    action: getImportableTemplates,
  });

  const [importSelected, { loading: importing }] = useLazyLoading({
    action: importTemplates,
    onSuccess: (result) => {
      logEvent({ category: 'template', action: 'imported' });
      getUpdatedImportableTemplates();
      setImportedTemplates(result);
      setImportSuccess(true);
    },
  });

  const handleClose = () => {
    setImportTemplatesDialogOpen(false);
  };

  const DialogContent = () => {
    if (templateCount >= templateLimit) {
      return (
        <Fragment>
          <Box mb={2}>
            <Alert severity="warning">
              <AlertTitle>Plan Limit Exceeded</AlertTitle>
              You have exceeded your template limit. Upgrade your current plan
              to import additional templates from AWS.
            </Alert>
          </Box>
          <AsyncDialogActions
            onClose={handleClose}
            loading={false}
            submitText={'Upgrade'}
            onSubmit={() => showUpgradeDialog(true)}
          />
        </Fragment>
      );
    }

    if (error) return <div>{error}</div>;

    if (loading || updatingImportableTemplates) return <LogoLoader />;

    if (templates.length === 0) {
      return (
        <Fragment>
          <Box mb={2}>
            <Alert severity="info">
              <AlertTitle>No more templates to import</AlertTitle>
              All your templates were imported already. If you want to add
              additional templates, create a new one.
            </Alert>
          </Box>
          <AsyncDialogActions
            onClose={handleClose}
            loading={false}
            secondaryText="Close"
          />
        </Fragment>
      );
    }

    if (!templates)
      return (
        <Typography variant="body2">
          Error getting your existing templates
        </Typography>
      );

    if (!importSuccess) {
      return (
        <ImportTemplatesForm onSubmit={importSelected} loading={importing} />
      );
    }

    return (
      <Fragment>
        <Typography>
          We successfully imported the following templates
        </Typography>
        <List>
          {importedTemplates.map((template) => (
            <ListItem key={template.uid}>
              <Typography variant="h5">{template.templateName}</Typography>
            </ListItem>
          ))}
        </List>
        <PaddedAlert severity="info">
          <AlertTitle>About SES imported templates</AlertTitle>
          If you delete imported templates, they will also be deleted in SES.
          <br />
          Imported templates can only be edited via html. However you can
          quickly extract existing content and replicate blocks with the editor.
        </PaddedAlert>
        <AsyncDialogActions
          loading={false}
          onSubmit={handleClose}
          submitText={'Ok, got it!'}
          onClose={() => setImportSuccess(false)}
          secondaryText={'Import more'}
        />
      </Fragment>
    );
  };

  return (
    <DialogFormFrame
      title={'Import templates from SES'}
      open={open}
      maxWidth="md"
      onClose={handleClose}
      formComponent={<DialogContent />}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    open: state.layout.importTemplatesDialogOpen,
    templates: state.templates.importableTemplates,
    templateLimit: state.company.subscription?.templateLimit,
    templateCount: state.company.subscription?.templateCount,
  };
};

export const mapDispatchToProps = {
  setImportTemplatesDialogOpen,
  showUpgradeDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportTemplatesDialog);
