import { Button, Card, ListItemText, styled } from '@material-ui/core';

export const WarningButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.text.main,

  '&:hover': {
    backgroundColor: theme.palette.warning.light,
  },
}));

export const StyledListItem = styled(ListItemText)(
  ({ theme, width, textalign, pointer }) => ({
    width: `${width}%`,
    color: theme.palette.text.main,
    textAlign: textalign ? textalign : 'unset',
    cursor: pointer ? 'pointer' : 'unset',
  })
);

export const Container = styled(Card)(({ theme }) => ({
  margin: theme.spacing(2, 0, 0, 0),
  padding: theme.spacing(2),
  position: 'relative',
}));
