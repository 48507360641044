import React from 'react';
import PropTypes from 'prop-types';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid } from '@material-ui/core';
import { FieldWrapper, LoadingButton } from 'components/Base';
import * as Yup from 'yup';

const CreateSchema = Yup.object().shape({
  email: Yup.string().required('This field is required'),
});

const VerifyEmailAddressForm = ({ onSubmit, mail, loading }) => {
  return (
    <Formik
      initialValues={{
        email: mail ? mail : '',
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={CreateSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldWrapper
                label="Email Address"
                subtitle="AWS will send an Email to this address with a link to verify it."
              >
                <Field
                  component={TextField}
                  fullWidth
                  name="email"
                  variant="outlined"
                  disabled={!!mail}
                />
              </FieldWrapper>
            </Grid>
            <Grid item container justifyContent={'flex-end'}>
              <Grid item>
                <LoadingButton loading={isSubmitting || loading} type="submit">
                  {mail ? 'Resend Link' : 'Verify Email'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

VerifyEmailAddressForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default VerifyEmailAddressForm;
