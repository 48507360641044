import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import Drawer from './Drawer';
import AppBar from './AppBar';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'consts/defaults';

const Root = styled('div')({
  display: 'flex',
});

const Main = styled('main')(({ theme, nopadding, renderappbar }) => ({
  position: 'relative',
  minHeight: renderappbar
    ? `calc(100vh - ${theme.custom.appBarHeight}px)`
    : '100vh',
  flexGrow: 1,
  padding: nopadding ? '0px' : theme.spacing(3),
  marginTop: renderappbar ? theme.custom.appBarHeight : '0px',
  backgroundColor: theme.palette.background.main,
  overflowY: 'hidden',
}));

const Layout = ({
  children,
  nopadding,
  title,
  renderappbar = true,
  renderDrawer = true,
}) => {
  return (
    <Root>
      <Helmet>
        <title>
          {title} - {APP_NAME}{' '}
        </title>
      </Helmet>
      {renderappbar && <AppBar title={title} />}
      {renderDrawer && <Drawer />}
      <Main renderappbar={renderappbar ? 1 : 0} nopadding={nopadding}>
        {children}
      </Main>
    </Root>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

export default Layout;
