import React from 'react';
import { FieldWrapper } from 'components/Base';
import { FastField as Field, FieldArray, Form, Formik } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  styled,
  Typography,
} from '@material-ui/core';
import { usePlaceholders } from 'components/Contexts';
import { PlusCircle } from '../../../../assets/icons';

const StyledDivider = styled(Divider)({
  margin: '8px 0px',
  height: '3px',
  backgroundColor: 'white',
});

const StyledMiddleDivider = styled(StyledDivider)({
  margin: '12px 32px',
});

const SimpleVariableView = ({ formikRef }) => {
  const {
    handleInterpolation,
    displayedPlaceholderObject,
    flattenPlaceholerObject,
    sortFlatArray,
    initFieldsInArray,
  } = usePlaceholders();
  const flattenedArray = flattenPlaceholerObject(displayedPlaceholderObject);
  const sortedArray = sortFlatArray(flattenedArray);

  const createSchema = () => {
    // Extract Yup schema from flattened placeholder array
    let schema = {};
    if (flattenedArray.length > 0) {
      schema = initFieldsInArray(flattenedArray, schema);
    }
    return Yup.object().shape(schema);
  };

  const validateData = (data) => {
    if (data.includes('<script')) {
      return 'String not allowed. Please remove script tags.';
    }
  };

  const initFormik = () => {
    // Extract initial formik values from flattened placeholder array
    let initObj = {};
    if (flattenedArray.length > 0) {
      flattenedArray.forEach((placeholder) => {
        const value = Object.values(placeholder)[0];
        const name = Object.keys(placeholder)[0];
        initObj[name] = value;
      });
    }
    return initObj;
  };

  if (flattenedArray.length === 0) {
    return <></>;
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initFormik()}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={createSchema()}
      onSubmit={(values, { setSubmitting }) => {
        handleInterpolation(values);
        setSubmitting(false);
      }}
      render={({ values }) => {
        return (
          <Form>
            <Grid container spacing={1}>
              {sortedArray.simple.length > 0 && (
                <Grid item xs={12}>
                  <Box pt={2}>
                    <Typography variant="h5">Simple Variables</Typography>
                    <StyledDivider />
                  </Box>
                </Grid>
              )}
              {sortedArray.simple.length > 0 &&
                sortedArray.simple.map((item, index) => {
                  const nameOfPlaceholderField = Object.keys(item)[0];
                  return (
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      key={`${nameOfPlaceholderField}${index}`}
                    >
                      <FieldWrapper
                        label={nameOfPlaceholderField}
                        addBottomPadding
                      >
                        <Field
                          component={TextField}
                          id="filled-basic"
                          variant="filled"
                          fullWidth
                          InputProps={{ disableUnderline: true }}
                          validate={validateData}
                          key={nameOfPlaceholderField}
                          name={nameOfPlaceholderField}
                        />
                      </FieldWrapper>
                    </Grid>
                  );
                })}
              {sortedArray.nested.length > 0 && (
                <Grid item xs={12}>
                  <Box pt={2}>
                    <Typography variant="h5">Nested variables</Typography>
                    <StyledDivider />
                  </Box>
                </Grid>
              )}
              {sortedArray.nested.length > 0 &&
                sortedArray.nested.map((item, indexOfParent) => {
                  const nameOfPlaceholderField = Object.keys(item)[0];
                  const valueOfPlaceholderField = Object.values(item)[0];
                  return (
                    <>
                      <Grid
                        item
                        xs={12}
                        key={`${indexOfParent}.${nameOfPlaceholderField}`}
                      >
                        <Box pb={0.5}>
                          <Typography
                            variant="h6"
                            style={{
                              paddingBottom: '4px',
                              fontSize: 14,
                              fontWeight: 700,
                              lineHeight: 1,
                            }}
                          >
                            {nameOfPlaceholderField}
                          </Typography>
                        </Box>
                      </Grid>
                      {Object.keys(valueOfPlaceholderField).map(
                        (childName, index) => {
                          const childValue = valueOfPlaceholderField[childName];
                          if (typeof childValue === 'string') {
                            return (
                              <Grid
                                item
                                xs={12}
                                lg={12}
                                key={`${nameOfPlaceholderField}.${childName}`}
                              >
                                <FieldWrapper
                                  label={childName}
                                  addBottomPadding
                                >
                                  <Field
                                    component={TextField}
                                    id="filled-basic"
                                    variant="filled"
                                    InputProps={{ disableUnderline: true }}
                                    fullWidth
                                    validate={validateData}
                                    key={`${nameOfPlaceholderField}.${childName}`}
                                    name={`${nameOfPlaceholderField}.${childName}`}
                                  />
                                </FieldWrapper>
                              </Grid>
                            );
                          } else if (typeof childValue === 'boolean') {
                            return (
                              <>
                                <Grid
                                  item
                                  xs={9}
                                  lg={9}
                                  key={`${nameOfPlaceholderField}.${childName}`}
                                >
                                  <Typography
                                    variant="h6"
                                    style={{
                                      paddingTop: '10px',
                                      fontSize: 14,
                                      fontWeight: 700,
                                      lineHeight: 1,
                                    }}
                                  >
                                    {nameOfPlaceholderField}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  lg={3}
                                  key={`${nameOfPlaceholderField}${childName}-switch`}
                                >
                                  <Field
                                    key={`${nameOfPlaceholderField}.${childName}`}
                                    name={`${nameOfPlaceholderField}.${childName}`}
                                    component={Switch}
                                  />
                                </Grid>
                              </>
                            );
                          } else {
                            return <></>;
                          }
                        }
                      )}
                    </>
                  );
                })}
              {sortedArray.boolean.length > 0 && (
                <Grid item xs={12}>
                  <Box pt={2}>
                    <Typography variant="h5">Boolean Conditions</Typography>
                    <StyledDivider />
                  </Box>
                </Grid>
              )}
              {sortedArray.boolean.length > 0 &&
                sortedArray.boolean.map((item, index) => {
                  const nameOfPlaceholderField = Object.keys(item)[0];
                  return (
                    <>
                      <Grid
                        item
                        xs={9}
                        lg={9}
                        key={`${nameOfPlaceholderField}${index}`}
                      >
                        <Typography
                          variant="h6"
                          style={{
                            paddingTop: '12px',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: 1,
                          }}
                        >
                          {nameOfPlaceholderField}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        lg={3}
                        key={`${nameOfPlaceholderField}${index}-switch`}
                      >
                        <Field
                          label={nameOfPlaceholderField}
                          name={nameOfPlaceholderField}
                          component={Switch}
                        />
                      </Grid>
                    </>
                  );
                })}
              {sortedArray.loop.length > 0 && (
                <Grid item xs={12}>
                  <Box pt={2}>
                    <Typography variant="h5">Lists</Typography>
                    <StyledDivider />
                  </Box>
                </Grid>
              )}
              {sortedArray.loop.length > 0 &&
                sortedArray.loop.map((item, index) => {
                  const nameOfPlaceholderField = Object.keys(item)[0];
                  const valueOfPlaceholderField = Object.values(item)[0];
                  return (
                    <Grid item xs={12} key={`${nameOfPlaceholderField}`}>
                      <FieldArray
                        name={nameOfPlaceholderField}
                        render={(arrayHelpers) => (
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              lg={12}
                              key={`${nameOfPlaceholderField}${index}`}
                            >
                              <Box
                                display="flex"
                                flex-direction="column"
                                justifyContent="space-between"
                                width="100%"
                                pb={1}
                              >
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 700,
                                    lineHeight: 1,
                                  }}
                                >
                                  {nameOfPlaceholderField}
                                </Typography>
                                <IconButton
                                  type="button"
                                  className="secondary"
                                  onClick={() => {
                                    arrayHelpers.push(
                                      valueOfPlaceholderField[0]
                                    );
                                  }}
                                  style={{
                                    padding: '0 8px 0 0',
                                    marginTop: '-4px',
                                  }}
                                >
                                  <PlusCircle />
                                </IconButton>
                              </Box>
                            </Grid>
                            {values[nameOfPlaceholderField].length > 0 &&
                              values[nameOfPlaceholderField].map(
                                (item, index) => {
                                  return Object.keys(item).map(
                                    (childName, childIndex) => {
                                      const childValue = item[childName];
                                      if (typeof childValue === 'string') {
                                        return (
                                          <Grid
                                            item
                                            xs={12}
                                            lg={12}
                                            key={`${nameOfPlaceholderField}${index}${childName}`}
                                          >
                                            <Box pt={0.5}>
                                              <FieldWrapper
                                                label={childName}
                                                addBottomPadding
                                              >
                                                <Field
                                                  component={TextField}
                                                  id="filled-basic"
                                                  variant="filled"
                                                  InputProps={{
                                                    disableUnderline: true,
                                                  }}
                                                  fullWidth
                                                  validate={validateData}
                                                  key={`${nameOfPlaceholderField}[${index}][${childName}]`}
                                                  name={`${nameOfPlaceholderField}[${index}][${childName}]`}
                                                />
                                              </FieldWrapper>
                                            </Box>
                                            {childIndex ===
                                              Object.keys(item).length - 1 && (
                                              <StyledMiddleDivider />
                                            )}
                                          </Grid>
                                        );
                                      } else if (
                                        typeof childValue === 'boolean'
                                      ) {
                                        return (
                                          <Box pt={0.5}>
                                            <Grid
                                              item
                                              xs={9}
                                              lg={9}
                                              key={`${nameOfPlaceholderField}${childName}${index}`}
                                            >
                                              <Typography
                                                variant="h6"
                                                style={{
                                                  paddingTop: '10px',
                                                  fontSize: 14,
                                                  fontWeight: 700,
                                                  lineHeight: 1,
                                                }}
                                              >
                                                {nameOfPlaceholderField}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              lg={3}
                                              key={`${nameOfPlaceholderField}${index}${childName}-switch`}
                                            >
                                              <Field
                                                key={`${nameOfPlaceholderField}[${index}][${childName}]`}
                                                name={`${nameOfPlaceholderField}[${index}][${childName}]`}
                                                component={Switch}
                                              />
                                            </Grid>
                                            {childIndex ===
                                              Object.keys(item).length - 1 && (
                                              <StyledMiddleDivider />
                                            )}
                                          </Box>
                                        );
                                      } else {
                                        return <></>;
                                      }
                                    }
                                  );
                                }
                              )}
                          </Grid>
                        )}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Form>
        );
      }}
    />
  );
};
export default SimpleVariableView;
