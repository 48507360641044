import React, { Fragment } from 'react';
import { PasswordResetForm } from 'components/Auth';
import { Container } from 'components/Menu/CompanySettings/Shared/components/styles';
import { SettingsHeader } from 'components/Base';

const Security = () => {
  return (
    <Fragment>
      <SettingsHeader title={'Password Settings'} />
      <Container>
        <PasswordResetForm />
      </Container>
    </Fragment>
  );
};

export default Security;
