import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  Layout,
  SettingsContainer,
  SettingsTopNavigation,
} from 'components/Structure';
import { userSettingsMenu } from 'components/Menu/UserSettings/consts';
import {
  DataPrivacy,
  Details,
  Security,
  TermsOfUse,
} from 'components/Menu/UserSettings';
import {
  userPrivacy,
  userSecurity,
  userSettings,
  userTerms,
} from 'consts/routes';
import Box from '@material-ui/core/Box';

const UserSettings = () => {
  const title = 'User Settings';

  return (
    <Layout title={title} nopadding={1}>
      <SettingsTopNavigation tabs={userSettingsMenu} />
      <SettingsContainer>
        <Box p={3}>
          <Switch>
            <Route exact path={userSettings} component={Details} />
            <Route exact path={userSecurity} component={Security} />
          <Route exact path={userPrivacy} component={DataPrivacy} />
          <Route exact path={userTerms} component={TermsOfUse} />
        </Switch></Box>
      </SettingsContainer>
    </Layout>
  );
};

export default UserSettings;
