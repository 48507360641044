import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthLayout, EmailInput } from 'components/Auth';
import { sendResetMail } from 'state/actions/auth';
import { Grid } from '@material-ui/core';
import { authConstants } from 'consts';
import AuthHeadline from 'components/Auth/AuthHeadline';
import { useLazyLoading } from 'state/hooks';
import { StyledLink } from '../components/Auth/styles';

const PasswordForgot = () => {
  const history = useHistory();

  const [mailSent, setMailSent] = useState(false);
  const [userMail, setUserMail] = useState(null);

  const [handleResetMail, { loading }] = useLazyLoading({
    action: sendResetMail,
    onSuccess: () => {
      setMailSent(true);
    },
  });
  const routeLogin = () => {
    setUserMail(null);
    setMailSent(false);
    history.push(authConstants.login.route);
  };

  const handleSubmit = ({ email }) => {
    setUserMail(email);
    handleResetMail(email);
  };

  return (
    <AuthLayout title="Forgot">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AuthHeadline
            title="Forgot password"
            subTitle="Enter your email below."
          />
        </Grid>
        <Grid item xs={12}>
          {!mailSent ? (
            <EmailInput onSubmit={handleSubmit} loading={loading} />
          ) : (
            <Grid item>
              <p style={{ color: 'white' }}>
                We sent a mail to {userMail}. Use the link in the mail to reset
                your password and sign in with your new one.{' '}
              </p>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <StyledLink onClick={routeLogin}>
            <b>Back to login</b>
          </StyledLink>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default PasswordForgot;
