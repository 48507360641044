import * as Yup from 'yup';
import dompurify from 'dompurify';
import Handlebars from 'handlebars';
import Barhandles from 'barhandles';

function transformStructure(input) {
  // Base case: If the input is not an object or array, return it directly.
  if (typeof input !== 'object' || input === null) {
    return input;
  }

  // Process arrays
  if (Array.isArray(input)) {
    return input.map((item) => transformStructure(item));
  }

  // Process objects
  const output = {};
  Object.keys(input).forEach((key) => {
    // Skip "_optional" and "_type" keys
    if (key === '_optional' || key === '_type') {
      return;
    }

    const value = input[key];
    if (value._type === 'array') {
      // For arrays, check if the items are optional and of type "any", initialize as an array with an empty string
      // This is for a list of strings
      if (value['#'] && value['#']._optional && value['#']._type === 'any') {
        output[key] = [''];
      } else if (value['#'] && value['#']._type === 'object') {
        // This is for a list of objects
        output[key] = [transformStructure(value['#'])];
      } else {
        // This is for a list of any other type
        output[key] = [];
      }
    } else if (value._type === 'object') {
      // For objects, recursively transform the structure
      output[key] = transformStructure(value);
    } else {
      // For "_type": "any"
      // Initialize boolean conditions as false and other placeholders as empty strings
      output[key] = value._optional ? false : '';
    }
  });
  return output;
}

function correctBooleanValues(templateString, structure) {
  function correctValues(currentPath, obj) {
    Object.keys(obj).forEach((key) => {
      let value = obj[key];
      let newPath = currentPath ? `${currentPath}.${key}` : key;
      // If the value is boolean, check its occurrence in the template
      if (typeof value === 'boolean') {
        const regexPattern = new RegExp(
          `{{\\s*${newPath.replace(/\./g, '\\.')}\\s*}}`,
          'g'
        );
        // If found in the template, assume it's meant to be a string
        if (templateString.match(regexPattern)) {
          obj[key] = '';
        }
      } else if (value._type === 'object' && value !== null) {
        // If it's an object (or array), recurse
        correctValues(newPath, value);
      } else if (Array.isArray(value)) {
        // If it's an array, recurse for each item
        value.forEach((item) => {
          correctValues('', item);
        });
      } else if (typeof value === 'object') {
        // If it's an object (or array), recurse
        correctValues(newPath, value);
      }
    });
  }
  correctValues('', structure);
  return structure;
}

export const extractHandlebarsPlaceholdersFromTemplate = (originalTemplate) => {
  // concat subject, html, and (optionally) preheaderText to one long string
  let text = originalTemplate.subject + originalTemplate.html;
  if (originalTemplate.design) {
    let design = JSON.parse(originalTemplate.design);
    if (design.body && design.body.values && design.body.values.preheaderText) {
      text += design.body.values.preheaderText;
    }
  }

  let variables = Barhandles.extractSchema(text.replace('{{else}}', ''));
  variables = transformStructure(variables);
  return correctBooleanValues(text, variables);
};

// Check if child is array or object
function isComplex(child) {
  return Array.isArray(child) || typeof child === 'object';
}

// Check if any child is array or object
export const hasComplexContent = (child) => {
  if (child === null || child === undefined) {
    return false;
  }
  return Object.values(child).some(isComplex);
};

// test if array is to deep to display in simple view
export const isDeepObject = (obj) => {
  let isDeepObject = false;

  Object.keys(obj).forEach((key) => {
    const childItem = obj[key];

    if (
      (Array.isArray(childItem) && childItem.some(hasComplexContent)) ||
      (typeof childItem === 'object' && Object.keys(childItem).some(isComplex))
    ) {
      isDeepObject = true;
    }
  });
  return isDeepObject;
};

export const flattenPlaceholerObject = (obj) => {
  // Convert object to array of objects
  if (obj) {
    let array = [];
    Object.keys(obj).forEach((key) => {
      let subObj = {};
      subObj[key] = obj[key];
      array.push(subObj);
    });
    return array;
  }
  return [];
};

export const sortFlatArray = (array) => {
  const sortedByTypes = {
    simple: [],
    boolean: [],
    nested: [],
    loop: [],
  };

  array.forEach((item) => {
    if (typeof item[Object.keys(item)[0]] === 'string') {
      sortedByTypes.simple.push(item);
    } else if (typeof item[Object.keys(item)[0]] === 'boolean') {
      sortedByTypes.boolean.push(item);
    } else if (Array.isArray(item[Object.keys(item)[0]])) {
      sortedByTypes.loop.push(item);
    } else if (typeof item[Object.keys(item)[0]] === 'object') {
      sortedByTypes.nested.push(item);
    }
  });

  return sortedByTypes;
};

export const initFieldsInArray = (array, schema) => {
  array.forEach((placeholder) => {
    const value = Object.values(placeholder)[0];
    const name = Object.keys(placeholder)[0];
    let schemaObj;
    if (typeof value === 'string') {
      schemaObj = Yup.string().required(`${name} is required`);
    } else if (typeof value === 'boolean') {
      schemaObj = Yup.boolean().required(`${name} is required`);
    } else if (Array.isArray(value) && value.length > 0) {
      // Construct child schema
      const firstItem = value[0];
      const childSchema = {};
      Object.keys(firstItem).forEach((fieldName) => {
        if (typeof firstItem[fieldName] === 'string') {
          childSchema[fieldName] = Yup.string().required(
            `${fieldName} is required`
          );
        } else if (typeof firstItem[fieldName] === 'boolean') {
          childSchema[fieldName] = Yup.boolean().required(
            `${fieldName} is required`
          );
        }
      });
      schemaObj = Yup.array()
        .of(Yup.object().shape(childSchema))
        .min(1, 'Minimum of one item is required');
    } else if (typeof value === 'object') {
      // Construct child schema for nested object
      const childSchema = {};

      Object.keys(value).forEach((fieldName) => {
        if (typeof value[fieldName] === 'string') {
          childSchema[fieldName] = Yup.string().required(
            `${fieldName} is required`
          );
        } else if (typeof value[fieldName] === 'boolean') {
          childSchema[fieldName] = Yup.boolean().required(
            `${fieldName} is required`
          );
        }
      });
      schemaObj = Yup.object().shape(childSchema);
    }
    schema[name] = schemaObj;
  });
  return schema;
};

// Use dompurify to sanitize the data
export const cleanStringData = (data) => {
  return dompurify.sanitize(data);
};

// Do interpolation using handlebars
export const interpolateSingleString = (variables, content) => {
  let template = Handlebars.compile(content);
  return template(variables);
};
