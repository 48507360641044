import React, { Fragment } from 'react';
import { Container } from 'components/Menu/CompanySettings/Shared/components/styles';
import { terms } from 'components/Legal';

const TermsOfUse = () => {
  return (
    <Fragment>
      <Container>
        <div dangerouslySetInnerHTML={{ __html: terms }}></div>
      </Container>
    </Fragment>
  );
};

export default TermsOfUse;
