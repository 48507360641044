import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@material-ui/core';

const FadeMount = ({ children }) => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);

    return () => setFadeIn(false);
  }, []); //eslint-disable-line

  return <Fade in={fadeIn}>{children}</Fade>;
};

FadeMount.propTypes = {
  children: PropTypes.node,
};

export default FadeMount;
