import React, { useEffect, useRef } from 'react';
import { FastField as Field, Form, Formik } from 'formik';
import { Box, Grid, useTheme } from '@material-ui/core';
import { usePlaceholders } from 'components/Contexts';

const JSONVariableView = ({ formikRef }) => {
  const theme = useTheme();
  const { displayedPlaceholderObject, handleInterpolation } = usePlaceholders();
  const textAreaRef = useRef(null);

  useEffect(() => {
    const adjustHeight = () => {
      const element = textAreaRef.current;
      if (element) {
        element.style.height = 'auto'; // Reset height to recalculate
        element.style.height = `${element.scrollHeight}px`; // Adjust height based on scroll height
      }
    };

    // Adjust height on mount and whenever the displayedPlaceholderObject changes
    adjustHeight();
  }, [displayedPlaceholderObject]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        jsonStructure: JSON.stringify(displayedPlaceholderObject, null, 2),
      }}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, { setSubmitting }) => {
        handleInterpolation(values);
        setSubmitting(false);
      }}
    >
      {({ values }) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item>
              <Box pt={2}>
                <Field
                  innerRef={textAreaRef}
                  as="textarea"
                  id="outlined-basic"
                  name="jsonStructure"
                  style={{
                    resize: 'none',
                    border: 'none',
                    borderRadius: '8px',
                    width: '325px',
                    minHeight: '100px',
                    maxHeight: '100%',
                    padding: theme.spacing(0.5),
                  }}
                  onInput={() => {
                    const element = textAreaRef.current;
                    if (element) {
                      element.style.height = 'auto'; // Reset height to recalculate
                      element.style.height = `${element.scrollHeight}px`; // Adjust height based on scroll height
                    }
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default JSONVariableView;
