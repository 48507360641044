import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Card, Grid, List, ListItem } from '@material-ui/core';
import * as Yup from 'yup';
import { FieldWrapper, LoadingButton } from 'components/Base';
import PasswordInputField from 'components/Auth/PasswordInputField';
import { updatePassword } from 'state/actions/auth';
import { connect } from 'react-redux';

const Schema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, 'Please enter at least 8 characters')
    .required('This field is required')
    .default(''),
  newPassword: Yup.string()
    .min(8, 'Please enter at least 8 characters')
    .required('This field is required')
    .default(''),
});

const PasswordResetForm = ({ updatePassword }) => {
  const [loading, setLoading] = useState(false);

  const handleUpdatePassword = async (values) => {
    setLoading(true);
    await updatePassword(values);
    setLoading(false);
  };

  return (
    <Formik
      initialValues={Schema.default()}
      validateOnBlur={false}
      validationSchema={Schema}
      onSubmit={(values) => handleUpdatePassword(values)}
    >
      {() => (
        <Form>
          <Grid container spacing={3}>
            <Grid item container lg={8} md={8} sm={8} xs={8} direction="row">
              <Grid item xs={12}>
                <FieldWrapper
                  label={'Old Password'}
                  subtitle="Please enter your old password so we know its you."
                >
                  <Field
                    fullWidth
                    name="oldPassword"
                    className="form-control rounded-0"
                    component={PasswordInputField}
                  />
                </FieldWrapper>
              </Grid>
              <Grid item xs={12}>
                <FieldWrapper
                  label={'New Password'}
                  subtitle="Please create a new password corresponding to our Password rules"
                >
                  <Field
                    fullWidth
                    name="newPassword"
                    className="form-control rounded-0"
                    component={PasswordInputField}
                  />
                </FieldWrapper>
              </Grid>
            </Grid>
            <Grid
              item
              style={{ marginTop: '56px' }}
              lg={4}
              md={4}
              sm={4}
              xs={4}
            >
              <Card>
                <List>
                  <ListItem>Minimum of 8 characters:</ListItem>
                  <ListItem>at lease 1 number</ListItem>
                  <ListItem>one special character</ListItem>
                  <ListItem>one lowercase and uppercase letter</ListItem>
                </List>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                variant="contained"
                color="primary"
                type="submit"
              >
                Update Password
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export const mapDispatchToProps = {
  updatePassword,
};

export default connect(null, mapDispatchToProps)(PasswordResetForm);
