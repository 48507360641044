import React, { Fragment } from 'react';
import { Container } from 'components/Menu/CompanySettings/Shared/components/styles';
import { privacyPolicy } from 'components/Legal';

const DataPrivacy = () => {
  return (
    <Fragment>
      <Container>
        <div dangerouslySetInnerHTML={{ __html: privacyPolicy }}></div>
      </Container>
    </Fragment>
  );
};

export default DataPrivacy;
