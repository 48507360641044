import React, { Fragment, useState, useEffect } from 'react';
import { Formik } from 'formik';
import awsCredentialsSchema from 'consts/schemas/awsCredentialsSchema';
import { FormControlBar } from 'components/WelcomeSlides';
import { Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Loader } from 'components/Base';
import aws from 'assets/img/auth/register.png';
import { PageContainer, TextContainer, Headline } from './styles';
import { useWelcomeSlides } from 'components/WelcomeSlides/hooks';
import { connect } from 'react-redux';
import { styled } from '@material-ui/core/styles';
import AwsCredentialsFields from '../Forms/AwsCredentialsFields';
import { useLazyLoading } from '../../../state/hooks';
import {
  addAwsCredentials,
  evaluateAwsPermissions,
} from '../../../state/actions';
import { useConversions, useTracking } from '../../Contexts';
import { isCompanyEmail } from 'components/utils';
import { googleAdsConversionTypes } from 'consts';

const AwsSesImage = styled('img')(({ theme }) => ({
  width: '300px !important',
  marginLeft: '-24px',
  marginTop: '16px',
}));

const AwsCredentials = ({ active, company, user }) => {
  const { nextSlide } = useWelcomeSlides();
  const { logEvent } = useTracking();
  const { sendConversion } = useConversions();
  const [accountAlias, setAccountAlias] = useState(null);
  const [receivedAccountData, setReceivedAccountData] = useState(false);

  const [handleEvaluatePermissions] = useLazyLoading({
    action: evaluateAwsPermissions,
  });

  useEffect(() => {
    if (receivedAccountData) {
      const account = receivedAccountData.find(
        (account) => account.alias === accountAlias
      );
      if (account) {
        handleEvaluatePermissions(account.id, true);
        setAccountAlias(null);
        setReceivedAccountData(null);
      }
    }
  }, [accountAlias, receivedAccountData, handleEvaluatePermissions]);

  const [handleSubmit, { loading }] = useLazyLoading({
    action: addAwsCredentials,
    onSuccess: (data) => {
      logEvent({ category: 'aws_credentials', action: 'added' });
      if (user?.gclid) {
        sendConversion(
          user.gclid,
          isCompanyEmail(user.email)
            ? googleAdsConversionTypes.AWS_CONNECTED_COMPANY_SUBSCRIBER
            : googleAdsConversionTypes.AWS_CONNECTED_SUBSCRIBER
        );
      }
      setReceivedAccountData(data);
      nextSlide();
    },
  });

  const handleSubmitAwsCredentials = async (values) => {
    setAccountAlias(values.alias);
    await handleSubmit(values);
  };

  if (!company && loading) return <Loader />;

  return (
    <Fragment>
      <Formik
        initialValues={{
          alias: '',
          awsRegion: '',
        }}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={awsCredentialsSchema}
        onSubmit={handleSubmitAwsCredentials}
      >
        {({ isSubmitting, submitForm }) => (
          <Fragment>
            <PageContainer>
              <TextContainer width={35}>
                <Headline>Connect your AWS SES Account</Headline>
                <Fade in={active} timeout={500}>
                  <AwsSesImage alt="aws ses functionalities" src={aws} />
                </Fade>
              </TextContainer>

              <TextContainer width={65}>
                <AwsCredentialsFields />
              </TextContainer>
            </PageContainer>
            <FormControlBar
              onSubmit={submitForm}
              allowSkip={true}
              isSubmitting={isSubmitting || loading}
            />
          </Fragment>
        )}
      </Formik>
    </Fragment>
  );
};

AwsCredentials.propTypes = {
  active: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    company: state.company.details,
    user: state.user,
  };
};

export default connect(mapStateToProps)(AwsCredentials);
