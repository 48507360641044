import React from 'react';
import { terms } from 'components/Legal';
import { useTheme } from '@material-ui/core';
const Terms = () => {
  const theme = useTheme();
  return (
    <div style={{ padding: theme.spacing(3) }}>
      <div dangerouslySetInnerHTML={{ __html: terms }}></div>
    </div>
  );
};
export default Terms;
