import React from 'react';
import { styled, Tooltip } from '@material-ui/core';
import VersionDescription, {
  CreationMessage,
  Timestamp,
} from './VersionDescription';
import { StyledInvertedButton } from 'components/Base';

const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 0, 0, 1),
}));

const LoadMoreContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 0, 5, 3),
  borderLeft: `1px solid ${theme.palette.primary.main}`,
}));

const List = styled('ul')(({ theme }) => ({
  margin: theme.spacing(4, 0),
  padding: 0,
  display: 'inline-block',
}));

const Dot = styled('span')(({ theme, selected, first }) => ({
  position: 'absolute',
  left: selected ? '-11.5px' : '-9px',
  top: '0px',
  content: ' ',
  borderRadius: '100%',
  border: `2px solid ${theme.palette.primary.main}`,
  background: selected
    ? theme.palette.primary.main
    : theme.palette.background.paper,
  height: selected ? '23px' : '18px',
  width: selected ? '23px' : '18px',
  boxShadow: selected ? '1px 1px 10px 5px rgba(0, 0, 0, 0.05)' : 'none',
  cursor: 'pointer',
  transition: 'all 0.2s ease-out',
}));

const ListItem = styled('li')(({ theme }) => ({
  listStyle: 'none',
  margin: 'auto',
  borderLeft: `1px solid ${theme.palette.primary.main}`,
  padding: theme.spacing(0, 0, 5, 3),
  position: 'relative',
  cursor: 'pointer',
  transition: 'padding 0.2s ease-out',

  '&:last-child': {
    borderLeft: 0,
  },
}));

const VersionsSelector = ({
  onLoadMoreVersions,
  onSelectVersion,
  versions,
  selectedVersionNumber,
}) => {
  const { creation, latest: latestVersions, total } = versions;

  return (
    <Container>
      <List>
        {latestVersions?.map((version, index) => {
          const selected =
            version.versionNumber === selectedVersionNumber ? 1 : 0;
          return (
            <ListItem
              key={`${version.uid}-${version.versionNumber}`}
              onClick={() => onSelectVersion(version)}
            >
              <Tooltip title="Click to view this version">
                <Dot selected={selected} first={index === 0 ? 1 : 0} />
              </Tooltip>
              <VersionDescription version={version} selected={selected} />
            </ListItem>
          );
        })}
        {total > latestVersions.length + 1 && (
          <LoadMoreContainer>
            <StyledInvertedButton
              variant="outlined"
              color="primary"
              onClick={onLoadMoreVersions}
            >
              Load more
            </StyledInvertedButton>
          </LoadMoreContainer>
        )}
        <ListItem key="last-version" onClick={() => onSelectVersion(creation)}>
          <Dot
            selected={creation.versionNumber === selectedVersionNumber ? 1 : 0}
          />
          <Timestamp
            selected={creation.versionNumber === selectedVersionNumber ? 1 : 0}
            format="MMM D, YYYY h:mm A"
          >
            {creation.createdTimestamp}
          </Timestamp>
          <CreationMessage
            version={creation}
            selectedVersionNumber={selectedVersionNumber}
          />
        </ListItem>
      </List>
    </Container>
  );
};

export default VersionsSelector;
