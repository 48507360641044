import React, { Fragment } from 'react';
import { Box, useTheme } from '@material-ui/core';
import SendMailDialog from './SendMailDialog';
import { PlaceholderView } from '.';
import { Alert, AlertTitle } from '@material-ui/lab';
import { usePlaceholders } from 'components/Contexts';
import {
  MainAreaContainer,
  SideAreaContainer,
  TitleTypography,
} from '../Versions/TemplateVersions';
import {
  Metadata,
  TemplatesAppBar,
  SubtitleTypography,
  ResponsivePreview,
} from '../Shared';
import { LoadingButton } from 'components/Base';
import { Email } from 'assets/icons';

const TemplatePreview = () => {
  const theme = useTheme();

  const {
    displayedTemplate,
    templateCanBeSend,
    errorLoadingTemplate,
    sendMailViaBackend,
  } = usePlaceholders();
  if (errorLoadingTemplate)
    return (
      <Alert severity="error">
        <AlertTitle title="Template not found for selected account" />
        We could not find your template. You might have switched the AWS Account
        while being in preview mode.
      </Alert>
    );

  return (
    <Fragment>
      <TemplatesAppBar>
        <Box mr={4}>
          <LoadingButton
            onClick={sendMailViaBackend}
            startIcon={<Email size={26} />}
            disabled={!templateCanBeSend()}
          >
            Send
          </LoadingButton>
        </Box>
      </TemplatesAppBar>
      <MainAreaContainer>
        <Metadata template={displayedTemplate} />
        <ResponsivePreview template={displayedTemplate} />
      </MainAreaContainer>
      <SideAreaContainer>
        <Box
          pt={2.8}
          pb={2}
          style={{
            zIndex: 8000,
            position: 'fixed',
            top: `calc(${theme.custom.appBarHeight})`,
            right: theme.spacing(2),
            width: `calc(${theme.custom.sideAreaWidth}px - ${theme.spacing(
              4
            )}px)`,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <TitleTypography variant="h3">Email Personalization</TitleTypography>
          <SubtitleTypography variant="h6">
            Variables that you create in the editor will show up here:
          </SubtitleTypography>
        </Box>
        <Box pt={16}>
          <PlaceholderView />
        </Box>
      </SideAreaContainer>
      <SendMailDialog />
    </Fragment>
  );
};

export default TemplatePreview;
