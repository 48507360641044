import React from 'react';
import { styled } from '@material-ui/core';
import { connect } from 'react-redux';

const StyledAvatar = styled('div')(({ theme, location }) => ({
  borderRadius: location === 'menu' ? '18px' : '20px',
  backgroundColor:
    location === 'menu'
      ? theme.palette.secondary.light
      : theme.palette.text.defaultReverted,
  height: location === 'menu' ? '50px' : '80px',
  width: location === 'menu' ? '50px' : '80px',
}));

const StyledLetters = styled('p')(({ theme, location }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  fontWeight: 700,
  margin: location === 'menu' ? theme.spacing(0.75, 0) : theme.spacing(1.5, 2),
  fontSize: location === 'menu' ? '22px' : '40px',
  color:
    location === 'menu'
      ? theme.palette.text.default
      : theme.palette.secondary.main,
}));

const ProfileAvatar = ({ firstName, lastName, location }) => {
  let initials = '?';
  if (firstName && lastName) {
    initials = firstName[0] + lastName[0];
  } else if (firstName) {
    initials = firstName[0];
  } else if (lastName) {
    initials = lastName[0];
  }
  return (
    <StyledAvatar location={location}>
      <StyledLetters location={location}>{initials}</StyledLetters>
    </StyledAvatar>
  );
};

const mapStateToProps = (state) => {
  return {
    firstName: state.user?.firstName,
    lastName: state.user?.lastName,
  };
};

export default connect(mapStateToProps)(ProfileAvatar);
