import React from 'react';
import { styled } from '@material-ui/core/styles';

const StyledBadge = styled('div')(({ theme, maxwidth }) => ({
  color: theme.palette.text.main,
  backgroundColor: theme.palette.background.light,
  minWidth: '60px',
  maxWidth: maxwidth ? `${maxwidth}px` : 'unset',
  textAlign: 'center',
  height: theme.custom.tagHeight,
  margin: theme.spacing(0),
  padding: '4px 8px 8px 8px',
  borderRadius: '4px',
}));

const BadgeText = styled('p')(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  fontSize: '0.875rem',
  fontWeight: 600,
  margin: theme.spacing(0, 1),
  color:
    theme.palette.type === 'dark'
      ? theme.palette.text.default
      : theme.palette.secondary.main,
}));

export const ColoredTag = styled('div')(({ theme, background }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  backgroundColor: background,
  fontSize: '0.875rem',
  color: 'white',
  fontWeight: 600,
  margin: theme.spacing(0.5),
  cursor: 'pointer',
  textAlign: 'center',
  height: theme.custom.tagHeight,
  padding: '4px 8px 8px 8px',
  borderRadius: '4px',
  width: 'fit-content',
}));

const Badge = ({ text, maxWidth }) => {
  return (
    <StyledBadge maxwidth={maxWidth}>
      <BadgeText>{text}</BadgeText>
    </StyledBadge>
  );
};

export default Badge;
