import React, { Fragment, useRef, useState } from 'react';
import {
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
} from '@material-ui/core';
import { MoreHoriz } from 'assets/icons';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,

  '&:last-child': {
    borderBottom: 'none',
  },
}));

const TemplateTableActions = ({ template, actions }) => {
  const [actionsOpen, setActionsOpen] = useState(false);
  const actionAnchorRef = useRef(null);

  return (
    <Fragment>
      <IconButton
        ref={actionAnchorRef}
        aria-label="open additional actions"
        onClick={() => setActionsOpen(true)}
        edge="end"
      >
        <MoreHoriz size={26} />
      </IconButton>
      <Popper
        key={`template-popper-${template.uid}`}
        open={actionsOpen}
        anchorEl={actionAnchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={'bottom-end'}
        popperOptions={{
          modifiers: {
            preventOverflow: {
              padding: 0,
            },
          },
        }}
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper>
              <ClickAwayListener onClickAway={() => setActionsOpen(false)}>
                <MenuList disablePadding>
                  {actions.map(({ label, icon, onClick }) => {
                    return (
                      <StyledMenuItem
                        key={`action-list-${label}-${template.uid}`}
                        onClick={() => onClick(template)}
                      >
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText>{label}</ListItemText>
                        <Divider sx={{ my: 0.5 }} />
                      </StyledMenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default TemplateTableActions;
