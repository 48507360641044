import { styled } from '@material-ui/core/styles';

export const SpacedContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const StyledLink = styled('span')(({ theme }) => ({
  color: theme.palette.text.reverted,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '1.7',
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.text.reverted,
  },
  '&.active': {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.text.reverted,
  },
}));
