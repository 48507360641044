import React from 'react';
import { Field, Form, Formik } from 'formik';
import { InplaceUpdateForm, Loader } from 'components/Base';
import * as Yup from 'yup';

const Schema = Yup.object().shape({
  displayName: Yup.string(),
});

const SenderNameForm = ({ displayName, loading, onSubmit, onAbort }) => {
  return (
    <Formik
      initialValues={{ displayName }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) =>
        isSubmitting || loading ? (
          <Loader />
        ) : (
          <Form>
            <Field
              fullWidth
              name="displayName"
              render={(rest) => (
                <InplaceUpdateForm
                  {...rest}
                  onSubmit={() => onSubmit(values)}
                  onAbort={onAbort}
                />
              )}
            />
          </Form>
        )
      }
    </Formik>
  );
};

SenderNameForm.propTypes = {};

export default SenderNameForm;
