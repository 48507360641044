import React, { Fragment } from 'react';
import { LogoLoader, SettingsHeader } from 'components/Base';
import { UserEditForm } from './Forms';
import { updateUserDetails } from 'state/actions';
import { connect } from 'react-redux';
import { Container } from 'components/Menu/CompanySettings/Shared/components/styles';
import { useLazyLoading } from 'state/hooks';
import SignInProviders from './SignInProviders';

const Details = ({ user }) => {
  const [handleSubmit, { loading }] = useLazyLoading({
    action: updateUserDetails,
  });

  if (!user?.email) {
    return <LogoLoader />;
  }

  return (
    <Fragment>
      <SettingsHeader title={'User Details'} />
      <Container>
        <UserEditForm loading={loading} onSubmit={handleSubmit} user={user} />
      </Container>
      <SignInProviders />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Details);
