import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { signInWithGoogle, signInWithMicrosoft } from 'state/actions/auth';
import { GoogleIcon, MicrosoftIcon } from 'assets/icons';
import SSOBaseButton from './SSOBaseButton';
import { styled } from '@material-ui/core/styles';

const DividerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: theme.spacing(1.5, 0, 1.5, 0),
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Divider = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.text.reverted,
  width: '30%',
  height: '1px',
  borderRadius: '8px',
}));

const StyledDividerText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.reverted,
  fontWeight: 600,
}));

const SSOButtons = () => {
  const providers = [
    {
      label: 'Google',
      provider: 'google',
      logo: GoogleIcon,
      signInMethod: signInWithGoogle,
      display: true,
    },
    {
      label: 'Microsoft',
      provider: 'microsoft',
      logo: MicrosoftIcon,
      signInMethod: signInWithMicrosoft,
      display: true,
    },
  ];

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <DividerContainer>
          <Divider />
          <StyledDividerText variant="body1">OR SIGN IN WITH</StyledDividerText>
          <Divider />
        </DividerContainer>
      </Grid>
      {providers.map(({ label, provider, logo, signInMethod, display }) => {
        if (!display) {
          return <span key={provider}></span>;
        }
        return (
          <Grid item key={provider}>
            <SSOBaseButton
              label={label}
              provider={provider}
              logo={logo}
              signInMethod={signInMethod}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SSOButtons;
