import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { FieldWrapper, LoadingButton } from 'components/Base';
import { styled } from '@material-ui/core/styles';

const Schema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('This field is required'),
});

const StyledLoadingButton = styled(LoadingButton)({
  width: '100%',
});

const EmailInput = ({ onSubmit, loading }) => {
  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validateOnBlur={false}
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FieldWrapper label="Email" inverted variant="small">
                <Field
                  component={TextField}
                  fullWidth
                  placeholder="jon.doe@company.com"
                  name="email"
                  variant="outlined"
                  size="small"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <StyledLoadingButton
                loading={isSubmitting || loading}
                variant="contained"
                color="secondary"
                type="submit"
                className="button-wide"
              >
                Send Mail
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

EmailInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default EmailInput;
