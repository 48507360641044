import React, { useEffect, useState } from 'react';
import { CircularProgress, IconButton, TextField } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ColoredTag } from 'components/Base';
import { useAsyncAction } from 'state/hooks';
import { getTags } from 'state/actions';
import { Save } from 'assets/icons';
import { arraysAreEqual } from '../../utils';
import { Close } from '@material-ui/icons';

const StyledAutocomplete = styled(Autocomplete)({
  width: '100%',
});

const TagSelector = ({ defaultValue, onChange, onSave, saving, onClose }) => {
  const [selectedTags, setSelectedTags] = useState(defaultValue);

  const { data, loading: tagsLoading } = useAsyncAction({
    action: getTags,
  });

  useEffect(() => {
    onChange && onChange(selectedTags);
  }, [selectedTags, onChange]);

  const handleChange = (event, items) => {
    setSelectedTags(items);
  };

  if (!data?.tags || tagsLoading || saving)
    return <CircularProgress size={20} />;

  return (
    <>
      <StyledAutocomplete
        multiple
        id="tags-standard"
        options={data?.tags}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.id === value.id}
        value={selectedTags}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Click to add tags"
          />
        )}
        renderTags={(tagValue) =>
          tagValue.map((option) => (
            <ColoredTag background={option.color} key={option.label}>
              {option.label}
            </ColoredTag>
          ))
        }
      />
      {onSave && !arraysAreEqual(selectedTags, defaultValue) && (
        <IconButton onClick={() => onSave(selectedTags)}>
          <Save size={22} />
        </IconButton>
      )}
      {onSave && arraysAreEqual(selectedTags, defaultValue) && (
        <IconButton onClick={onClose}>
          <Close size={22} />
        </IconButton>
      )}
    </>
  );
};

export default TagSelector;
