import React, { Fragment } from 'react';
import { Zoom, Fade, Box } from '@material-ui/core';
import { TextControlBar } from 'components/WelcomeSlides';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';

import {
  PageContainer,
  TextContainer,
  ImageContainer,
  Headline,
  Text,
} from './styles';
import WelcomeImage from 'assets/img/welcomeSlides/welcome-to-semplates.png';

const StyledImage = styled('img')(({ theme }) => ({
  width: '400px !important',
  paddingRight: theme.spacing(2),
}));

const Welcome = ({ active }) => {
  return (
    <Fragment>
      <PageContainer>
        <Zoom in={active}>
          <TextContainer>
            <Box mt={9}>
              <Headline>Welcome to Semplates</Headline>
              <Text>
                Create new templates or import existing templates from AWS SES
                with just few clicks.
              </Text>
            </Box>
          </TextContainer>
        </Zoom>
        <Fade in={active} timeout={400}>
          <ImageContainer>
            <StyledImage src={WelcomeImage} alt="Welcome to Semplates" />
          </ImageContainer>
        </Fade>
      </PageContainer>
      <TextControlBar />
    </Fragment>
  );
};

Welcome.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default Welcome;
