import React, { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { signOut } from 'state/actions/auth';
import { FirebaseContext } from 'components/Contexts/index';
import { useLazyLoading } from 'state/hooks';

const VersionContext = createContext(null);
const STORAGE_KEY = 'version';

const VersionProvider = ({ children }) => {
  const { authenticated } = useContext(FirebaseContext);
  const avoidBuildIdCheck =
    !authenticated || process.env.NODE_ENV === 'development';
  const [didCheckVersion, setDidCheckVersion] = useState(false);
  const history = useHistory();

  const [logout] = useLazyLoading({
    action: signOut,
    onSuccess: () => history.push('/login'),
  });

  useEffect(() => {
    if (avoidBuildIdCheck) {
      setDidCheckVersion(true);
      return;
    }

    async function checkBuildId() {
      const currentBuildId = process.env.REACT_APP_PIPELINE_ID;
      const prevBuildId = localStorage.getItem(STORAGE_KEY);

      if (prevBuildId !== currentBuildId) {
        localStorage.setItem(STORAGE_KEY, currentBuildId);
        await logout();
      }

      setDidCheckVersion(true);
    }

    checkBuildId();
  }, []); //eslint-disable-line

  if (!didCheckVersion) return null;

  return <VersionContext.Provider>{children}</VersionContext.Provider>;
};

VersionContext.propTypes = {
  children: PropTypes.any,
};

export { VersionProvider, VersionContext };

export default VersionContext;
