const userMenu = [
  {
    label: 'User Details',
    to: '/user',
    checkIsActive: true,
  },
  {
    label: 'Security',
    to: '/user/security',
  },
  {
    label: 'Data Privacy',
    to: '/user/privacy',
  },
  {
    label: 'Terms of Use',
    to: '/user/terms-of-use',
  },
  // {
  //   label: 'Notifications',
  //   to: '/user/notifications',
  // },
];

export default userMenu;
