import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyLoading } from 'state/hooks';
import { changeBulkEmailIdentityVisibility } from 'state/actions';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { StyledDialogTitle } from 'components/Base/DialogFormFrame';
import { LoadingButton } from 'components/Base';

const EmailVisibilityDialog = ({ open, onClose }) => {
  const [selectedButton, setSelectedButton] = useState('');

  const [handleSetVisibility, { loading }] = useLazyLoading({
    action: changeBulkEmailIdentityVisibility,
    onSuccess: () => {
      setSelectedButton('');
      onClose();
    },
  });

  const handleSubmit = (buttonName) => {
    setSelectedButton(buttonName);

    if (buttonName === 'show') {
      handleSetVisibility(true);
    }

    if (buttonName === 'hide') {
      handleSetVisibility(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'sm'}
    >
      <StyledDialogTitle id="form-dialog-title">
        Manage Email Identity Visibility
      </StyledDialogTitle>
      <DialogContent
        dividers
        style={{ position: 'relative', minHeight: '6rem' }}
      >
        Use the buttons below to toggle the visibility of email identities in
        preview mode. This will show or hide email addresses accordingly. If an
        email identity is hidden, you and your colleagues will not be able to
        use it for testing anymore.
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading && selectedButton === 'hide'}
          color={'primary'}
          size={'small'}
          variant={'outlined'}
          onClick={() => handleSubmit('hide')}
        >
          Hide all
        </LoadingButton>
        <LoadingButton
          loading={loading && selectedButton === 'show'}
          color={'primary'}
          size={'small'}
          variant={'contained'}
          onClick={() => handleSubmit('show')}
        >
          Show all
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

EmailVisibilityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EmailVisibilityDialog;
