import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import moment from 'moment';

export const makeAxiosClient = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
  });

  axiosInstance.interceptors.request.use(async (config) => {
    try {
      const user = await firebase.auth().currentUser;
      if (user) {
        const { expirationTime } = user.auth.currentUser.stsTokenManager;
        const currentTimestamp = moment(new Date())
          .add(59, 'm')
          .toDate();
        let token = await user.getIdToken();
        if (new Date(expirationTime * 1000) < currentTimestamp) {
          token = await user.getIdToken(true);
        }
        config.headers['authorization'] = `Bearer ${token}`;
      }
    } catch (e) {
      console.log(e);
    }
    return config;
  });

  return axiosInstance;
};
