import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const SubscriptionCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(0),
  height: theme.spacing(27),
  position: 'relative',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  alignItems: 'right',
  '& .MuiCardHeader': {
    padding: theme.spacing(0),
    display: 'flex',
    flexFlow: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
  '& .MuiCardHeader-title': {
    fontWeight: 400,
    fontSize: theme.spacing(2.25),
    textAlign: 'left',
    color: theme.palette.text.main,
  },
  '& .MuiIconButton-root': {
    padding: theme.spacing(1, 0, 0, 0),
  },
  '& .MuiCardContent-root': {
    display: 'flex',
    flexFlow: 'row',
    flex: 2,
    justifyContent: 'flex-end',
  },
}));

const CardMetric = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: theme.spacing(2.75),
  padding: theme.spacing(0.25),
  textAlign: 'left',
  color: theme.palette.text.main,
}));

const CardMetricThreshold = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.spacing(2.75),
  padding: theme.spacing(0.25),
  textAlign: 'left',
  color: theme.palette.text.main,
}));

const CardInfoDisclaimer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 2, 2, 2),
  position: 'relative',
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-start',
  alignItems: 'right',
  color: theme.palette.text.main,
}));

const CardInfoText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.spacing(1.75),
  padding: theme.spacing(0, 0.5, 0, 0.5),
  textAlign: 'left',
  color: theme.palette.text.main,
}));

const CardIconButton = styled(IconButton)(({ theme }) => ({
  height: '30px',
  width: '30px',
}));

const CardIcon = styled(KeyboardArrowRightIcon)({
  position: 'relative',
  display: 'block',
  left: '50%',
  top: '50%',
  transform: 'translateX(-55%) translateY(-15%)',
});

const SubscriptionsDetailsCard = ({
  metricLabel,
  metricValue,
  metricThreshold,
  metricCaption,
  onClickAction,
}) => {
  const displayedValue = metricValue.toString();

  return (
    <SubscriptionCard>
      <CardHeader
        title={metricLabel}
        action={
          onClickAction && (
            <CardIconButton
              aria-label={'More details on ' + metricLabel}
              onClick={onClickAction}
            >
              <CardIcon />
            </CardIconButton>
          )
        }
      />
      <CardContent>
        <CardMetric>{displayedValue}</CardMetric>
        {metricThreshold && (
          <CardMetricThreshold>{' / ' + metricThreshold}</CardMetricThreshold>
        )}
      </CardContent>

      <CardInfoDisclaimer>
        {metricCaption && <CardInfoText>{metricCaption}</CardInfoText>}
      </CardInfoDisclaimer>
    </SubscriptionCard>
  );
};

SubscriptionsDetailsCard.propTypes = {
  metricLabel: PropTypes.string.isRequired,
  metricValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  metricThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  metricSubtext: PropTypes.string,
};

export default SubscriptionsDetailsCard;
