import React from 'react';

import { AuthLayout, LoginForm } from 'components/Auth';

const Login = () => {
  return (
    <AuthLayout title="Login">
      <LoginForm />
    </AuthLayout>
  );
};

export default Login;
