export const arraysAreEqual = (array1, array2) => {
  if (array1?.length === array2?.length) {
    return array1.every((element, index) => {
      return element === array2[index];
    });
  }

  return false;
};

export const isCompanyEmail = (email) => {
  // Extract everything between '@' and the next dot.
  const match = email.match(/@([a-zA-Z0-9-]+)\./);

  let domain = null;
  if (match) {
    domain = match[1];
  } else {
    return false;
  }
  const personalDomains = [
    'gmail',
    'hotmail',
    'yahoo',
    'googlemail',
    'outlook',
  ];
  return !personalDomains.includes(domain.toLowerCase());
};
