import React, { Fragment } from 'react';
import Moment from 'react-moment';
import { styled, Typography } from '@material-ui/core';

export const Timestamp = styled(Moment)(({ theme, selected }) => ({
  transition: 'all 0.2s ease-out',
  fontSize: selected ? '17px' : '14px',
  fontWeight: 700,
  color: theme.palette.text.main,
}));

export const StyledDescription = styled(Typography)({
  fontSize: '14px',
  transition: 'font-size 0.2s',
});

export const CreationMessage = ({ version, selectedVersionNumber }) => {
  const selected = version.versionNumber === selectedVersionNumber ? 1 : 0;
  let user;
  if (version?.publishedByUser) {
    user = version.publishedByUser.firstName
      ? version.publishedByUser.firstName +
        ' ' +
        version.publishedByUser.lastName[0] +
        '.'
      : version.publishedByUser.email;
  }
  return (
    <>
      <StyledDescription variant="body2" selected={selected}>
        Created {user && ' by ' + user}
      </StyledDescription>
    </>
  );
};

const VersionDescription = ({ version, selected }) => {
  const Description = () => {
    if (version.published && version.publishedByUser?.firstName) {
      return (
        <StyledDescription variant="body2">
          published by {version.publishedByUser.firstName}{' '}
          {version.publishedByUser.lastName[0]}
          {'.'}
        </StyledDescription>
      );
    } else if (version.published && version.publishedByUser?.email) {
      return (
        <StyledDescription variant="body2">
          published by {version.publishedByUserEmail}{' '}
        </StyledDescription>
      );
    } else {
      return (
        <StyledDescription variant="body2">Latest Version</StyledDescription>
      );
    }
  };
  return (
    <Fragment>
      <Timestamp format="MMM D, YYYY h:mm A" selected={selected} utc local>
        {version.published
          ? version.publishedTimestamp
          : version.updatedTimestamp}
      </Timestamp>
      <Description />
    </Fragment>
  );
};

export default VersionDescription;
