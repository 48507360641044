import React from 'react';
import { useParams } from 'react-router-dom';
import { LogoLoader } from 'components/Base';
import { getTemplate } from 'state/actions';
import { UnlayerEditor } from '.';
import { useAsyncAction } from 'state/hooks';
import { Alert, AlertTitle } from '@material-ui/lab';
import { List, ListItem } from '@material-ui/core';

const TemplateEditor = () => {
  const { id } = useParams();

  const { data: template, loading, error } = useAsyncAction({
    action: getTemplate,
    values: { id },
  });

  if (error && error.includes('404')) {
    return (
      <Alert severity="warning">
        <AlertTitle>Wrong account selected or template deleted</AlertTitle>
        We could not find your template under the route you accessed. This can
        have two different reasons:
        <List>
          <ListItem>
            1. You might have switched the AWS Account while being in editing
            mode. Go back to the templates overview page.
          </ListItem>
          <ListItem>
            2. This template was deleted outside of the Semplates tool and can
            not be shown anymore. To recreate it from our database contact us
            via the support center in the lower left corner. To delete it from
            our database, go back to the templates overview table and use the
            delete icon to remove the template for good.
          </ListItem>
        </List>
      </Alert>
    );
  }

  if (loading || !template) return <LogoLoader />;

  return <UnlayerEditor />;
};

export default TemplateEditor;
