import React, { useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { BooleanParam, useQueryParam } from 'use-query-params';
import useFlags from 'components/hooks/useFlags';
import { DialogFormFrame } from '../Base';

const MaintenanceBanner = () => {
  const {
    flags: { underMaintenance },
  } = useFlags();

  const [maintenance = underMaintenance, setMaintenance] = useQueryParam(
    'maintenance',
    BooleanParam
  );

  useEffect(() => {
    if (underMaintenance) {
      const maintenanceStatus = sessionStorage.getItem('maintenance');
      if (maintenanceStatus === 'false') {
        setMaintenance(false);
      }

      sessionStorage.setItem('maintenance', maintenance.toString());
    }
    // eslint-disable-next-line
  }, [maintenance]);

  return (
    <DialogFormFrame
      open={maintenance || false}
      title={'New features are being released'}
      formComponent={
        <Alert severity="info">
          <Typography variant="body2">
            Our services are currently not available. We are currently deploying
            new features so managing templates will get even more convenient in
            the future. Please come back later.
          </Typography>
        </Alert>
      }
    />
  );
};

export default MaintenanceBanner;
