import React from 'react';
import PropTypes from 'prop-types';
import { Desktop, Mobile } from 'assets/icons';
import { TabComponent } from '../Preview';
import { styled } from '@material-ui/core';

const PreviewContainer = styled('div')(({ theme }) => ({
  height: '90%',
  width: '100%',
}));

const IFrame = styled('iframe')(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderColor: theme.palette.primary.main,
}));

const MobileIFrame = styled('iframe')(({ theme }) => ({
  width: '400px',
  minHeight: '100%',
  height: '100%',
  textAlign: 'center',
  borderColor: theme.palette.primary.main,
}));

const ResponsivePreview = ({ template }) => {
  const tabData = [
    {
      label: '',
      Icon: Desktop,
      children: () => <IFrame srcDoc={template?.html} />,
    },
    {
      label: '',
      Icon: Mobile,
      children: () => <MobileIFrame srcDoc={template?.html} />,
    },
  ];

  return (
    <PreviewContainer>
      <TabComponent tabData={tabData} />
    </PreviewContainer>
  );
};

ResponsivePreview.propTypes = {
  template: PropTypes.object.isRequired,
};

export default ResponsivePreview;
