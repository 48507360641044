import { showErrorSnackbar, showSuccessSnackbar } from './layout';
import {
  handleBackendErrorMessage,
  handleUpgradeError,
} from '../utils/error_handling';

import { fetchSignInMethodsForEmail } from 'firebase/auth';

import {
  DELETE_MEMBER_ERROR,
  DELETE_MEMBER_SUCCESS,
  INVITE_MEMBER_ERROR,
  INVITE_MEMBER_COSTS_ERROR,
  INVITE_MEMBER_SUCCESS,
  UPDATE_COMPANY_DETAILS_ERROR,
  UPDATE_COMPANY_DETAILS_SUCCESS,
  UPGRADE_PLAN_ERROR,
  UPGRADE_PLAN_SUCCESS,
  UPDATE_MEMBER_ERROR,
  UPDATE_MEMBER_SUCCESS,
  RESEND_MEMBER_INVITATION_SUCCESS,
  RESEND_MEMBER_INVITATION_ERROR,
} from '../consts/snackbarMessages';
import { setDefaultAwsAccount } from './awsAccounts';

export const setCompanyDetails = (data) => ({
  type: 'COMPANY_DETAILS_SET',
  payload: data,
});

export const setCompanySummary = (data) => ({
  type: 'COMPANY_SUMMARY_SET',
  payload: data,
});

export const updateSubscriptionDetails = (data) => ({
  type: 'SUBSCRIPTION_UPDATE',
  payload: data,
});

export const setSubscriptionDetails = (
  subscription,
  companyCreatedTimestamp
) => ({
  type: 'COMPANY_SUBSCRIPTION_SET',
  payload: { ...subscription, companyCreatedTimestamp },
});

export const setCompanyMembers = (data) => ({
  type: 'COMPANY_MEMBERS_SET',
  payload: data,
});

export const incrementCompanyMembersCount = () => ({
  type: 'COMPANY_MEMBERS_COUNT_INCREMENT',
  payload: undefined,
});

export const decrementCompanyMembersCount = () => ({
  type: 'COMPANY_MEMBERS_COUNT_DECREMENT',
  payload: undefined,
});

export const incrementAwsCredentialsCount = () => ({
  type: 'AWS_CREDENTIALS_COUNT_INCREMENT',
  payload: undefined,
});

export const decrementAwsCredentialsCount = () => ({
  type: 'AWS_CREDENTIALS_COUNT_DECREMENT',
  payload: undefined,
});

export const loadCompanyInfo = () => async (dispatch, getState, { axios }) => {
  try {
    const {
      data: { details, defaultAccount, subscription, summary },
    } = await axios.get(`/company`);
    dispatch(setCompanyDetails(details));
    defaultAccount && dispatch(setDefaultAwsAccount(defaultAccount));
    dispatch(setSubscriptionDetails(subscription));
    dispatch(setCompanySummary(summary));
    return { subscription, summary };
  } catch (error) {
    throw error;
  }
};

export const loadCompanyMembers = () => async (
  dispatch,
  getState,
  { axios, getFirebase }
) => {
  try {
    if (!getState().company.members) {
      const { data } = await axios.get(`/company/members`);

      if (data.members.length >= 3) {
        const auth = getFirebase().auth();
        data.members = await Promise.all(
          data.members.map(async (member) => {
            try {
              let signInMethods = await fetchSignInMethodsForEmail(
                auth,
                member.email
              );

              // Initialize a Set to store unique signInMethods
              const uniqueMethods = new Set();

              // Filter, clean, and add unique signInMethods
              signInMethods.forEach((method) => {
                if (method.includes('microsoft'))
                  uniqueMethods.add('microsoft');
                else if (method.includes('google')) uniqueMethods.add('google');
                else if (method.includes('okta')) uniqueMethods.add('okta');
                else if (method === 'password') uniqueMethods.add('password');
              });

              // Convert the Set back to an array
              signInMethods = Array.from(uniqueMethods);
              return { ...member, signInMethods };
            } catch (error) {
              console.log(error);
              return member;
            }
          })
        );
      }

      dispatch(setCompanyMembers(data));
      return data;
    } else {
      return true;
    }
  } catch (error) {
    throw error;
  }
};

export const inviteCompanyMember = ({
  confirmAdditionalCosts,
  email,
  role,
}) => async (dispatch, getState, { axios }) => {
  try {
    const { data } = await axios.post(`/company/member`, {
      confirmAdditionalCosts,
      email,
      role,
    });
    dispatch(setCompanyMembers(data));
    dispatch(incrementCompanyMembersCount());
    dispatch(showSuccessSnackbar(INVITE_MEMBER_SUCCESS));

    return true;
  } catch (error) {
    if (error.response?.status === 426) {
      handleUpgradeError(error, dispatch);
    } else if (error.response?.status === 402) {
      dispatch(showErrorSnackbar(INVITE_MEMBER_COSTS_ERROR));
    } else {
      handleBackendErrorMessage(error, dispatch, INVITE_MEMBER_ERROR);
    }
    throw error;
  }
};

export const upgradePlan = (values) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`/company/upgrade`, values);
    dispatch(updateSubscriptionDetails(data));
    return true;
  } catch (error) {
    throw error;
  }
};

export const deleteCompanyMember = (uid) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.delete(`/company/member/${uid}`);
    dispatch(setCompanyMembers(data));
    dispatch(decrementCompanyMembersCount());
    dispatch(showSuccessSnackbar(DELETE_MEMBER_SUCCESS));
    return true;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, DELETE_MEMBER_ERROR);
    throw error;
  }
};

export const changeMemberRole = ({ uid, role }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`company/member/${uid}/role`, { role });
    dispatch(setCompanyMembers(data));
    dispatch(showSuccessSnackbar(UPDATE_MEMBER_SUCCESS));
    return data;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, UPDATE_MEMBER_ERROR);
    throw error;
  }
};

export const resendMemberInvitation = ({ uid }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`company/member/${uid}/reinvite`);
    dispatch(showSuccessSnackbar(RESEND_MEMBER_INVITATION_SUCCESS));
    return data;
  } catch (error) {
    handleBackendErrorMessage(error, dispatch, RESEND_MEMBER_INVITATION_ERROR);
    throw error;
  }
};

export const updateCompanyDetails = (values) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(`/company/details`, values);
    dispatch(setCompanyDetails(data));
    dispatch(showSuccessSnackbar(UPDATE_COMPANY_DETAILS_SUCCESS));

    return true;
  } catch (error) {
    dispatch(showErrorSnackbar(UPDATE_COMPANY_DETAILS_ERROR));
    throw error;
  }
};

export const loadCompanyName = (uid) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const data = await axios.get(`/invited-member?userId=${uid}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const stripeCheckoutSuccessful = (
  checkoutSessionId,
  subscribedPlan
) => async (dispatch, getState, { axios }) => {
  try {
    const { data } = await axios.put(`/company/upgrade-success`, {
      checkoutSessionId,
      subscribedPlan,
    });
    dispatch(updateSubscriptionDetails(data));
    dispatch(showSuccessSnackbar(UPGRADE_PLAN_SUCCESS));
    return true;
  } catch (error) {
    dispatch(showErrorSnackbar(UPGRADE_PLAN_ERROR));
    throw error;
  }
};
