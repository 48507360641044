import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { SubscriptionNudge } from './';
import { Lock, LogOutOutline, Moon, Settings, Sun, User } from 'assets/icons';
import { useDarkMode, useLazyLoading } from 'state/hooks';
import { signOut } from 'state/actions';
import { companySubscription, userSecurity, userSettings } from 'consts/routes';
import AccountSelector from './AccountSelector';
import { Badge, ProfileAvatar } from '../Base';

const ICON_SIZE = 'small';

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  padding: '0px',
  margin: '0px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const MenuButton = styled(Button)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(0, 3, 0, 2),
  margin: theme.spacing(0, 0),
  '&:hover': {
    backgroundColor: theme.palette.background.main,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '0px',
}));

const StyledMenuList = styled(MenuList)(({ theme }) => ({
  width: '230px',
  textAlign: 'right',
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 500,

  '& .MuiListItemIcon-root': {
    minWidth: '30px',
    color: theme.palette.text.main,
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  height: '1px',
}));

const UserMenu = ({ themeMode, planName }) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [signOutAction] = useLazyLoading({ action: signOut });
  const [, setDarkMode] = useDarkMode();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleRouteUserSettings = (event) => {
    handleClose(event);
    history.push(userSettings);
  };

  const handleRouteUserSecurity = (event) => {
    handleClose(event);
    history.push(userSecurity);
  };

  const handleRouteSignOut = (event) => {
    handleClose(event);
    signOutAction();
    history.push('/login');
  };

  const handleRouteSubscription = (event) => {
    handleClose(event);
    history.push(companySubscription);
  };

  const handleChangeTheme = () => {
    setDarkMode(themeMode !== 'dark');
  };

  let themeModeLabel = themeMode === 'dark' ? 'Light mode' : 'Dark mode';
  let themeModeIcon = themeMode === 'dark' ? Sun : Moon;

  const menuItems = [
    {
      label: planName || 'Discover',
      Item: Badge,
      Icon: Settings,
      onClick: handleRouteSubscription,
    },
    {
      Divider: StyledDivider,
    },
    {
      label: 'User Settings',
      Icon: User,
      onClick: handleRouteUserSettings,
    },
    {
      label: 'Password',
      Icon: Lock,
      onClick: handleRouteUserSecurity,
    },
    {
      label: themeModeLabel,
      Icon: themeModeIcon,
      onClick: handleChangeTheme,
    },
    {
      Divider: StyledDivider,
    },
    {
      label: 'Logout',
      Icon: LogOutOutline,
      onClick: handleRouteSignOut,
    },
  ];

  return (
    <StyledContainer>
      <AccountSelector />
      <SubscriptionNudge />
      <MenuButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disableRipple={true}
        disableFocusRipple={true}
        disableElevation={true}
      >
        <ProfileAvatar location="menu" />
      </MenuButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={'bottom-end'}
        popperOptions={{
          modifiers: {
            preventOverflow: {
              padding: 0,
            },
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <StyledPaper>
              <ClickAwayListener onClickAway={handleClose}>
                <StyledMenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {menuItems.map(
                    ({ label, Item, Icon, onClick, Divider }, ix) => {
                      if (Divider) {
                        return <Divider key={ix} />;
                      }
                      return (
                        <StyledMenuItem key={ix} onClick={onClick}>
                          {Item && <Item text={label} />}
                          {!Item && label}
                          <ListItemIcon>
                            <Icon fontSize={ICON_SIZE} />
                          </ListItemIcon>
                        </StyledMenuItem>
                      );
                    }
                  )}
                </StyledMenuList>
              </ClickAwayListener>
            </StyledPaper>
          </Grow>
        )}
      </Popper>
    </StyledContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.layout?.themeMode,
    planName: state.company?.subscription?.name,
  };
};

export default connect(mapStateToProps)(UserMenu);
