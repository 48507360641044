import React, { useRef, useState } from 'react';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  Paper,
  Popper,
} from '@material-ui/core';
import { FilterList } from 'assets/icons';
import { useTemplates } from 'components/Contexts';
import { FilterContainer, FilterCount } from './TagFilterIconButton';
import StatusFilter from './StatusFilter';
import { arraysAreEqual } from 'components/utils';
import { DEFAULT_STATUS_FILTERS } from 'consts/defaults';

const StatusFilterIconButton = () => {
  const { statusFilterCount, statusFilterState } = useTemplates();
  const [filterOpen, setFilterOpen] = useState(false);
  const filterAnchorRef = useRef(null);
  const defaultFiltersSelected = arraysAreEqual(
    statusFilterState,
    DEFAULT_STATUS_FILTERS
  );

  return (
    <FilterContainer>
      {!defaultFiltersSelected && statusFilterCount > 0 && (
        <FilterCount>{statusFilterCount}</FilterCount>
      )}
      <IconButton
        ref={filterAnchorRef}
        style={{ padding: 0 }}
        onClick={() => setFilterOpen(!filterOpen)}
      >
        <FilterList size={20} />
      </IconButton>
      <Popper
        key={`template-table-popper-filter`}
        open={filterOpen}
        anchorEl={filterAnchorRef.current}
        transition
        disablePortal
        placement={'bottom-end'}
        popperOptions={{
          modifiers: {
            preventOverflow: {
              padding: 0,
            },
          },
        }}
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <ClickAwayListener onClickAway={() => setFilterOpen(false)}>
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'center top' }}
            >
              <Paper>
                <StatusFilter />
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </FilterContainer>
  );
};

export default StatusFilterIconButton;
