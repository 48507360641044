import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { DialogFormFrame } from 'components/Base';
import { duplicateTemplate } from 'state/actions';
import { useLazyLoading } from 'state/hooks';
import DuplicateTemplateForm from './DuplicateTemplateForm';
import { connect } from 'react-redux';
import { useTracking } from 'components/Contexts/TrackingContext';

const DuplicateTemplateDialog = ({
  open,
  onClose,
  template,
  defaultUid,
  accounts,
}) => {
  const history = useHistory();
  const { logEvent } = useTracking();

  const [duplicateTemplateAction, { loading }] = useLazyLoading({
    action: duplicateTemplate,
    onSuccess: (data) => {
      logEvent({ category: 'template', action: 'duplicated' });
      if (!data.target_account_uid) {
        history.push(`/templates/${data.uid}/editor`);
      } else {
        onClose();
      }
    },
  });

  const handleSubmit = async (values) => {
    const { name, destination } = values;
    await duplicateTemplateAction(
      template?.uid,
      name,
      destination !== defaultUid ? destination : null,
      destination !== defaultUid
        ? accounts.find((account) => account.id === destination).alias
        : null
    );
  };

  return (
    <DialogFormFrame
      title={`Duplicate ${template?.templateName}`}
      open={open}
      onClose={onClose}
      formComponent={
        <DuplicateTemplateForm
          onSubmit={handleSubmit}
          loading={loading}
          template={template}
        />
      }
    />
  );
};

DuplicateTemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    defaultUid: state.aws?.default?.id,
    accounts: state.aws?.all,
  };
};

export default connect(mapStateToProps)(DuplicateTemplateDialog);
