import React, { useEffect, useState } from 'react';

import { StringParam, useQueryParam } from 'use-query-params';
import { signInWithToken } from '../state/actions/auth';
import { useLazyLoading } from '../state/hooks';
import { useHistory } from 'react-router-dom';
import { Loader } from 'components/Base';
import AuthHeadline from 'components/Auth/AuthHeadline';
import { AuthLayout, ResendVerification } from 'components/Auth';

const TokenizedLogin = () => {
  const history = useHistory();
  const [loginCalled, setLoginCalled] = useState(false);
  const [token] = useQueryParam('token', StringParam);
  const [email] = useQueryParam('email', StringParam);
  const [login, { loading }] = useLazyLoading({
    action: signInWithToken,
    onSuccess: () => {
      setLoginCalled(true);
      history.push('/');
    },
  });

  useEffect(() => {
    if (token && email && !loginCalled) {
      login(token);
    }
    // eslint-disable-next-line
  }, [token, loginCalled]);

  if (loading) {
    return <Loader />;
  }

  return (
    <AuthLayout title="Log In">
      <AuthHeadline
        title="Invalid Link!"
        subTitle="Your link has expired or is invalid. Click below to request a new one."
      />
      <ResendVerification email={email} />
    </AuthLayout>
  );
};

export default TokenizedLogin;
