const INITIAL_STATE = {
  all: [],
  default: undefined,
};

const setDefaultAwsAccount = (state, { payload }) => {
  return {
    ...state,
    default: payload,
  };
};

const addAwsAccount = (state, { payload }) => {
  return {
    ...state,
    default: payload.find((account) => account.isDefault),
    all: payload,
  };
};

const setAwsAccounts = (state, { payload }) => {
  return {
    ...state,
    all: payload,
    default: payload.find((account) => account.isDefault),
  };
};

// FixMe: Adjust to work for multiple aws_accounts
const setApiKey = (state, { payload }) => {
  if (state.default?.id === payload.id) {
    return {
      default: payload,
      all: [payload],
    };
  }

  return {
    ...state,
    all: [payload],
  };
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'AWS_ACCOUNTS_SET': {
      return setAwsAccounts(state, action);
    }
    case 'DEFAULT_AWS_ACCOUNT_SET': {
      return setDefaultAwsAccount(state, action);
    }
    case 'AWS_ACCOUNT_ADD': {
      return addAwsAccount(state, action);
    }
    case 'API_KEY_UPDATE': {
      return setApiKey(state, action);
    }
    default:
      return state;
  }
};

export default settingsReducer;
