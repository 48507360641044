import { styled } from '@material-ui/core/styles';
import { IconButton, TableCell, Tooltip } from '@material-ui/core';
import defaultBackground from '../../assets/img/image-placeholder.png';
import React from 'react';

export const TableTitle = styled('div')(({ theme, cmdpressed }) => ({
  fontSize: '0.9rem',
  fontWeight: 600,
  cursor: cmdpressed ? 'alias' : 'pointer',
}));

export const ShimmerContainer = styled('div')({
  position: 'relative',
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.main,
}));

export const StyledImage = styled(({ backgroundImage, children, ...rest }) => (
  <div {...rest}>{children}</div>
))(({ theme, backgroundImage }) => ({
  width: '100px',
  height: '100px',
  backgroundImage: `url(${backgroundImage || defaultBackground})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

export const StyledLabel = styled('div')({
  cursor: 'pointer',
});

export const tableCellStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '20vW',
  cursor: 'pointer',
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const TableActions = ({ actions, row, col, labelId }) => {
  return (
    <TableCell align={col.align} component="th" id={labelId} scope="row">
      {actions.map((action, index) => {
        if (
          (action.renderCondition && !row[action.renderCondition]) ||
          (action.hideCondition && row[action.hideCondition])
        ) {
          return null;
        }

        return !action.disabledField || row[action.disabledField] ? (
          <Tooltip key={index} title={action.tooltip}>
            <StyledIconButton
              onClick={(event) => {
                event.stopPropagation();
                action.onClick(row);
              }}
            >
              {action.icon}
            </StyledIconButton>
          </Tooltip>
        ) : (
          <IconButton key={index} disabled>
            {action.icon}
          </IconButton>
        );
      })}
    </TableCell>
  );
};

export const TableIcons = ({ icons, col, labelId }) => {
  return (
    <TableCell align={col.align} component="th" id={labelId} scope="row">
      {icons.map(({ tooltip, disabled, Icon, icon }, index) => {
        return (
          <Tooltip key={index} title={tooltip}>
            <StyledIconButton disabled={disabled}>
              {icon && (
                <img style={{ height: '24px' }} src={icon.type} alt={tooltip} />
              )}
              {Icon && <Icon />}
            </StyledIconButton>
          </Tooltip>
        );
      })}
    </TableCell>
  );
};
