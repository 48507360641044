import React, { Fragment, useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  AlertDialog,
  LogoLoader,
  OverviewTable,
  SettingsHeader,
} from 'components/Base';
import { memberColumns } from 'consts';
import {
  deleteCompanyMember,
  loadCompanyMembers,
  resendMemberInvitation,
} from 'state/actions';
import { useAsyncAction, useLazyLoading } from 'state/hooks';
import { DeleteOutline, EditIcon, Refresh } from 'assets/icons';
import { connect } from 'react-redux';
import ErrorAlert from 'components/Base/ErrorAlert';
import { useSubscription } from '../../../Contexts';

const Members = ({ memberData }) => {
  const { members, total } = memberData;
  const [scopedMember, setScopedMember] = useState(null);
  const [deleteMemberDialogOpen, setDeleteMemberDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { openUserDialog } = useSubscription();

  const { loading: loadingMembers, error } = useAsyncAction({
    action: loadCompanyMembers,
  });

  const [handleDeleteMember, { loading }] = useLazyLoading({
    action: deleteCompanyMember,
    onSuccess: () => {
      handleCloseDialog();
    },
  });

  const [handleResendInvitation] = useLazyLoading({
    action: resendMemberInvitation,
  });

  const handleOpenDeleteDialog = (member) => {
    setDeleteMemberDialogOpen(true);
    setScopedMember(member);
  };

  const handleOpenMemberEditDialog = (member) => {
    openUserDialog(member);
  };

  const handleCloseDialog = () => {
    setDeleteMemberDialogOpen(false);
    setScopedMember(null);
  };

  if (error) {
    return <ErrorAlert error={error} />;
  }

  if (loadingMembers || members === undefined) {
    return <LogoLoader />;
  }

  // when all objects in members array do not contain field signInMethods,
  // then filter signInMethods from memberColumns (rate limiting hack)
  const shouldNotDisplayMemberColumn = !members.every((member) =>
    member.hasOwnProperty('signInMethods')
  );

  return (
    <Fragment>
      <Grid container direction={'column'}>
        <SettingsHeader
          buttonText={' Add Member'}
          title={'Organization Members'}
          onClick={() => openUserDialog(null)}
        />
        <Grid item>
          <OverviewTable
            data={members}
            columns={
              shouldNotDisplayMemberColumn
                ? memberColumns.filter(
                    (column) => column.id !== 'signInMethods'
                  )
                : memberColumns
            }
            total={total}
            actions={[
              {
                icon: <EditIcon size="26" />,
                tooltip: 'Edit User',
                onClick: handleOpenMemberEditDialog,
              },
              {
                icon: <DeleteOutline size="26" />,
                tooltip: 'Remove User',
                onClick: handleOpenDeleteDialog,
              },
              {
                icon: <Refresh size="26" />,
                tooltip: 'Resend Invitation',
                onClick: handleResendInvitation,
                hideCondition: 'showResendInvitation',
              },
            ]}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Grid>
      </Grid>
      <AlertDialog
        onClose={handleCloseDialog}
        open={deleteMemberDialogOpen}
        title={'Remove User'}
        onSubmit={() => handleDeleteMember(scopedMember.uid)}
        text={
          'Are you sure you want to delete the user from your organization?'
        }
        submitText={'Remove'}
        loading={loading}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    memberData: state.company.members || [],
  };
};

export default connect(mapStateToProps)(Members);
