const INITIAL_STATE = {
  details: {},
  members: undefined,
  subscription: undefined,
  summary: {
    progress: 0,
  },
};

const applySetCompanyDetails = (state, { payload }) => {
  return {
    ...state,
    details: payload,
  };
};

const applySetCompanySummary = (state, { payload }) => {
  return {
    ...state,
    summary: {
      ...state.summary,
      ...payload,
      progress: payload.progress || state.summary.progress,
    },
  };
};

const applySetCompanySubscription = (state, { payload }) => {
  return {
    ...state,
    subscription: {
      ...payload,
    },
  };
};

const applySetCompanyMembers = (state, { payload }) => {
  const { total, members } = payload;
  return {
    ...state,
    members: {
      total,
      members: members.map((member) =>
        Object.assign(member, {
          roleName: member.role.toString() === 'admin' ? 'Admin' : 'Read only',
          verified: !!member.firebaseUid ? 'True' : 'False',
          showResendInvitation: !!member.firebaseUid,
        })
      ),
    },
  };
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'COMPANY_DETAILS_SET': {
      return applySetCompanyDetails(state, action);
    }
    case 'COMPANY_SUMMARY_SET': {
      return applySetCompanySummary(state, action);
    }
    case 'COMPANY_SUBSCRIPTION_SET': {
      return applySetCompanySubscription(state, action);
    }
    case 'COMPANY_MEMBERS_SET': {
      return applySetCompanyMembers(state, action);
    }
    case 'COMPANY_MEMBERS_COUNT_INCREMENT': {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          userCount: state.subscription.userCount + 1,
        },
      };
    }
    case 'COMPANY_MEMBERS_COUNT_DECREMENT': {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          userCount: state.subscription.userCount - 1,
        },
      };
    }
    case 'AWS_CREDENTIALS_COUNT_INCREMENT': {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          awsAccountsCount: state.subscription.awsAccountsCount + 1,
        },
      };
    }
    case 'AWS_CREDENTIALS_COUNT_DECREMENT': {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          awsAccountsCount: state.subscription.awsAccountsCount - 1,
        },
      };
    }
    case 'SUBSCRIPTION_UPDATE': {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default settingsReducer;
