import React from 'react';
import { LogoLoader } from 'components/Base';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import AwsAccountStatus from './AwsAccountStatus';

const AwsAccountStatusCard = ({ loading, statusSummary }) => {
  return (
    <Card style={{ marginTop: '16px', position: 'relative' }}>
      <CardHeader title="Status Info" />
      <CardContent>
        {loading ? (
          <Box pt={5}>
            <LogoLoader />
          </Box>
        ) : (
          <AwsAccountStatus statusSummary={statusSummary} />
        )}
      </CardContent>
    </Card>
  );
};

export default AwsAccountStatusCard;
