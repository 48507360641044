import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useWelcomeSlides } from 'components/WelcomeSlides/hooks';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledCarousel = styled(Carousel)(({ theme }) => ({
  width: '100%',
  height: '100%',
  margin: 0,
  padding: 0,

  '& .slide': {
    textAlign: 'left',
  },
}));

const SlideContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 600,
}));

const WelcomeSlidesCarousel = () => {
  const { currentSlide, setCurrentSlide, slides } = useWelcomeSlides();

  return (
    <StyledBox>
      <StyledCarousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        transitionTime={0}
        selectedItem={currentSlide}
        onChange={(index) => setCurrentSlide(index)}
      >
        {slides.map((Slide, index) => (
          <SlideContainer key={index}>
            <Slide active={index === currentSlide} />
          </SlideContainer>
        ))}
      </StyledCarousel>
    </StyledBox>
  );
};

export default WelcomeSlidesCarousel;
