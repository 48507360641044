import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid, Link } from '@material-ui/core';
import * as Yup from 'yup';
import { FieldWrapper, LoadingButton } from 'components/Base';
import PasswordInputField from './PasswordInputField';
import { signIn } from 'state/actions/auth';
import { useLazyLoading } from 'state/hooks';
import SSOButtons from './SSOButtons';
import AuthHeadline from './AuthHeadline';
import { SpacedContainer, StyledLink } from './styles';
import { styled } from '@material-ui/core/styles';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('This field is required'),
  password: Yup.string().required('This field is required'),
});

const LoginButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.text.main,
  },
}));

const LoginForm = () => {
  const history = useHistory();

  const [handleSubmit, { loading }] = useLazyLoading({
    action: signIn,
    onSuccess: () => {
      history.push('/');
    },
  });

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validateOnBlur={false}
      validationSchema={LoginSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AuthHeadline
                title="Welcome back"
                subTitle="Please enter your details."
              />
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper label="Email" inverted variant="small">
                <Field
                  component={TextField}
                  fullWidth
                  name="email"
                  variant="outlined"
                  size="small"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper label="Password" inverted variant="small">
                <Field
                  fullWidth
                  component={PasswordInputField}
                  name="password"
                  variant="outlined"
                  size="small"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <LoginButton
                loading={isSubmitting || loading}
                variant="contained"
                color="secondary"
                type="submit"
                className="button-wide"
              >
                Login
              </LoginButton>
            </Grid>
            <Grid item xs={12}>
              <SSOButtons action="LOGIN" />
            </Grid>
            <Grid item xs={12}>
              <SpacedContainer>
                <Link component={RouterLink} to="/register" color="secondary">
                  <StyledLink>
                    No account? <b>Sign up for free.</b>
                  </StyledLink>
                </Link>
                <Link
                  component={RouterLink}
                  to="/password-forgot"
                  color="secondary"
                >
                  <StyledLink>
                    <b>Forgot password?</b>
                  </StyledLink>
                </Link>
              </SpacedContainer>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
