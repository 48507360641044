import React from 'react';
import { LogoLoader, OverviewTable, SettingsHeader } from 'components/Base';
import { Box } from '@material-ui/core';
import { dnsColumns } from 'consts';
import { styled } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const Container = styled('div')(({ theme }) => ({
  marginTop: '32px',
  position: 'relative',
  height: '80px',
}));

const DomainDetailsTableContent = ({ domainDetails, loading }) => {
  if (loading) {
    return (
      <Container>
        <LogoLoader />
      </Container>
    );
  }

  if (domainDetails.error) {
    return (
      <Box mt={4}>
        <SettingsHeader
          title={'Domain Details of ' + domainDetails?.domainName}
        />
        <Alert severity={'warning'}>
          After 3 days of detection attempts, Amazon SES has been unable to
          detect the presence of the MX record required to use mails.
          {domainDetails?.domainName} as a custom MAIL FROM domain for verified
          identity {domainDetails?.domainName}.
        </Alert>
      </Box>
    );
  }

  let records = [];

  if (domainDetails.domainRecord) {
    records.push({
      recordName: 'Domain Verification Record',
      ...domainDetails.domainRecord,
    });
  }

  if (domainDetails.dkimRecords && domainDetails.dkimRecords.length > 0) {
    domainDetails.dkimRecords.forEach((record) => {
      records.push({
        recordName: 'DKIM Record',
        ...record,
      });
    });
  }

  if (
    domainDetails.mailFromRecords &&
    domainDetails.mailFromRecords.length > 0
  ) {
    domainDetails.mailFromRecords.forEach((record) => {
      records.push({
        recordName: 'Mail From Record',
        ...record,
      });
    });
  }

  const DNSTable = ({ records }) => {
    return (
      <OverviewTable
        data={records}
        columns={dnsColumns}
        outlined={'outlined'}
        total={records.length}
        page={0}
        rowsPerPage={10}
      />
    );
  };

  return (
    <Box mt={4}>
      <SettingsHeader
        title={'Domain Details of ' + domainDetails?.domainName}
      />
      <Box
        mb={2}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span>
          <strong style={{ marginRight: '8px' }}>DKIM Status:</strong>
          {domainDetails.dkimStatus}
        </span>
        <span>
          <strong style={{ marginRight: '8px' }}>Sending Status:</strong>
          {domainDetails.sendingStatus}
        </span>
        <span>
          <strong style={{ marginRight: '8px' }}>Mail From Status:</strong>
          {domainDetails.mailFromStatus}
        </span>
      </Box>
      <DNSTable records={records} />
    </Box>
  );
};

export default DomainDetailsTableContent;
