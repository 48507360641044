import React from 'react';
import { privacyPolicy } from 'components/Legal';
import { useTheme } from '@material-ui/core';

const PrivacyStatement = () => {
  const theme = useTheme();
  return (
    <div style={{ padding: theme.spacing(3) }}>
      <div dangerouslySetInnerHTML={{ __html: privacyPolicy }}></div>
    </div>
  );
};
export default PrivacyStatement;
