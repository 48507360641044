import { connect } from 'react-redux';
import { Snackbar as MuiSnackbar } from '@material-ui/core';
import { clearSnackbar } from 'state/actions';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

const Snackbar = ({
  snackbarOpen,
  snackbarMessage,
  snackbarSeverity,
  clearSnackbar,
}) => {
  function handleClose() {
    clearSnackbar();
  }

  return (
    <MuiSnackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={snackbarSeverity}>
        {snackbarMessage}
      </Alert>
    </MuiSnackbar>
  );
};

const mapStateToProps = (state) => {
  return {
    snackbarOpen: state.layout.snackbarOpen,
    snackbarMessage: state.layout.snackbarMessage,
    snackbarSeverity: state.layout.snackbarSeverity,
  };
};

const mapDispatchToProps = {
  clearSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
