import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, styled, useTheme } from '@material-ui/core';

const BackgroundImage = styled('div')({
  backgroundImage: ({ backgroundimage }) => `url(${backgroundimage})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  height: '80%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  marginTop: '5%',
});

const BackgroundImageContainer = styled('div')({
  width: '100%',
  height: '75%',
  position: 'relative',
});

const ImageDescriptionContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.main,
}));

const NoData = ({ onClick, image, description, buttonText }) => {
  const { palette } = useTheme();
  return (
    <Fragment>
      <BackgroundImageContainer>
        <BackgroundImage backgroundimage={image} />
      </BackgroundImageContainer>
      <ImageDescriptionContainer>
        <h4 style={{ padding: '0 10%', color: palette.text.main }}>
          {description}
        </h4>
        {buttonText ? (
          <Button variant={'contained'} onClick={onClick}>
            {buttonText}
          </Button>
        ) : null}
      </ImageDescriptionContainer>
    </Fragment>
  );
};

NoData.propTypes = {
  description: PropTypes.string.isRequired,
};

export default NoData;
