const errors = {
  USER_LIMIT_EXCEEDED: {
    title: 'Maximum number of users reached',
    description:
      'You reached the maximum number of users for the plan you are currently subscribed on. Send us a message to let us know that you want to upgrade your companies plan.  We will get back to you asap.',
  },
  TEMPLATE_LIMIT_EXCEEDED: {
    title: 'Maximum number of templates reached',
    description:
      'You reached the maximum number of templates for the plan you are currently subscribed on. Send us a message to let us know that you want to upgrade your companies plan. We will get back to you asap.',
  },
};

export default errors;
