import { styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import React from 'react';

export const Text = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    fontSize: '14px',
    lineHeight: 1.2,
    textAlign: 'initial',
    fontFamily: theme.typography.fontFamilyTitle,
    fontWeight: 400,
    marginBottom: theme.spacing(0),
  })
);

export const BoldText = styled(Text)(({ theme }) => ({
  fontWeight: 700,
}));

export const Actions = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  marginTop: theme.spacing(3),
  padding: theme.spacing(3),
  textAlign: 'center',
}));
