import React from 'react';
import {
  Description,
  Lock,
  LogOutOutline,
  Moon,
  Security,
  Sun,
} from 'assets/icons';
import {
  MenuItem,
  MenuList,
  ListItemIcon,
  styled,
  Typography,
  Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDarkMode, useLazyLoading } from 'state/hooks';
import { signOut } from 'state/actions';
import {
  userPrivacy,
  userSecurity,
  userSettings,
  userTerms,
} from 'consts/routes';
import { useSubscription } from 'components/Contexts';
import { StyledInvertedButton, Badge, ProfileAvatar } from 'components/Base';

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingTop: `calc(${theme.custom.appBarHeight}px - 30px)`,
  width: '100%',
  minHeight: '700px',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
}));

const StyledUserName = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  fontWeight: 700,
  fontSize: '18px',
  color: theme.palette.text.main,
  margin: theme.spacing(2, 0, 1, 0),
}));

const Divider = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: theme.spacing(3, 1, 1, 1),
  height: '1px',
  width: '70%',
  borderRadius: '15px',
  backgroundColor: theme.palette.secondary.main,
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  width: '200px',
  color: theme.palette.text.main,
  margin: theme.spacing(1, 0),
  borderRadius: '4px',
  transition: 'all 0.15s ease',

  '& .MuiListItemIcon-root': {
    minWidth: '35px',
  },

  '& .MuiSvgIcon-root': {
    color: theme.palette.text.main,
  },
}));

const StyledLogoutItem = styled(StyledMenuItem)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
}));

const StyledItemText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  fontSize: '16px',
}));

const StyledLogoutText = styled(StyledItemText)(({ theme }) => ({
  fontWeight: 600,
}));

const DashboardProfile = ({ firstName, lastName, themeMode }) => {
  const [darkMode, setDarkMode] = useDarkMode();
  const {
    subscription: { name },
  } = useSubscription();
  const history = useHistory();
  const [signOutAction] = useLazyLoading({
    action: signOut,
    onSuccess: () => history.push('/login'),
  });

  const handleThemeModeSwitch = () => {
    setDarkMode(!darkMode);
  };

  const menuItems = [
    {
      Icon: Lock,
      label: 'Change password',
      to: userSecurity,
    },
    {
      Icon: Moon,
      label: 'Dark mode',
      onClick: () => handleThemeModeSwitch(),
      onMode: 'light',
    },
    {
      Icon: Sun,
      label: 'Light mode',
      onClick: () => handleThemeModeSwitch(),
      onMode: 'dark',
    },
    {
      Icon: Security,
      label: 'Data privacy',
      to: userPrivacy,
    },
    {
      Icon: Description,
      label: 'Terms of use',
      to: userTerms,
    },
  ];

  return (
    <Root>
      <ProfileAvatar location="home" />
      <StyledUserName variant="body1">
        {firstName + ' ' + lastName}
      </StyledUserName>
      <Badge text={name} />
      <Box mt={6} mb={1}>
        <StyledInvertedButton
          variant="outlined"
          onClick={() => history.push(userSettings)}
        >
          View Profile
        </StyledInvertedButton>
      </Box>
      <Divider />
      <MenuList>
        <div>
          {menuItems.map(
            ({ label, Icon, onClick, to, onMode }, index) =>
              themeMode === onMode ||
              (!onMode && (
                <StyledMenuItem
                  key={`dashboard-profile-menu-item-${index}`}
                  onClick={() => (to ? history.push(to) : onClick())}
                >
                  <ListItemIcon>
                    <Icon fontSize="small" />
                  </ListItemIcon>
                  <StyledItemText>{label}</StyledItemText>
                </StyledMenuItem>
              ))
          )}
        </div>
      </MenuList>

      <StyledLogoutItem onClick={signOutAction}>
        <ListItemIcon>
          <LogOutOutline fontSize="small" />
        </ListItemIcon>
        <StyledLogoutText>Logout</StyledLogoutText>
      </StyledLogoutItem>
    </Root>
  );
};

const mapStateToProps = (state) => {
  return {
    firstName: state.user?.firstName || '',
    lastName: state.user?.lastName || '',
    themeMode: state.layout?.themeMode || 'light',
  };
};

export default connect(mapStateToProps)(DashboardProfile);
