import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { AsyncDialogActions, TextInputField } from 'components/Base';
import * as Yup from 'yup';

const CreateSchema = Yup.object().shape({
  displayName: Yup.string().required('This field is required'),
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  email: Yup.string().required('This field is required'),
});

const UserEditForm = ({ loading, onSubmit, user }) => {
  const { displayName, firstName, lastName, email } = user;
  return (
    <Formik
      initialValues={{
        displayName,
        firstName,
        lastName,
        email,
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={CreateSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInputField
                inverted
                label="Display name"
                name="displayName"
                subtitle="Name shown to all members of your organization"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextInputField label="First name" name="firstName" />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextInputField label="Last name" name="lastName" />
            </Grid>
            <Grid item xs={12}>
              <TextInputField
                disabled={true}
                label="E-Mail"
                subtitle="E-Mail Address we will send notifications to"
                name="email"
              />
            </Grid>
            <AsyncDialogActions
              loading={isSubmitting || loading}
              submitText={'Save'}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

UserEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default UserEditForm;
