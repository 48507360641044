import React, { useState } from 'react';
import { AlertDialog, OverviewTable } from 'components/Base';
import { createTag, removeTag } from 'state/actions';
import { connect } from 'react-redux';
import { useLazyLoading } from 'state/hooks';
import { tagColumns } from 'consts';
import { EditIcon, DeleteOutline } from 'assets/icons';
import { Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const StyledEdit = styled(EditIcon)(({ theme }) => ({
  color: theme.palette.text.main,
}));

const StyledDelete = styled(DeleteOutline)(({ theme }) => ({
  color: theme.palette.text.main,
}));

const TagManager = ({ tags, total, onEditDialogClick }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [handleDelete, { loading: deleteLoading }] = useLazyLoading({
    action: removeTag,
    onSuccess: () => {
      handleClose();
    },
  });

  const handleClose = () => {
    setDeleteDialogOpen(false);
    setSelectedTag(undefined);
  };

  const handleOpenDeleteDialog = (value) => {
    setSelectedTag(value);
    setDeleteDialogOpen(true);
  };

  return (
    <Grid item>
      <OverviewTable
        data={tags}
        columns={tagColumns}
        total={total}
        actions={[
          {
            icon: <StyledEdit size="26" />,
            tooltip: 'Edit Tag',
            onClick: onEditDialogClick,
          },
          {
            icon: <StyledDelete size="26" />,
            tooltip: 'Remove Tag',
            onClick: handleOpenDeleteDialog,
          },
        ]}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <AlertDialog
        onClose={handleClose}
        title="Delete Tag"
        open={deleteDialogOpen}
        text="Are you sure you want to delete this tag? This action will remove the tag from all templates it is attached to."
        onSubmit={() => handleDelete(selectedTag)}
        submitText="Yes, Delete"
        loading={deleteLoading}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    tags: state.tags.tags,
    total: state.tags.total,
  };
};

export const mapDispatchToProps = {
  createTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(TagManager);
