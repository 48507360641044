import * as Yup from 'yup';

export default Yup.object().shape({
  alias: Yup.string().required('This field is required'),
  awsRegion: Yup.string().required('This field is required'),
  roleArn: Yup.string()
    .required('This field is required')
    .test('role-arn-format', 'Invalid Role ARN format', (value) => {
      if (!value) return false;
      const arnRegex = /^arn:aws:iam::\d+:role\/[\w+=,.@-]+$/;
      return arnRegex.test(value);
    }),
  confirmAdditionalCosts: Yup.bool().default(false),
});
