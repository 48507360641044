const verifiedMailColumns = [
  {
    id: 'alias',
    subId: 'awsRegionName',
    label: '',
    type: 'subtitled',
    sortable: true,
  },
  {
    id: 'createdTimestamp',
    label: 'Added',
    type: 'date',
    sortable: true,
  },
  {
    id: 'roleArn',
    label: 'Role Arn',
    type: 'text',
    sortable: true,
  },
  {
    id: 'accessKey',
    label: 'Access Key',
    type: 'text',
    sortable: true,
  },
  // {
  //   id: 'apiKey',
  //   label: 'API Key',
  //   type: 'text',
  //   sortable: false,
  // },
  {
    align: 'center',
    label: 'Actions',
    type: 'actions',
    sortable: false,
  },
];

export default verifiedMailColumns;
