import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Slide,
  styled,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Done, InfoOutlined } from 'assets/icons';

export const BaseCard = styled('div')(({ theme, iscolored, onClick }) => ({
  borderRadius: '10px',
  backgroundColor:
    theme.palette.type === 'dark'
      ? iscolored
        ? theme.palette.secondary.light
        : theme.palette.background.paper
      : iscolored
      ? theme.palette.background.light
      : theme.palette.background.main,
  padding: theme.spacing(2.5),
  cursor: onClick ? 'pointer' : 'default',
}));

export const IconContainer = styled('div')(({ theme, iscolored, islarge }) => ({
  borderRadius: islarge ? '5.5vw' : '3.5vw',
  width: islarge ? '5.5vw' : '3.5vw',
  height: islarge ? '5.5vw' : '3.5vw',
  backgroundColor: iscolored
    ? theme.palette.text.defaultReverted
    : theme.palette.type === 'dark'
    ? theme.palette.secondary.light
    : theme.palette.background.light,
  padding: theme.spacing(1, 0),
  marginTop: islarge ? '5%' : theme.spacing(0),
  '& .MuiSvgIcon-root': {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translateY(-55%) translateX(-50%)',
    color: theme.palette.text.default,
  },
  fontSize: islarge ? '2.3vw' : '1.7vw',
}));

const DoneContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  borderRadius: '1.4vw',
  width: '1.4vw',
  height: '1.4vw',
  position: 'relative',
  top: '12%',
  left: '77%',
  transform: 'translateY(-25%) translateX(-25%)',
  fontSize: '1.1vw',
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.main,
  },
}));

const SettingsCardComponent = styled(BaseCard)(({ theme, fullheight }) => ({
  border:
    theme.palette.type === 'dark'
      ? `0px solid ${theme.palette.grey[100]}`
      : `1px solid ${theme.palette.grey[100]}`,
  boxShadow: '0px 4px 4px 0px #0000000D',
  transition: 'all 0.15s ease',
  height: fullheight ? '100%' : '75%',
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'space-between',
  '&:hover': {
    boxShadow: theme.custom.standardShadow,
  },
}));

const NudgeCardComponent = styled(SettingsCardComponent)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 20px 50px 0px #00000026',
  '&:hover': {
    boxShadow: '0px 30px 60px 0px #00000028',
  },
  backgroundColor: theme.palette.text.defaultReverted,
  height: '100%',
}));

const OnboardingCardComponent = styled(BaseCard)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: theme.spacing(2.5),
  transition: 'all 0.15s ease',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
}));

const DisabledOnboardingCard = styled(OnboardingCardComponent)(({ theme }) => ({
  border: '0',
  boxShadow: 'none',
  backgroundColor:
    theme.palette.type === 'dark'
      ? theme.palette.background.paper
      : theme.palette.grey[200],
  '&:hover': {
    boxShadow: 'none',
  },
}));

const SettingsCardTitle = styled(Typography)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  left: '0%',
  bottom: '0%',
  fontFamily: theme.typography.fontFamilyTitle,
  color:
    theme.palette.type === 'dark'
      ? theme.palette.secondary.light
      : theme.palette.text.main,
  fontWeight: 700,
  fontSize: '16px',
  padding: theme.spacing(1.5, 0, 0, 0.5),
}));

const NudgeCardTitle = styled(SettingsCardTitle)(({ theme }) => ({
  position: 'relative',
  textAlign: 'center',
  color: theme.palette.text.default,
}));

const NudgeCardText = styled(NudgeCardTitle)(({ theme }) => ({
  padding: theme.spacing(1, 1, 0, 1),
  fontSize: '14px',
  fontWeight: '400',
}));

const MainCardTitle = styled(SettingsCardTitle)(({ theme, disabled }) => ({
  display: 'block',
  fontWeight: 700,
  color: disabled ? theme.palette.grey[500] : theme.palette.text.default,
  fontSize: '19px',
  lineHeight: '1.2',
  marginTop: theme.spacing(0.5),
  padding: theme.spacing(2, 7, 2, 0),
}));

const StepName = styled(MainCardTitle)(({ theme, disabled }) => ({
  position: 'relative',
  fontSize: '14px',
  color: disabled
    ? theme.palette.grey[500]
    : theme.palette.type === 'dark'
    ? theme.palette.text.default
    : theme.palette.secondary.main,
  fontWeight: 700,
  margin: theme.spacing(2, 0, -3, 0),
}));

const DisabledCard = styled(SettingsCardComponent)(({ theme }) => ({
  border: '0',
  boxShadow: 'none',
  backgroundColor:
    theme.palette.type === 'dark'
      ? theme.palette.background.paper
      : theme.palette.grey[200],
  padding: theme.spacing(2.5),
  '&:hover': {
    boxShadow: 'none',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  fontFamily: theme.typography.fontFamilyTitle,
  textTransform: 'none',
  fontWeight: 700,
  fontSize: '14px',
  marginTop: theme.spacing(1),
  padding: theme.spacing(0.5, 1.5),
}));

const MainButton = styled(StyledButton)(({ theme, disabled }) => ({
  position: 'relative',
  backgroundColor: disabled
    ? `${theme.palette.grey[500]} !important`
    : `${theme.palette.secondary.main}`,
  color: disabled
    ? `${theme.palette.background.main} !important`
    : `${theme.palette.text.main}`,
  whiteSpace: 'nowrap',
  boxShadow: 'none',
  textDecoration: 'none !important',
}));

const StyledLink = styled('a')({
  textDecoration: 'none',
});

const CenteredMainButton = styled(MainButton)({
  position: 'relative',
  textDecoration: 'none',
});

const DisabledCardTitle = styled(SettingsCardTitle)(({ theme }) => ({
  color: theme.palette.text.main,
}));

const DisabledIconContainer = styled(IconContainer)(({ theme }) => ({
  backgroundColor: theme.palette.background.white,
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.default,
  },
}));

const InfoLink = styled('a')(({ theme }) => ({
  margin: theme.spacing(0, 0.5),
  color: theme.palette.secondary.main,
  '& .MuiSvgIcon-root': {
    position: 'relative',
    top: '3px',
  },
}));

export const OnboardingCard = ({
  step,
  title,
  handleClick,
  buttonText,
  Icon,
  disabled,
  completed,
  successMessage,
  infoUrl,
}) => {
  if (disabled) {
    return (
      <DisabledOnboardingCard iscolored={1}>
        {Icon && (
          <IconContainer iscolored={1} islarge={1}>
            <Icon fontSize={'inherit'} />
            {completed && (
              <DoneContainer>
                <Done fontSize={'inherit'} />
              </DoneContainer>
            )}
          </IconContainer>
        )}
        <Box position="relative" height="50%">
          <StepName disabled={true}>{'Step ' + step.toString()}</StepName>
          <MainCardTitle disabled={true}>
            {title}
            {infoUrl && (
              <InfoLink href={infoUrl} target={'blank'}>
                <InfoOutlined fontSize={'inherit'} />
              </InfoLink>
            )}
          </MainCardTitle>
        </Box>
        {completed ? (
          <Box
            fontSize="16px"
            lineHeight="1.5"
            paddingLeft="4px"
            paddingTop="12px"
            fontFamily="Exo"
          >
            <Box fontWeight={400}>{successMessage}</Box>
          </Box>
        ) : (
          <MainButton
            disabled={true}
            variant="contained"
            color="secondary"
            onClick={handleClick}
          >
            {buttonText}
          </MainButton>
        )}
      </DisabledOnboardingCard>
    );
  }

  return (
    <OnboardingCardComponent iscolored={1}>
      <Box
        display="flex"
        flex-direction="row"
        height="40%"
        alignItems="center"
        justifyContent="flex-start"
        pb={2}
      >
        {Icon && (
          <IconContainer iscolored={1} islarge={1}>
            <Icon fontSize={'inherit'} />
            {completed && (
              <DoneContainer>
                <Done fontSize={'inherit'} />
              </DoneContainer>
            )}
          </IconContainer>
        )}
      </Box>
      <Box
        display="flex"
        style={{ flexDirection: 'column' }}
        alignItems="space-between"
        height="30%"
      >
        <StepName>{'Step ' + step.toString()}</StepName>
        <MainCardTitle>
          {title}
          {infoUrl && (
            <InfoLink href={infoUrl} target={'blank'}>
              <InfoOutlined fontSize={'inherit'} />
            </InfoLink>
          )}
        </MainCardTitle>
      </Box>
      <Box
        display="flex"
        flex-direction="column"
        height="30%"
        alignItems="flex-end"
      >
        {completed ? (
          <Box
            fontSize="16px"
            lineHeight="1.5"
            paddingLeft="4px"
            paddingTop="12px"
            fontFamily="Exo"
          >
            <Box fontWeight={400}>{successMessage}</Box>
          </Box>
        ) : (
          <MainButton
            variant="contained"
            color="secondary"
            onClick={handleClick}
          >
            {buttonText}
          </MainButton>
        )}
      </Box>
    </OnboardingCardComponent>
  );
};

export const SettingsCard = ({
  title,
  handleClick,
  Icon,
  disabled,
  disabledSubtitle,
  disabledButtonText,
  showNudge,
}) => {
  const theme = useTheme();
  if (disabled)
    return (
      <DisabledCard fullheight={showNudge ? 0 : 1}>
        <Box
          display={'flex'}
          style={{ flexDirection: 'row' }}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
        >
          {Icon && (
            <DisabledIconContainer iscolored={1}>
              <Icon fontSize={'inherit'} />
            </DisabledIconContainer>
          )}
          {disabledButtonText && (
            <StyledButton
              variant="contained"
              color={theme.palette.type === 'dark' ? 'secondary' : 'primary'}
              onClick={handleClick}
            >
              {disabledButtonText}
            </StyledButton>
          )}
        </Box>
        <DisabledCardTitle>
          {title}
          <br />
          <span style={{ fontWeight: 400 }}>{disabledSubtitle}</span>
        </DisabledCardTitle>
      </DisabledCard>
    );

  return (
    <SettingsCardComponent
      iscolored={0}
      onClick={handleClick}
      fullheight={showNudge ? 0 : 1}
    >
      {Icon && (
        <IconContainer iscolored={0}>
          <Icon fontSize={'inherit'} />
        </IconContainer>
      )}
      <SettingsCardTitle>{title}</SettingsCardTitle>
    </SettingsCardComponent>
  );
};

export const SettingsUpgradeCard = ({ title, text, buttonText, Icon }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => setShow(true), 750);
  });
  return (
    <Slide direction={'up'} in={show} timeout={500}>
      <NudgeCardComponent iscolored={0}>
        <Box
          display={'flex'}
          style={{ flexDirection: 'column' }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {Icon && (
            <IconContainer iscolored={0}>
              <Icon fontSize={'inherit'} />
            </IconContainer>
          )}
          <NudgeCardTitle>{title}</NudgeCardTitle>
          <NudgeCardText>{text}</NudgeCardText>
          <Box pt={0}>
            <StyledLink
              href="https://semplates.io/pricing"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'white' }}
            >
              <CenteredMainButton
                disabled={false}
                variant="contained"
                color="secondary"
              >
                {buttonText}
              </CenteredMainButton>
            </StyledLink>
          </Box>
        </Box>
      </NudgeCardComponent>
    </Slide>
  );
};
