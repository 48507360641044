import React, { useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { resendVerification } from 'state/actions';
import { useLazyLoading } from 'state/hooks';
import { LoadingButton } from 'components/Base';

const SendEmailButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.text.main,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  color: theme.palette.text.reverted,
  fontWeight: 800,
  fontSize: '18px',
}));

const ResendVerification = ({ email }) => {
  const theme = useTheme();
  const [emailIsSent, setEmailIsSent] = useState(false);

  const [resendVerificationEmail, { loading }] = useLazyLoading({
    action: resendVerification,
    onSuccess: (result) => {
      setEmailIsSent(true);
    },
  });

  return (
    <Box mt={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {!emailIsSent ? (
            <SendEmailButton
              loading={loading}
              variant="contained"
              color="secondary"
              type="submit"
              className="button-wide"
              onClick={() => resendVerificationEmail({ email })}
            >
              Resend Email
            </SendEmailButton>
          ) : (
            <Text
              sx={{
                paddingBottom: theme.spacing(2),
              }}
            >
              {`An email has been sent to your inbox!`}
            </Text>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResendVerification;
