import React, { Fragment } from 'react';
import { styled } from '@material-ui/core/styles';
import { Zoom, Fade, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { TextControlBar } from 'components/WelcomeSlides';
import chat from 'assets/img/welcomeSlides/customer-support.png';

import {
  PageContainer,
  TextContainer,
  ImageContainer,
  Headline,
  Text,
} from './styles';

const ChatImage = styled('img')(({ theme }) => ({
  width: '280px !important',
}));

const Assistance = ({ active }) => {
  return (
    <Fragment>
      <PageContainer>
        <Zoom in={active}>
          <TextContainer>
            <Box mt={10}>
              <Headline>Need help?</Headline>
              <Text>
                If you have any questions, check out our{' '}
                <a href={'https://semplates.io/documentation'}>helpdesk</a> or
                find us in the live chat at the bottom left.
              </Text>
            </Box>
          </TextContainer>
        </Zoom>
        <Fade in={active} timeout={400}>
          <ImageContainer>
            <ChatImage alt="Customer support chat" src={chat} />
          </ImageContainer>
        </Fade>
      </PageContainer>
      <TextControlBar />
    </Fragment>
  );
};

Assistance.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default Assistance;
