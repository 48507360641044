import React, { Fragment, useState } from 'react';
import { DialogFormFrame } from 'components/Base';
import { terms } from 'components/Legal';
import { Button } from '@material-ui/core';

const TermsAndConditionsDialog = ({ open, onClose, onAccept }) => {
  const [bottomViewed, setBottomViewed] = useState(false);
  const handleClose = () => {
    onClose();
    setBottomViewed(false);
  };
  const scrollAbleArea = (node) => {
    if (node) {
      node.addEventListener('scroll', handleScroll.bind(this));
    }
  };

  const handleScroll = (event) => {
    var node = event.target;
    const bottom =
      Math.abs(node.scrollHeight - node.scrollTop - node.clientHeight) < 10;
    if (bottom) {
      setBottomViewed(true);
    }
  };

  return (
    <DialogFormFrame
      title={'Terms and Conditions'}
      open={open}
      onClose={handleClose}
      innerContentRef={scrollAbleArea}
      actions={
        <Fragment>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!bottomViewed}
            onClick={onAccept}
          >
            Accept
          </Button>
        </Fragment>
      }
      formComponent={<div dangerouslySetInnerHTML={{ __html: terms }}></div>}
    />
  );
};
export default TermsAndConditionsDialog;
