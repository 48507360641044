import React, { Fragment, useState } from 'react';
import { FastField as Field, Form, Formik } from 'formik';
import { FormControlBar } from 'components/WelcomeSlides';
import { FieldWrapper, Loader } from 'components/Base';
import aws from 'assets/img/auth/register.png';
import {
  PageContainer,
  TextContainer,
  ImageContainer,
  Headline,
} from './styles';
import { useWelcomeSlides } from 'components/WelcomeSlides/hooks';
import { connect } from 'react-redux';
import { styled } from '@material-ui/core/styles';
import { useLazyLoading } from 'state/hooks';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { finalizeRegistration } from 'state/actions/user';

const AwsSesImage = styled('img')(({ theme }) => ({
  width: '360px !important',
}));

export const userDetailsSchema = Yup.object().shape({
  companyName: Yup.string()
    .nullable()
    .required('Required field'),
  firstName: Yup.string().required('Required field'),
  lastName: Yup.string().required('Required'),
});

const UserDetails = ({ company, user }) => {
  const { nextSlide } = useWelcomeSlides();
  const [validationErrors, setValidationErrors] = useState(false);

  const [handleSubmit, { loading }] = useLazyLoading({
    action: finalizeRegistration,
    onSuccess: () => nextSlide(),
  });

  if (!company && loading) return <Loader />;
  return (
    <Formik
      initialValues={{
        firstName: user.firstName || undefined,
        lastName: user.lastName || undefined,
        companyName: company?.name || undefined,
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={userDetailsSchema}
      onSubmit={() => console.log('Update User')}
    >
      {({ isSubmitting, values, errors }) => {
        const handleValidate = () => {
          if (
            !values.firstName ||
            !values.lastName ||
            (!company?.name && !values.companyName)
          ) {
            setValidationErrors(true);
          } else {
            setValidationErrors(false);
            handleSubmit(values);
          }
        };
        return (
          <Fragment>
            <PageContainer>
              <TextContainer width={55}>
                <Headline>We need some additional details about you</Headline>
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FieldWrapper label="First Name" variant="small">
                        <Field
                          component={TextField}
                          fullWidth
                          name="firstName"
                          variant="outlined"
                          size="small"
                        />
                      </FieldWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <FieldWrapper label="Last Name" variant="small">
                        <Field
                          component={TextField}
                          fullWidth
                          name="lastName"
                          variant="outlined"
                        />
                      </FieldWrapper>
                    </Grid>
                    {!company?.name && (
                      <Grid item xs={12}>
                        <FieldWrapper label="Company Name" variant="small">
                          <Field
                            component={TextField}
                            fullWidth
                            name="companyName"
                            variant="outlined"
                            size="small"
                          />
                        </FieldWrapper>
                      </Grid>
                    )}
                  </Grid>
                  {validationErrors && (
                    <p style={{ color: 'red' }}>Please fill out all fields</p>
                  )}
                </Form>
              </TextContainer>
              <ImageContainer>
                <AwsSesImage alt="aws ses functionalities" src={aws} />
              </ImageContainer>
            </PageContainer>
            <FormControlBar
              onSubmit={handleValidate}
              allowSkip={false}
              isSubmitting={isSubmitting || loading}
            />
          </Fragment>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.details,
    user: state.user,
  };
};

export default connect(mapStateToProps)(UserDetails);
