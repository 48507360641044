import React from 'react';
import PropTypes from 'prop-types';
import { FastField as Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Grid } from '@material-ui/core';
import { AsyncDialogActions, FieldWrapper } from 'components/Base';
import * as Yup from 'yup';
import { TagInputField } from 'components/Menu/Tags';

const CreateSchema = Yup.object().shape({
  name: Yup.string()
    .matches(
      '^[a-zA-Z0-9_-]*$',
      'Only alphanumeric letters, minuses or underscores are allowed.'
    )
    .required('This field is required'),
  subject: Yup.string().required('Each template should have a subject.'),
});

const CreateForm = ({ loading, onClose, onSubmit, input }) => {
  return (
    <Formik
      initialValues={{
        name: input?.name || '',
        subject: input?.subject || '',
        tags: input?.tags || [],
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={CreateSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FieldWrapper
                label="Template Name (editable)"
                subtitle="Shown in the overview table and used by AWS SES."
              >
                <Field
                  component={TextField}
                  fullWidth
                  name="name"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <FieldWrapper
                label="Subject Line (editable)"
                subtitle="Displayed in the recipient's inbox."
              >
                <Field
                  component={TextField}
                  fullWidth
                  name="subject"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>

            <Grid item xs={12}>
              <FieldWrapper
                label="Tags"
                subtitle="Associated properties for this template."
              >
                <Field
                  component={TagInputField}
                  template={values}
                  fullWidth
                  name="tags"
                  variant="outlined"
                />
              </FieldWrapper>
            </Grid>
            <AsyncDialogActions
              onClose={onClose}
              loading={isSubmitting || loading}
              submitText={'Create Template'}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

CreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CreateForm;
