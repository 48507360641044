import React, { useEffect, useState } from 'react';
import SendForm from './SendForm';
import { DialogFormFrame, Loader } from 'components/Base';
import { useLazyLoading } from 'state/hooks';
import {
  getEmailIdentities,
  sendMail,
  setSendTemplateOptions,
} from 'state/actions';
import { connect, useDispatch } from 'react-redux';
import { useTracking } from 'components/Contexts/TrackingContext';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Button, Typography } from '@material-ui/core';

const SendMailDialog = ({ open, template, templateInputs }) => {
  const dispatch = useDispatch();
  const { logEvent } = useTracking();

  const [identitiesLoaded, setIdentitiesLoaded] = useState(false);
  const [verifiedMails, setVerifiedEmails] = useState(undefined);

  const [getEmailIdentitiesAction] = useLazyLoading({
    action: getEmailIdentities,
    onSuccess: (res) => {
      setVerifiedEmails(res);
      setIdentitiesLoaded(true);
    },
  });

  useEffect(() => {
    if (open && !identitiesLoaded) {
      getEmailIdentitiesAction({ isTestSender: true });
      setIdentitiesLoaded(true);
    }
  }, [open, identitiesLoaded, getEmailIdentitiesAction]); //

  const [sendMailAction, { loading }] = useLazyLoading({
    action: sendMail,
    onSuccess: () => {
      logEvent({ category: 'template', action: 'send' });
      handleCloseDialog();
    },
  });

  const handleCloseDialog = () => {
    dispatch(
      setSendTemplateOptions({
        open: false,
        templateName: null,
        templateInputs: null,
      })
    );
  };

  const handleSubmit = async (values) => {
    if (typeof values.recipients === 'string') {
      values.recipients = values.recipients.split(',');
    }
    await sendMailAction({
      templateId: template.uid,
      templateInputs,
      ...values,
    });
  };

  if (!open || template === null) return null;

  if (!template?.awsAccountId) {
    return (
      <DialogFormFrame
        onClose={handleCloseDialog}
        title="Template cannot be sent"
        open={open}
        formComponent={
          <Alert severity="warning">
            <AlertTitle title="No account associated" />
            <Typography>
              This template is not associated with any AWS Account and can
              therefore not be send. To be able to send it, please connect an
              AWS Account and save the template again in order to store it in
              AWS SES.
            </Typography>
          </Alert>
        }
        actions={
          <Button onClick={handleCloseDialog} variant="contained">
            Cancel
          </Button>
        }
      />
    );
  }

  const formComponent = () => {
    if (!identitiesLoaded || verifiedMails === undefined) return <Loader />;

    return (
      <SendForm
        onSubmit={handleSubmit}
        loading={loading}
        isDraft={template?.unpublishedChanges}
        verifiedMailAddresses={identitiesLoaded && verifiedMails}
      />
    );
  };

  return (
    <DialogFormFrame
      open={open}
      onClose={handleCloseDialog}
      title={`Send Mail from ${template?.templateName}`}
      formComponent={formComponent()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    open: state.layout.sendTemplateOptions?.open,
    templateInputs: state.layout.sendTemplateOptions?.templateInputs,
    template: state.layout.sendTemplateOptions?.template,
  };
};

export default connect(mapStateToProps)(SendMailDialog);
