import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from 'assets/icons';
import { TextField } from 'formik-material-ui';
import { withStyles } from '@material-ui/core/styles';

const StyledButton = withStyles((theme) => ({
  root: {
    color: theme.palette.text.main,
  },
}))(IconButton);

const PasswordInputField = ({ ...props }) => {
  const [showHidePassword, setShowHidePassword] = useState(false);

  return (
    <TextField
      type={showHidePassword ? 'text' : 'password'}
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <StyledButton
              aria-label="toggle password visibility"
              onClick={() => setShowHidePassword(!showHidePassword)}
              edge="end"
              color="secondary"
            >
              {!showHidePassword ? (
                <Visibility size={22} />
              ) : (
                <VisibilityOff size={22} />
              )}
            </StyledButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInputField;
