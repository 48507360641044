import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { FastField as Field } from 'formik';
import { ChromePicker } from 'react-color';
import FieldWrapper from 'components/Base/FieldWrapper';
import { TextField } from 'formik-material-ui';
import {
  Box,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import { ColorPaletteOutline } from 'assets/icons';
import { styled } from '@material-ui/core/styles';

const StyledBox = styled(Box)(({ theme, color }) => ({
  backgroundColor: color,
  width: '100%',
  height: 45,
  borderRadius: '10%',
}));

const StyledPalette = styled(ColorPaletteOutline)(({ theme }) => ({
  color: theme.palette.text.main,
}));

const ColorPickerField = ({ label, subTitle, name, color, onChange }) => {
  const [showPicker, setShowPicker] = useState(false);
  const togglePicker = () => {
    setShowPicker((prev) => !prev);
  };

  const colorFieldProps = {
    endAdornment: (
      <InputAdornment position="end">
        <Tooltip title="Color Picker">
          <IconButton
            aria-label="Color Picker"
            onClick={togglePicker}
            edge="end"
          >
            {<StyledPalette size={22} />}
          </IconButton>
        </Tooltip>
      </InputAdornment>
    ),
  };

  return (
    <Fragment>
      <FieldWrapper label={label} subtitle={subTitle}>
        <Grid container spacing={3}>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <StyledBox boxShadow={10} color={color} />
          </Grid>

          <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
            <Field
              component={TextField}
              fullWidth
              name={name}
              value={color}
              variant="outlined"
              InputProps={colorFieldProps}
            />
          </Grid>
          {showPicker && (
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <Fade in={showPicker}>
                <div>
                  <ChromePicker
                    color={color}
                    disableAlpha={true}
                    onChange={(e) => onChange(e.hex)}
                  />
                </div>
              </Fade>
            </Grid>
          )}
        </Grid>
      </FieldWrapper>
    </Fragment>
  );
};

ColorPickerField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ColorPickerField;
