import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import LoadingButton from './LoadingButton';
import { StyledInvertedButton } from './Buttons';

const AsyncDialogActions = ({
  loading,
  onClose,
  submitText,
  secondaryText,
  onSubmit,
}) => {
  return (
    <Grid
      container
      item
      spacing={2}
      justifyContent={'flex-end'}
      alignItems={'center'}
    >
      {onClose ? (
        <Grid item>
          <StyledInvertedButton onClick={onClose}>
            {secondaryText ? secondaryText : 'Cancel'}
          </StyledInvertedButton>
        </Grid>
      ) : null}
      {submitText && (
        <Grid item>
          <LoadingButton loading={loading} type="submit" onClick={onSubmit}>
            {submitText}
          </LoadingButton>
        </Grid>
      )}
    </Grid>
  );
};

AsyncDialogActions.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default AsyncDialogActions;
