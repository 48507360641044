import React from 'react';
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const IndicatorContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Indicator = styled(({ active, ...props }) => <div {...props} />)(
  ({ active, theme }) => ({
    padding: 4,
    marginRight: theme.spacing(1),
    width: 10,
    height: 10,
    borderRadius: 10,
    transition: 'background 0.25s ease-in',
    background: `${
      active ? theme.palette.secondary.main : theme.palette.text.reverted
    }`,
    cursor: 'pointer',
  })
);

const SlideIndicator = ({ slideCount, activeIndex, onClick }) => {
  return (
    <IndicatorContainer>
      {Array.from(Array(slideCount).keys()).map((slide, index) => (
        <Indicator
          key={index}
          active={activeIndex === index}
          onClick={() => onClick(index)}
        />
      ))}
    </IndicatorContainer>
  );
};

SlideIndicator.propTypes = {
  slideCount: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SlideIndicator;
