import React, { Fragment, useEffect, useState } from 'react';
import { SettingsHeader } from 'components/Base';
import { Box } from '@material-ui/core';
import { useLazyLoading } from 'state/hooks';
import {
  getAwsAccountPermissions,
  evaluateAwsPermissions,
} from 'state/actions';
import AwsPermissionsTable from './AwsPermissionsTable';

const AwsAccountPermissionsSection = ({ open, account, onError }) => {
  const [permissionsSummary, setPermissionsSummary] = useState(null);
  const [loadAccountPermissions, { loading, error }] = useLazyLoading({
    action: getAwsAccountPermissions,
    onSuccess: (permissions) => setPermissionsSummary(permissions),
    onError: () => setPermissionsSummary(null),
  });

  const [
    loadEvalauteAwsPermissions,
    { loading: loadingEvaluate, error: errorEvaluate },
  ] = useLazyLoading({
    action: evaluateAwsPermissions,
    onSuccess: (permissions) => setPermissionsSummary(permissions),
    onError: () => setPermissionsSummary(null),
  });

  useEffect(() => {
    if (open && permissionsSummary?.uid !== account.id) {
      loadAccountPermissions(account.id);
    }
  }, [open, account]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error || errorEvaluate) {
    onError();
  }

  const handleLoadPermissions = () => {
    loadEvalauteAwsPermissions(account.id);
  };

  return (
    <Fragment>
      <Box pt={3}>
        <SettingsHeader
          title={'AWS Permissions of ' + account?.alias}
          buttonText={'Evaluate again'}
          onClick={handleLoadPermissions}
        />
        <AwsPermissionsTable
          loading={loading || loadingEvaluate}
          permissions={permissionsSummary?.permissions}
          awsAccount={account}
        />
      </Box>
    </Fragment>
  );
};

export default AwsAccountPermissionsSection;
