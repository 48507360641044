import React, { Fragment, useEffect, useState } from 'react';
import { DeleteOutline as DeleteIcon } from 'assets/icons';
import {
  AlertDialog,
  OverviewTable,
  StyledIconButton,
  SettingsHeader,
} from 'components/Base';
import { VerifyEmailAddressDialog } from 'components/Menu/Identities';
import { useLazyLoading } from 'state/hooks';
import {
  getEmailIdentities,
  removeIdentity,
  syncEmailIdentities,
  updateSenderName,
  updateIsTestSenderName,
} from 'state/actions';
import { connect } from 'react-redux';
import { verifiedMailColumns } from 'consts';
import { AwsNotConnected } from '../CompanySettings/Aws';
import { useTracking } from 'components/Contexts/TrackingContext';
import { Visibility } from '@material-ui/icons';
import EmailVisibilityDialog from './EmailVisibilityDialog';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { Searchbar } from '../Templates';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

const EmailsManager = ({ awsConnected, verifiedMails, addressCount }) => {
  const { logEvent } = useTracking();

  const [mailDialogOpen, setMailDialogOpen] = useState(false);
  const [visibilityDialogOpen, setVisibilityDialogOpen] = useState(false);
  const [scopedMail, setScopedMail] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
  });

  const [initialLoad, setInitialLoad] = useState(false);
  const [oldQuery, setOldQuery] = useState(query);

  const [reloadEmailIdentities, { loading, error }] = useLazyLoading({
    action: getEmailIdentities,
    onSuccess: (res) => console.log(res),
  });

  useEffect(() => {
    if (!initialLoad || !verifiedMails || query?.search !== oldQuery?.search) {
      setInitialLoad(true);
      reloadEmailIdentities({ search: query.search });
    }
  }, [initialLoad, reloadEmailIdentities, query, oldQuery, verifiedMails]);

  const [handleRefresh, { loading: refreshing }] = useLazyLoading({
    action: syncEmailIdentities,
  });

  const [handleRemoveIdentity, { loading: deleting }] = useLazyLoading({
    action: removeIdentity,
    onSuccess: () => {
      logEvent({ category: 'email_identity', action: 'removed' });
      setDeleteDialogOpen(false);
    },
    onError: () => {
      setDeleteDialogOpen(false);
    },
  });

  const [
    updateSenderNameAction,
    { loading: setSenderUpdateLoading },
  ] = useLazyLoading({
    action: updateSenderName,
    onSuccess: () => {
      logEvent({ category: 'email_identity', action: 'sender name updated' });
      setScopedMail(null);
    },
  });

  const [
    updateEmailVisibility,
    { loading: setVisibiliyLoading },
  ] = useLazyLoading({
    action: updateIsTestSenderName,
    onSuccess: () => setScopedMail(null),
  });

  const handleCloseDialog = () => {
    setMailDialogOpen(false);
    setDeleteDialogOpen(false);
    setScopedMail(null);
  };

  const confirmRemoveIdentity = (row) => {
    setScopedMail(row);
    setDeleteDialogOpen(true);
  };

  const handleSenderNameChange = async ({ displayName }) => {
    await updateSenderNameAction(scopedMail.uid, displayName);
  };

  const handleIsTestSenderChange = async (id, value) => {
    await updateEmailVisibility(id, value);
  };

  const handleOpenEmailVisibilityDialog = async () => {
    setVisibilityDialogOpen(true);
  };

  const handleQueryUpdates = (value) => {
    setOldQuery({ search: value });
  };

  if (error && error.includes('403')) {
    return (
      <Alert severity="error">
        <AlertTitle>Access Denied</AlertTitle>
        <Typography variant="body2">
          You don't have the necessary permissions to view this information.
          Please contact your administrator to request access.
        </Typography>
      </Alert>
    );
  }

  if (!awsConnected) return <AwsNotConnected />;

  return (
    <Fragment>
      <SettingsHeader
        title="Sender Mail Adresses"
        buttonText={'Add Address'}
        onClick={() => setMailDialogOpen(true)}
        refreshing={refreshing}
        onRefresh={handleRefresh}
      >
        <StyledIconButton
          aria-label="save changes"
          onClick={handleOpenEmailVisibilityDialog}
          edge="end"
        >
          <Visibility size={26} />
        </StyledIconButton>
      </SettingsHeader>
      <Searchbar
        onCancelSearch={() => setQuery({ search: '' })}
        submitAfterType={true}
        defaultQueryParam={'search'}
        onSubmit={handleQueryUpdates}
        customStyles={{ marginBottom: '8px' }}
      />
      <OverviewTable
        data={verifiedMails}
        columns={verifiedMailColumns}
        anyDialogOpen={deleteDialogOpen || mailDialogOpen}
        loading={loading}
        scopedMail={scopedMail}
        onSetScopedEntity={setScopedMail}
        onChangeSenderName={handleSenderNameChange}
        onChangeIsTestSender={handleIsTestSenderChange}
        onLoadingSenderName={setSenderUpdateLoading}
        onLoadingEmailVisibility={setVisibiliyLoading}
        total={addressCount}
        actions={[
          {
            icon: <DeleteIcon size={22} />,
            tooltip: 'Remove',
            onClick: confirmRemoveIdentity,
          },
        ]}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <VerifyEmailAddressDialog
        mail={scopedMail?.identity}
        open={mailDialogOpen}
        onClose={handleCloseDialog}
      />
      <EmailVisibilityDialog
        open={visibilityDialogOpen}
        onClose={() => setVisibilityDialogOpen(false)}
      />
      <AlertDialog
        onClose={handleCloseDialog}
        open={deleteDialogOpen}
        title={'Remove Identity'}
        onSubmit={async () => {
          await handleRemoveIdentity(scopedMail);
          setScopedMail(null);
        }}
        text={'Are you sure you want to delete this Identity?'}
        submitText={'Remove'}
        loading={deleting}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    awsConnected: state.aws.all?.length > 0,
    verifiedMails: state.identities?.emails,
    addressCount: state.identities.emails.length,
  };
};

export default connect(mapStateToProps)(EmailsManager);
