import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useLazyLoading } from 'state/hooks';
import { getAwsAccountStatus } from 'state/actions';
import { SettingsHeader } from 'components/Base';
import AwsAccountStatusCard from './AwsAccountStatusCard';

const AwsAccountStatusSection = ({ open, account, onError }) => {
  const [statusSummary, setStatusSummary] = useState(null);
  const [loadAccountStatus, { loading, error }] = useLazyLoading({
    action: getAwsAccountStatus,
    onSuccess: (status) => {
      setStatusSummary(status);
    },
    onError: () => setStatusSummary(null),
  });

  useEffect(() => {
    if (open && statusSummary?.uid !== account.id) {
      loadAccountStatus(account.id);
    }
  }, [open, account]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    onError();
  }

  return (
    <Fragment>
      <Box pt={3}>
        <SettingsHeader title={'Account Status of ' + account?.alias} />
        <AwsAccountStatusCard loading={loading} statusSummary={statusSummary} />
      </Box>
    </Fragment>
  );
};

export default AwsAccountStatusSection;
