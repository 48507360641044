import React from 'react';
import { AuthLayout, RegistrationForm } from 'components/Auth';

const Register = () => {
  return (
    <AuthLayout title="Register">
      <RegistrationForm />
    </AuthLayout>
  );
};

export default Register;
