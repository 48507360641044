import React, { Fragment, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import RouteGuardDialog from './RouteGuardDialog';

const RouteLeavingGuard = ({ when, onNavigate, loading }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && when) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = async (save) => {
    const route = lastLocation?.href
      ? lastLocation.pathname + lastLocation.href
      : lastLocation.pathname;
    await onNavigate(save, route);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (!loading) {
      setModalVisible(false);
    }
  }, [loading]);

  return (
    <Fragment>
      <Prompt when={when} message={handleBlockedNavigation} />
      <RouteGuardDialog
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        onSave={async () => await handleConfirmNavigationClick(true)}
        onDiscardChanges={async () => await handleConfirmNavigationClick(false)}
        loading={loading}
      />
    </Fragment>
  );
};
export default RouteLeavingGuard;
