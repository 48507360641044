import React from 'react';
import { styled } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import semplatesLogo from 'assets/svgs/semplates_logo_dark.svg';
import semplatesLogoDark from 'assets/svgs/semplates_logo.svg';
import { Container } from './Loader';
import { connect } from 'react-redux';

const Image = styled('img')({
  maxHeight: '40px',
});

const ProgressSpinner = styled(CircularProgress)(({ isdarkmode, theme }) => ({
  position: 'absolute',
  color: isdarkmode ? theme.palette.text.main : theme.palette.primary.main,
}));

const LogoLoader = ({ themeMode }) => {
  return (
    <Container>
      <Image src={themeMode === 'light' ? semplatesLogo : semplatesLogoDark} />
      <ProgressSpinner
        size={65}
        thickness={2}
        isdarkmode={themeMode === 'dark' ? 1 : 0}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.layout.themeMode,
  };
};

export default connect(mapStateToProps)(LogoLoader);
