import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useLazyLoading } from 'state/hooks';
import { styled } from '@material-ui/core/styles';

const StyledSSOButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.text.reverted,
  borderWidth: '1px',
  color: theme.palette.text.reverted,
  width: '100%',

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const SSOBaseButton = ({ label, logo, signInMethod, provider }) => {
  const history = useHistory();

  const [handleSignIn] = useLazyLoading({
    action: signInMethod,
    onSuccess: (action) => {
      history.push('/');
    },
  });

  return (
    <StyledSSOButton variant="outlined" onClick={handleSignIn}>
      <img
        style={{ height: '20px', marginRight: '24px' }}
        src={logo}
        alt={'Semplates-' + provider + '-login'}
      />
      {label}
    </StyledSSOButton>
  );
};

export default SSOBaseButton;
