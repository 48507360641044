import React from 'react';
import PropTypes from 'prop-types';
import { DialogFormFrame } from 'components/Base';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const HtmlSourceDialog = ({ template, onClose, open }) => {
  return (
    <DialogFormFrame
      maxWidth="lg"
      title={`Source Code for template: ${template?.templateName}`}
      open={open}
      onClose={onClose}
      formComponent={
        <SyntaxHighlighter language="html" style={docco}>
          {template.html}
        </SyntaxHighlighter>
      }
    />
  );
};

HtmlSourceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default HtmlSourceDialog;
