import login from 'assets/svgs/login.svg';
import register from 'assets/svgs/register.svg';
import password from 'assets/svgs/password.svg';

const authConstants = {
  login: {
    route: '/login',
    tabTitle: 'Log In',
    formTitle: 'Send your mails to the world!',
    formImage: login,
  },
  register: {
    route: '/register',
    tabTitle: 'Sign Up',
    formTitle: 'Sign up. Connect. Start sending mails.',
    formImage: register,
  },
  forgot: {
    route: '/password-forgot',
    tabTitle: 'Forgot Password',
    formTitle: 'Forgot something? No worries...',
    formImage: password,
  },
};

export default authConstants;
