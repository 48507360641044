import React, { useEffect, useRef, useState } from 'react';
import SimpleVariableView from './SimpleVariableView';
import { usePlaceholders, useSubscription } from 'components/Contexts';
import noPlaceholders from 'assets/svgs/no_placeholders.svg';
import {
  StyledButton,
  StyledGroupButton,
  StyledInvertedButton,
} from '../../../Base';
import { Box, ButtonGroup, useTheme } from '@material-ui/core';
import JSONVariableView from './JSONVariableView';
import { TitleTypography } from '../Versions/TemplateVersions';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { Alert } from '@material-ui/lab';

const PlaceholderView = () => {
  const formikRef = useRef(null);
  const formikJSONRef = useRef(null);
  const [activeTab, setActiveTab] = useQueryParam(
    'expertMode',
    withDefault(NumberParam, 0)
  );
  const [simpleViewIsEnabled, setSimpleViewIsEnabled] = useState(true);
  const theme = useTheme();
  const { isDefaultPlan } = useSubscription();
  const {
    originalPlaceholderObject,
    hasComplexContent,
    isDeepObject,
    resetInterpolation,
  } = usePlaceholders();
  const hasPlaceholders = originalPlaceholderObject
    ? Object.keys(originalPlaceholderObject).length > 0
    : false;

  const isDeep = hasPlaceholders
    ? isDeepObject(originalPlaceholderObject)
    : false;

  useEffect(() => {
    if (isDeep) {
      setSimpleViewIsEnabled(false);
      setActiveTab(1);
    }
  }, [isDeep, setActiveTab, setSimpleViewIsEnabled]);

  const handleInterpolation = () => {
    if (activeTab === 0 && formikRef.current) {
      formikRef.current.handleSubmit();
    } else if (activeTab === 1 && formikJSONRef.current) {
      // ToDo Clemens: Not working yet
      formikJSONRef.current.handleSubmit();
    }
  };

  const handleReset = () => {
    resetInterpolation();
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    if (formikJSONRef.current) {
      formikJSONRef.current.resetForm();
    }
  };

  if (hasComplexContent(originalPlaceholderObject) && isDefaultPlan) {
    return (
      <Box
        display="flex"
        flexGrow={1}
        mb={1}
        style={{ flexDirection: 'column' }}
        justifyContent="flex-start"
      >
        <Box
          pt={20}
          display="flex"
          alignItems="center"
          style={{ flexDirection: 'column' }}
        >
          <Alert severity="error">
            Complex placeholders are available on business and corporate plans
            only. Upgrade your plan to use nested elements or loop placeholders.
          </Alert>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexGrow={1}
      mb={1}
      style={{ flexDirection: 'column' }}
      justifyContent="flex-start"
    >
      {!hasPlaceholders ? (
        <Box
          pt={20}
          display="flex"
          alignItems="center"
          style={{ flexDirection: 'column' }}
        >
          <img src={noPlaceholders} alt="No placeholders" width="100%" />
          <Box py={2}>
            <TitleTypography variant="h5" align="center">
              No variables detected 👀
            </TitleTypography>
          </Box>
          <StyledButton
            variant="contained"
            color="primary"
            target="_blank"
            href={'https://semplates.io/documentation/template-design'}
          >
            Learn how to use Email Personalization
          </StyledButton>
        </Box>
      ) : (
        <>
          <Box pb={1}>
            <ButtonGroup variant="contained" disableElevation disableRipple>
              <StyledGroupButton
                size="large"
                elevation={0}
                onClick={() => setActiveTab(0)}
                isactive={activeTab === 0 ? 1 : 0}
                disabled={!simpleViewIsEnabled}
              >
                Base Mode
              </StyledGroupButton>
              <StyledGroupButton
                size="large"
                elevation={0}
                onClick={() => setActiveTab(1)}
                isactive={activeTab === 1 ? 1 : 0}
              >
                Expert Mode
              </StyledGroupButton>
            </ButtonGroup>
          </Box>
          <Box pb={10}>
            {activeTab === 0 && <SimpleVariableView formikRef={formikRef} />}
            {activeTab === 1 && <JSONVariableView formikRef={formikJSONRef} />}
          </Box>
          <Box
            style={{
              backgroundColor: theme.palette.background.paper,
              display: 'flex',
              position: 'fixed',
              bottom: 0,
              zIndex: 8000,
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(2.5),
              width: `calc(${theme.custom.sideAreaWidth}px - ${theme.spacing(
                4
              )}px)`,
              justifyContent: 'space-between',
            }}
          >
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleInterpolation}
            >
              Update Template
            </StyledButton>
            <StyledInvertedButton
              variant="outlined"
              color="primary"
              onClick={handleReset}
            >
              Reset
            </StyledInvertedButton>
          </Box>
        </>
      )}
    </Box>
  );
};
export default PlaceholderView;
