const verifiedDomainColumns = [
  {
    id: 'name',
    label: 'Name',
    type: 'text',
    sortable: false,
    align: 'left',
  },
  {
    id: 'type',
    label: 'Type',
    type: 'text',
    sortable: false,
    align: 'left',
  },
  {
    id: 'value',
    label: 'Value',
    type: 'text',
    sortable: false,
    align: 'left',
  },
];

export default verifiedDomainColumns;
