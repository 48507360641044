import React, { Fragment } from 'react';
import { LoadingButton } from 'components/Base';
import { useLazyLoading } from 'state/hooks';
import { setDefaultEmail } from 'state/actions';
import { styled } from '@material-ui/core/styles';

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.primary.light,
}));

const DefaultEmailRow = ({ rowData }) => {
  const [handleSetDefaultMail, { loading }] = useLazyLoading({
    action: setDefaultEmail,
  });

  return (
    <Fragment>
      {!rowData.isDefault && rowData.verified ? (
        <StyledButton
          variant="text"
          loading={loading}
          onClick={() => handleSetDefaultMail(rowData.uid)}
        >
          set default
        </StyledButton>
      ) : (
        rowData.isDefault && <p>default</p>
      )}
    </Fragment>
  );
};

export default DefaultEmailRow;
