const INITIAL_STATE = {
  authUser: null,
  subscription: null,
};

const applySetAuthData = (state, { payload }) => ({
  ...state,
  authUser: payload.user,
  subscription: payload.subscription,
});

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'AUTH_DATA_SET': {
      return applySetAuthData(state, action);
    }
    default:
      return state;
  }
};

export default authReducer;
