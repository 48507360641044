import 'react-hot-loader/patch';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import App from './App';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider } from 'react-redux';
import configureStore from 'state/store';
import 'styles/app.css';
import { makeAxiosClient } from 'state/utils';
import { Firebase, FirebaseProvider } from 'components/Contexts';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorView from './containers/ErrorView';

const ENVIRONMENT = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG
)?.projectId.slice(-3);
Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: ENVIRONMENT,
  enabledReleaseStages: ['dev', 'prd'],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export const firebase = new Firebase({
  firebaseConfig: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG),
  useMock: process.env.REACT_APP_FIREBASE_EMULATOR,
});
const axiosInstance = makeAxiosClient({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

const store = configureStore({ firebaseInstance: firebase, axiosInstance });
const persistor = persistStore(store);

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <Provider store={store}>
          <FirebaseProvider firebase={firebase}>
            <PersistGate loading={null} persistor={persistor}>
              <Component />
            </PersistGate>
          </FirebaseProvider>
        </Provider>
      </ErrorBoundary>
    </AppContainer>,
    document.getElementById('root')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App.js', () => {
    const NextRootContainer = require('./App').default;
    render(NextRootContainer);
  });
}
